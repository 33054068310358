import { post, get, del} from "../http";

export const postsaveProductOrder = (payload) => post("/product/order/save", payload);
export const fetchproductList = () => get("/product/list");
export const fetchpendingProductOrderList = () => get("/product/order/pending/list");
export const fetchpreApproveProductOrderList = () => get("/product/order/pre-approve/list");
export const deleteProductOrder = (payload) => del("/product/order/delete?productOrderId="+payload);
export const fetchpackageListByProductId = (payload) => get("/product/package/list/by/product-id?productId="+payload);
export const approveProductOrder = (payload) => post("/product/order/approve", payload);
export const preApproveProductOrder = (payload) => post("/product/order/pre-approve?productOrderId="+payload, payload);
export const fetchclientList = (payload) => get("/client-details/yearly/list/by/product-custom-id?productCustomId="+payload);
export const fetchpendingApproveProductOrderList = (payload) => get("/product/order/approve/list/all?orderStatus="+payload);
export const fetchproductOrderList = (payload) => get("/product/order/list?orderStatus="+payload);
export const downloadAggrementCopyUrl = (payload) => get("/product/order/aggrement/copy/download?productOrderId="+payload);
export const updatePendingDataUrl = (payload) => post("/product/order/pending/update", payload);
export const updatePreApproveDataUrl = (payload) => post("/product/order/pre-approved/update", payload);
export const fetchclienHalfYearlyUrl = (payload) => get(`/client-details/half-yearly/list/by/product-custom-id?productCustomId=${payload}`);
export const fetchclientHalfYearlyDetailsUrl = (payload) => get(`/client-details/half-yearly/list/by/product-custom-id?productCustomId=${payload}`);
export const fetchInstituteListByProductCustomIdUrl = (payload) => get(`/client-details/list/by/product-custom-id?productCustomId=${payload}`);