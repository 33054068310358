import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { saveProductBillGenerateMonthly, fetchclientUnit, saveProductBillGenerateYearly, fetchdisbursementPayableList, saveDisbursement, fetchsearchApipClientBillPaidUnpaidList, fetchsearchPipClientBillPaidUnpaidList, fetchsearchSdlClientBillPaidUnpaidList, fetchsearchApipClientBillPaidUnpaidListSDL, fetchsearchProductWiseMonthlyPaidUnpaidList, fetchispBillPaymentLogList, fetchispClientBillPaymentCheck, ispClientBillPaymentUpdate, saveProductBillGenerateMonthly2, saveProductBillGenerateHalfYear, saveProductBillGenerateYearly2, fetchbillGenerateInfoViewList, updateBillGenerateInfo, fetchMenualBillGenerateListUrl, manualBillGenerateurl, deleteMenualGenerateBillUrl } from '../../../http/bill/bill';

export interface Bill {

	saveProductBillGenerateMonthly: Thunk<Bill, any>
	saveProductBillGenerateHalfYear: Thunk<Bill, any>
	saveProductBillGenerateYearly: Thunk<Bill, any>
	saveDisbursement: Thunk<Bill, any>

	clientUnit: any,
	setclientUnit: Action<Bill, any>;
	fetchclientUnit: Thunk<Bill, any>;

	disbursementPayableList: any,
	setdisbursementPayableList: Action<Bill, any>;
	fetchdisbursementPayableList: Thunk<Bill>;	
	
	paidBillGenerateInfoViewList: any,
	setPaidBillGenerateInfoViewList: Action<Bill, any>;
	fetchPaidBillGenerateInfoViewList: Thunk<Bill, any>;
	updateBillGenerateInfo: Thunk<Bill, any>;

	unpaidBillGenerateInfoViewList: any,
	setUnpaidBillGenerateInfoViewList: Action<Bill, any>;
	fetchUnpaidBillGenerateInfoViewList: Thunk<Bill, any>;

	billGenerateInfoViewList: any,
	setBillGenerateInfoViewList: Action<Bill, any>;
	fetchBillGenerateInfoViewList: Thunk<Bill, any>;

	searchApipClientBillPaidUnpaidList: any,
	setsearchApipClientBillPaidUnpaidList: Action<Bill, any>;
	fetchsearchApipClientBillPaidUnpaidList: Thunk<Bill>;

	searchPipClientBillPaidUnpaidList: any,
	setsearchPipClientBillPaidUnpaidList: Action<Bill, any>;
	fetchsearchPipClientBillPaidUnpaidList: Thunk<Bill>;


	searchSdlClientBillPaidUnpaidList: any,
	setsearchSdlClientBillPaidUnpaidList: Action<Bill, any>;
	fetchsearchSdlClientBillPaidUnpaidList: Thunk<Bill>;

	searchApipClientBillPaidUnpaidListSDL: any,
	setsearchApipClientBillPaidUnpaidListSDL: Action<Bill, any>;
	fetchsearchApipClientBillPaidUnpaidListSDL: Thunk<Bill>;


	searchProductWiseMonthlyPaidUnpaidList: any,
	setsearchProductWiseMonthlyPaidUnpaidList: Action<Bill, any>;
	fetchsearchProductWiseMonthlyPaidUnpaidList: Thunk<Bill>;

	ispBillPaymentLogList: any,
	setispBillPaymentLogList: Action<Bill, any>;
	fetchispBillPaymentLogList: Thunk<Bill>;

	ispClientBillPaymentCheck: any,
	setispClientBillPaymentCheck: Action<Bill, any>;
	fetchispClientBillPaymentCheck: Thunk<Bill>;

	ispClientBillPaymentUpdate: Thunk<Bill>;

	menualBillGenerateList: any,
	setMenualBillGenerateList: Action<Bill, any>;
	fetchMenualBillGenerateList: Thunk<Bill, any>;
	paymetManualBillGenerator: Thunk<Bill, any>

	updateBillGenerateDiscountInfo: Thunk<Bill, any>;
	deleteMenualGenerateBill: Thunk<Bill, any>;
}

export const billStore: Bill = {

	clientUnit: 0,
	fetchclientUnit: thunk(async (actions, payload) => {
		const response = await fetchclientUnit(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			console.log(body);
			actions.setclientUnit(body?.item);
		} else {
			actions.setclientUnit(0);
		}
	}),
	setclientUnit: action((state, payload) => {
		state.clientUnit = payload;
	}),
	disbursementPayableList: [],
	fetchdisbursementPayableList: thunk(async (actions) => {
		const response = await fetchdisbursementPayableList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();

			actions.setdisbursementPayableList(body?.item);
		} else {
			actions.setdisbursementPayableList([]);
		}
	}),
	setdisbursementPayableList: action((state, payload) => {
		state.disbursementPayableList = payload;
	}),	
	
	paidBillGenerateInfoViewList: [],
	fetchPaidBillGenerateInfoViewList: thunk(async (actions, payload) => {
		const response = await fetchbillGenerateInfoViewList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
			actions.setPaidBillGenerateInfoViewList(body?.item);
			} else {
				actions.setPaidBillGenerateInfoViewList([]);
				notification['warning']({
					message: 'No Data Found',
					description: 'No Data Found',
				});
			}
		} else {
			actions.setPaidBillGenerateInfoViewList([]);
		}
	}),
	setPaidBillGenerateInfoViewList: action((state, payload) => {
		state.paidBillGenerateInfoViewList = payload;
	}),
	unpaidBillGenerateInfoViewList:[],
	fetchUnpaidBillGenerateInfoViewList: thunk(async (actions, payload) => {
		const response = await fetchbillGenerateInfoViewList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
			actions.setUnpaidBillGenerateInfoViewList(body?.item);
			} else {
				actions.setUnpaidBillGenerateInfoViewList([]);
				notification['warning']({
					message: 'No Data Found',
					description: 'No Data Found',
				});
			}
		} else {
			actions.setUnpaidBillGenerateInfoViewList([]);
		}
	}),
	setUnpaidBillGenerateInfoViewList: action((state, payload) => {
		state.unpaidBillGenerateInfoViewList = payload;
	}),
	billGenerateInfoViewList:[],
	fetchBillGenerateInfoViewList: thunk(async (actions, payload) => {
		const response = await fetchbillGenerateInfoViewList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
			actions.setBillGenerateInfoViewList(body?.item);
			} else {
				actions.setBillGenerateInfoViewList([]);
				notification['warning']({
					message: 'No Data Found',
					description: 'No Data Found',
				});
			}
		} else {
			actions.setBillGenerateInfoViewList([]);
		}
	}),
	setBillGenerateInfoViewList: action((state, payload) => {
		state.billGenerateInfoViewList = payload;
	}),
	searchApipClientBillPaidUnpaidList: [],
	fetchsearchApipClientBillPaidUnpaidList: thunk(async (actions, payload) => {
		const response = await fetchsearchApipClientBillPaidUnpaidList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setsearchApipClientBillPaidUnpaidList(body?.item);
			} else {
				notification['error']({
					message: 'Bill Report',
					description: 'No data found',
				});
				actions.setsearchApipClientBillPaidUnpaidList([])
			}

		} else {
			actions.setsearchApipClientBillPaidUnpaidList([]);
		}
	}),
	setsearchApipClientBillPaidUnpaidList: action((state, payload) => {
		state.searchApipClientBillPaidUnpaidList = payload;
	}),
	searchPipClientBillPaidUnpaidList: [],
	fetchsearchPipClientBillPaidUnpaidList: thunk(async (actions, payload) => {
		const response = await fetchsearchPipClientBillPaidUnpaidList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setsearchPipClientBillPaidUnpaidList(body?.item);
			} else {
				notification['error']({
					message: 'Bill Report',
					description: 'No data found',
				});
				actions.setsearchPipClientBillPaidUnpaidList([])
			}

		} else {
			actions.setsearchPipClientBillPaidUnpaidList([]);
		}
	}),
	setsearchPipClientBillPaidUnpaidList: action((state, payload) => {
		state.searchPipClientBillPaidUnpaidList = payload;
	}),
	searchSdlClientBillPaidUnpaidList: [],
	fetchsearchSdlClientBillPaidUnpaidList: thunk(async (actions, payload) => {
		const response = await fetchsearchSdlClientBillPaidUnpaidList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setsearchSdlClientBillPaidUnpaidList(body?.item);
			} else {
				notification['error']({
					message: 'Bill Report',
					description: 'No data found',
				});
				actions.setsearchSdlClientBillPaidUnpaidList([])
			}

		} else {
			actions.setsearchSdlClientBillPaidUnpaidList([]);
		}
	}),
	setsearchSdlClientBillPaidUnpaidList: action((state, payload) => {
		state.searchSdlClientBillPaidUnpaidList = payload;
	}),

	searchApipClientBillPaidUnpaidListSDL: [],
	fetchsearchApipClientBillPaidUnpaidListSDL: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchsearchApipClientBillPaidUnpaidListSDL(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setsearchApipClientBillPaidUnpaidListSDL(body?.item);
			} else {
				notification['error']({
					message: 'Bill Report',
					description: 'No data found',
				});
				actions.setsearchApipClientBillPaidUnpaidListSDL([])
			}

		} else {
			actions.setsearchApipClientBillPaidUnpaidListSDL([]);
		}
	}),
	setsearchApipClientBillPaidUnpaidListSDL: action((state, payload) => {
		state.searchApipClientBillPaidUnpaidListSDL = payload;
	}),

	searchProductWiseMonthlyPaidUnpaidList: [],
	fetchsearchProductWiseMonthlyPaidUnpaidList: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchsearchProductWiseMonthlyPaidUnpaidList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setsearchProductWiseMonthlyPaidUnpaidList(body?.item);
			} else {
				notification['error']({
					message: 'Bill Report',
					description: 'No data found',
				});
				actions.setsearchProductWiseMonthlyPaidUnpaidList([])
			}

		} else {
			actions.setsearchProductWiseMonthlyPaidUnpaidList([]);
		}
	}),
	setsearchProductWiseMonthlyPaidUnpaidList: action((state, payload) => {
		state.searchProductWiseMonthlyPaidUnpaidList = payload;
	}),

	saveProductBillGenerateMonthly: thunk(async (actions, payload) => {
		const response = await saveProductBillGenerateMonthly2(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Bill Generate',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Bill Generate',
					description: body?.message,
				});
			}
			// actions.setpartnerProfile(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),	
	saveProductBillGenerateHalfYear: thunk(async (actions, payload) => {
		const response = await saveProductBillGenerateHalfYear(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Bill Generate',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Bill Generate',
					description: body?.message,
				});
			}
			// actions.setpartnerProfile(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	saveProductBillGenerateYearly: thunk(async (actions, payload) => {
		const response = await saveProductBillGenerateYearly2(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			console.log(body)
			if (body.messageType == 1) {
				notification['success']({
					message: 'Bill Generate',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Bill Generate',
					description: body?.message,
				});
			}
			// actions.setpartnerProfile(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),

	saveDisbursement: thunk(async (actions, payload) => {
		const response = await saveDisbursement(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Bill Disbursment',
					description: body?.message,
				});


			} else {
				notification['error']({
					message: 'Bill Disbursment',
					description: body?.message,
				});
			}
			// actions.setpartnerProfile(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),

	ispBillPaymentLogList: [],
	fetchispBillPaymentLogList: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchispBillPaymentLogList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setispBillPaymentLogList(body?.item);
			} else {
				notification['error']({
					message: 'Sheba Net',
					description: 'No data found',
				});
				actions.setispBillPaymentLogList([])
			}

		} else {
			actions.setispBillPaymentLogList([]);
		}
	}),
	setispBillPaymentLogList: action((state, payload) => {
		state.ispBillPaymentLogList = payload;
	}),
	ispClientBillPaymentCheck: null,
	fetchispClientBillPaymentCheck: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchispClientBillPaymentCheck(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				let finalData = body?.item.replace(/\\/g, "");
				actions.setispClientBillPaymentCheck(JSON.parse(finalData));
			} else {
				notification['error']({
					message: 'Sheba Net',
					description: body?.message,
				});
				actions.setispClientBillPaymentCheck(null)
			}

		} else {
			actions.setispClientBillPaymentCheck(null);
		}
	}),
	setispClientBillPaymentCheck: action((state, payload) => {
		state.ispClientBillPaymentCheck = payload;
	}),

	ispClientBillPaymentUpdate: thunk(async (actions, payload) => {
		const response = await ispClientBillPaymentUpdate(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				notification['success']({
					message: 'Sheba Net',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Sheba Net',
					description: body?.message,
				});
			}
		} else {

			notification['error']({
				message: 'Sheba Net',
				description: "Something went wrong",
			})

		}
	}),	
	
	updateBillGenerateInfo: thunk(async (actions, payload) => {
		const response = await updateBillGenerateInfo(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				notification['success']({
					message: 'Bill Generate',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Bill Generate',
					description: body?.message,
				});
			}
		} else {

			notification['error']({
				message: 'Bill Generate',
				description: "Something went wrong",
			})

		}
	}),

	menualBillGenerateList: [],
	fetchMenualBillGenerateList: thunk(async (actions, payload) => {
		const response = await fetchMenualBillGenerateListUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();           
			actions.setMenualBillGenerateList(body?.item);
		} else {
			actions.setMenualBillGenerateList([]);
		}
	}),
	setMenualBillGenerateList: action((state, payload) => {
		state.menualBillGenerateList = payload;
	}),

	paymetManualBillGenerator: thunk(async (actions, payload) => {
		const response = await manualBillGenerateurl(payload.payloadData);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			console.log(body)
			if (body.messageType == 1) {
				notification['success']({
					message: 'Bill Generate',
					description: body?.message,
				});
				actions.fetchMenualBillGenerateList(payload.search);
			} else {
				notification['error']({
					message: 'Bill Generate',
					description: body?.message,
				});
			}
			// actions.setpartnerProfile(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),

	updateBillGenerateDiscountInfo: thunk(async (actions, payload) => {
		const response = await updateBillGenerateInfo(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				notification['success']({
					message: 'Bill Generate',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Bill Generate',
					description: body?.message,
				});
			}
		} else {

			notification['error']({
				message: 'Bill Generate',
				description: "Something went wrong",
			})

		}
	}),

	deleteMenualGenerateBill: thunk(async (actions, payload) => {
		const response = await deleteMenualGenerateBillUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				notification['success']({
					message: 'Bill Generate',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Bill Generate',
					description: body?.message,
				});
			}
		} else {

			notification['error']({
				message: 'Bill Generate',
				description: "Something went wrong",
			})

		}
	}),

}
