import { DeleteOutlined, FileExcelOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Skeleton, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import ReactExport from "react-export-excel";

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

const { Option } = Select;

export default function OrderStatusAPIPReport(props) {

    const productOrderList = useStoreState(state => state.order.productOrderList);
    const loading = useStoreState(state => state.order.loading);

    const fetchproductOrderList = useStoreActions(state => state.order.fetchproductOrderList);


    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        fetchproductOrderList(values?.orderStatus);
    };


    useEffect(() => {
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Report'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const columns = [
        {
            title: 'Products Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Executive Name',
            dataIndex: 'partnerName',
            key: 'partnerName',

        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',

        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',

        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
            key: 'contactNo',

        },
        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',

        },
        {
            title: 'Status',
            dataIndex: 'approveStatus',
            key: 'approveStatus',

        }
    ];

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = productOrderList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)

    };

    const [orderSts,setorderSts ]= useState<any>();

    return (
        <Card title="Order Status" style={{ overflow: "auto" }} >
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} >
                    <Col xs={24} sm={24} md={24} lg={7} xl={7}></Col>

                    <Col xs={24} sm={24} md={24} lg={7} xl={7}>

                        <Form.Item
                            name="orderStatus"
                            label="Order Status:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select your status" },
                            ]}
                        >
                            <Select placeholder="Order Status" style={{ width: "100" }}  onChange={e=>setorderSts(e)} >
                                <Option value="0">Pending</Option>
                                <Option value="1">Approved</Option>
                                <Option value="2">Pre-approved</Option>

                            </Select>

                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>

            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
            {productOrderList.length > 0 && !loading &&

                <>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                    <span>Total Found: {productOrderList !== null ? productOrderList?.length : 0}</span>
                    <Input
                        style={{ margin: "0 0 10px 0", width: 250, }}
                        placeholder="Search..."
                        value={searchv}
                        allowClear
                        onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                    />
                    </div>
                    <Table
                        bordered={true}
                        columns={columns}
                        dataSource={searchv === '' ? productOrderList : filterTable}
                    />
                    <Space style={{ float: 'right' }} size="middle">
                        <ExcelFile filename={`${orderSts==='0'?'Pending':orderSts==='1'?'Approved':'Pre-approved'} order status`} element={<Button type="primary" icon={<FileExcelOutlined />} > Download Excel </Button>}>
                            <ExcelSheet data={productOrderList} name="Bill">
                                <ExcelColumn label="Product Name" value="productName" />
                                <ExcelColumn label="Package Name" value="packageName" />
                                <ExcelColumn label="Executive Name" value="partnerName" />
                                <ExcelColumn label="Area" value="area" />
                                <ExcelColumn label="Client Name" value="clientName" />
                                <ExcelColumn label="Contact No" value="contactNo" />
                                <ExcelColumn label="Order Date" value="orderDate" />
                                <ExcelColumn label="Approve Status" value="approveStatus" />
 
                            </ExcelSheet>
                        </ExcelFile>
                    </Space>
                </>

            }
        </Card>
    )
}
