import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { SelectProduct } from '../../select/SelectProduct'
import moment from "moment";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectBillCycle } from '../../select/SelectBillCycle';
import { DeleteOutlined, EditOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Excel } from 'antd-table-saveas-excel';
import Text from 'antd/lib/typography/Text';
const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function BillGenerateUnPaidListSuperAdmin(props) {

    const fetchUnpaidBillGenerateInfoViewList = useStoreActions(state => state.bill.fetchUnpaidBillGenerateInfoViewList);
    const updateBillGenerateInfo = useStoreActions(state => state.bill.updateBillGenerateInfo);
    const unpaidBillGenerateInfoViewList = useStoreState(state => state.bill.unpaidBillGenerateInfoViewList);
    const deleteMenualGenerateBill = useStoreActions((state) => state.bill.deleteMenualGenerateBill);
    const [billgenerateForm] = Form.useForm();

    const [sub, setsub] = useState<any>('');

    const orderFormSubmit = (value) => {
        value.billStatus = "0"
        value.productId = value?.productId?.productId
        value.billingMonth = value.billingMonth == null ? "" : value.billingMonth
        fetchUnpaidBillGenerateInfoViewList(value);
        setsub(value);
    };
    useEffect(() => {
        setPageTitle();
    }, []);

    const [productId, setProductId] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>(null);

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Unpaid Bill'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const onchangeproductId = (val) => {
        setProductId(val);
    }
    const onchangebillCycle = (val) => {
        //   console.log(val)
        setBillCycle(val)
    }

    let year = new Date().getFullYear();
    const [bc, setbc] = useState<any>('');

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = unpaidBillGenerateInfoViewList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const [billGenerateId, setBillGenerateId] = useState<any>(null);
    const columns = [
        {
            title: 'Client Provided Id',
            dataIndex: 'clientProvidedId',
            key: 'clientProvidedId',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Client Unit',
            dataIndex: 'clientUnit',
            key: 'clientUnit',
        },
        {
            title: 'Bill Month',
            dataIndex: 'billingMonth',
            key: 'billingMonth',
        },
        {
            title: 'Bill Year',
            dataIndex: 'billingYear',
            key: 'billingYear',
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Discount Note',
            dataIndex: 'discountNote',
            key: 'discountNote',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Update">
                        <Button type="primary" icon={<EditOutlined />} onClick={() => { showModal(record); setBillGenerateId(record.billGenerateId); formUpdate.setFieldsValue({ discount: record.discount, discountNote: record.discountNote }) }} ></Button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure to delete?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteBillGenerateManualList(record.billGenerateId)}
                        >
                            <Tooltip title="Delete">
                                <Button danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const columns2 = [
        {
            title: 'Client Provided Id',
            dataIndex: 'clientProvidedId',
            key: 'clientProvidedId',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Client Unit',
            dataIndex: 'clientUnit',
            key: 'clientUnit',
        },
        {
            title: 'Bill Month',
            dataIndex: 'billingMonth',
            key: 'billingMonth',
        },
        {
            title: 'Bill Year',
            dataIndex: 'billingYear',
            key: 'billingYear',
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Discount Note',
            dataIndex: 'discountNote',
            key: 'discountNote',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
        },

    ];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = (val) => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        // setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [formUpdate] = Form.useForm();
    const onFinishUpdate = (values: any) => {
        values.billGenerateId = billGenerateId;
        updateBillGenerateInfo(values);
        setTimeout(() => {
            fetchUnpaidBillGenerateInfoViewList(sub);
        }, 1000);
        formUpdate.resetFields();
        setIsModalVisible(false);

    };

    function bcx(val) {
        setbc(val);
        if (val === "YEARLY") {
            billgenerateForm.setFieldsValue({ billingMonth: null })
        };

    }

    const deleteBillGenerateManualList = (value) => {
        deleteMenualGenerateBill(value);
        billgenerateForm.submit();
    }

    return (
        <>
            <Card title="Unpaid Bill List">
                <Form
                    layout="vertical"
                    onFinish={orderFormSubmit}
                    id="basic-info"
                    form={billgenerateForm}
                >
                    <div className="p-0">
                        <Row>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                <Form.Item
                                    name="productId"
                                    label="Product:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select your product" },
                                    ]}
                                    style={{ width: "100%" }}
                                >
                                    <SelectProduct onChange={(val) => onchangeproductId(val)} selected={productId} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                <Form.Item
                                    name="billingYear"
                                    label="Year:"
                                    className="title-Text"
                                    initialValue={year}
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                    style={{ width: "100%" }}
                                >
                                    <Select placeholder="Select Year" allowClear style={{ width: "100%" }}>
                                        <Option value={year - 1}>{year - 1}</Option>
                                        <Option value={year}>{year}</Option>
                                        <Option value={year + 1}>{year + 1}</Option>
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                <Form.Item
                                    name="billCycle"
                                    label="Bill Cycle:"
                                    className="title-Text"
                                    initialValue={null}
                                >
                                    <Select placeholder="Select Bill Cycle" allowClear onChange={(e) => bcx(e)} style={{ width: "100%" }}>
                                        <Option value="MONTHLY">Monthly</Option>
                                        <Option value="HALF_YEARLY">Half Yearly</Option>
                                        <Option value="YEARLY">Yearly</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                <Form.Item
                                    name="billingMonth"
                                    label="Month:"
                                    className="title-Text"
                                    initialValue=""
                                >
                                    <Select placeholder="Select Month" allowClear disabled={bc === "YEARLY" ? true : false} style={{ width: "100%" }}>
                                        <Option value="January-June">January-June</Option>
                                        <Option value="July-December">July-December</Option>
                                        <Option value="January">January</Option>
                                        <Option value="February">February</Option>
                                        <Option value="March">March</Option>
                                        <Option value="April">April</Option>
                                        <Option value="May">May</Option>
                                        <Option value="June">June</Option>
                                        <Option value="July">July</Option>
                                        <Option value="August">August</Option>
                                        <Option value="September">September</Option>
                                        <Option value="October">October</Option>
                                        <Option value="November">November</Option>
                                        <Option value="December">December</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Space size="small" className="pull-right-lg mt-30-lg">
                                    <Button type="primary" htmlType="submit" >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {unpaidBillGenerateInfoViewList?.length > 0 &&
                                    <div className="mt-15">
                                        <span>Total Found: {unpaidBillGenerateInfoViewList !== null ? unpaidBillGenerateInfoViewList?.length : 0}</span>
                                        <Input
                                            style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                                            placeholder="Search..."
                                            value={searchv}
                                            allowClear
                                            onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                                        />
                                        <Table
                                            bordered={true}
                                            dataSource={searchv === '' ? unpaidBillGenerateInfoViewList : filterTable}
                                            columns={columns}
                                            rowKey={(record) => record.billGenerateId}
                                            // pagination={false}
                                            locale={{ emptyText: 'Search result will appear here' }}
                                            summary={pageData => {
                                                let tpayableAmount = 0;
                                                let ddiscount = 0;
                                                let ppaidAmount = 0;

                                                unpaidBillGenerateInfoViewList.forEach(({ payableAmount, discount, paidAmount }) => {
                                                    tpayableAmount += payableAmount;
                                                    ddiscount += discount;
                                                    ppaidAmount += paidAmount;
                                                });

                                                return (
                                                    <>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0}> <Text type="danger" strong>Total</Text></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1}>

                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2}>

                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={3}>

                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={4}>

                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={5}>
                                                                <Text type="danger" strong>{tpayableAmount}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={6}>
                                                                <Text type="danger" strong>{ddiscount}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={7}>

                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={8}>
                                                                <Text type="danger" strong>{ppaidAmount}</Text>
                                                            </Table.Summary.Cell>
                                                            <Table.Summary.Cell index={9}>

                                                            </Table.Summary.Cell>
                                                        </Table.Summary.Row>

                                                    </>
                                                );
                                            }}
                                        // style={{ maxHeight: 400 }}
                                        />
                                        <Space style={{ float: 'right' }} size="middle">
                                            < Button
                                                type='primary'

                                                icon={<FileExcelOutlined />}
                                                onClick={() => {
                                                    const excel: any = new Excel();
                                                    excel
                                                        .addSheet(`Sheet 1`)
                                                        .addColumns(columns2)
                                                        .addDataSource(unpaidBillGenerateInfoViewList)
                                                        .saveAs(`Unpaid Bill.xlsx`);
                                                }}
                                            >
                                                Download Excel
                                            </ Button >

                                        </Space>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Card>
            <Modal
                title="Discount"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"40%"}
                okText="Update"
                centered
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                maskClosable={false}
            >
                <Form layout="vertical" onFinish={onFinishUpdate} id='update-class' form={formUpdate} >
                    <Form.Item
                        name="discount"
                        label="Discount:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input discount", },
                        ]}
                    >
                        <Input type="text" placeholder="Discount"></Input>
                    </Form.Item>
                    <Form.Item
                        name="discountNote"
                        label="Discount Note:"
                        className="title-Text"
                    >
                        <Input.TextArea placeholder="Discount Note"></Input.TextArea>
                    </Form.Item>

                    <Button type="primary" htmlType="submit" >
                        Update
                    </Button>
                </Form>
            </Modal>
        </>
    )
}
