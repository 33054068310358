import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography, Tooltip, Modal } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { EyeOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClientPublic } from '../../select/SelectClientPublic';
import Text from 'antd/lib/typography/Text';
const { Option } = Select;
const { Paragraph } = Typography;

export default function ProductOrderListPartnerWise(props) {

    const fetchApprovePartnerListForProductOrder = useStoreActions((state) => state.partner.fetchApprovePartnerListForProductOrder);
    const approvePartnerListForProductOrder = useStoreState((state) => state.partner.approvePartnerListForProductOrder);
    const fetchPartnerwiseProductOrderList = useStoreActions((state) => state.partner.fetchPartnerwiseProductOrderList);
    const partnerwiseProductOrderList = useStoreState((state) => state.partner.partnerwiseProductOrderList);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [rowSingleData, setRowSingleData] = useState<any>();
    const [SearchForm] = Form.useForm();

    const searchOrderList = (value) => {
        let postdata:any = {
            orderStatus : value.orderStatus,
            partnerId: value.selectPartner
        }
        fetchPartnerwiseProductOrderList(postdata);
    };

    useEffect(() => {
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Product Order'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const columns = [

        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },        
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: 'Partner Name',
            dataIndex: 'partnerName',
            key: 'partnerName',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
            key: 'contactNo',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
        },
        {
            title: 'Aggreement Date',
            dataIndex: 'agreementDate',
            key: 'agreementDate',
        },
        {
            title: 'Aggreement End Date',
            dataIndex: 'aggreementEndDate',
            key: 'aggreementEndDate',
        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <>
                    <Space size="small">
                        <Tooltip title="View">
                            <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                setIsModalVisible(true);
                                setRowSingleData(record);                            
                            }}></Button>
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    const onCHangeDesignation = (value) => {
        fetchApprovePartnerListForProductOrder(value);
    }
    
    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search =value => {

        const result = approvePartnerListForProductOrder.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Card title="Product Order List" >
                <Form
                    layout="vertical"
                    onFinish={searchOrderList}
                    id="basic-info"
                    form={SearchForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="orderStatus"
                                label="Order Status:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your status" },
                                ]}
                            >
                                <Select placeholder="Select Status" style={{ width: "100" }} >
                                    <Option value="0">Pending</Option>
                                    <Option value="1">Pre-Approve</Option>
                                    <Option value="2">Approved</Option>
                                </Select>

                            </Form.Item>
                        </Col>                        
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="designation"
                                label="Designation"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select designation" },
                                ]}
                            >
                                <Select placeholder="Select Designation" style={{ width: "100" }} onChange={(e) => onCHangeDesignation(e)}>
                                    <Option key="0" value="SDL">SDL</Option>
                                    <Option key="1" value="RM">RM</Option>
                                    <Option key="2" value="DM">DM</Option>
                                    <Option key="3" value="EX">EX</Option>
                                    <Option key="4" value="BILLING">BILLING</Option>
                                    <Option key="5" value="SUPPORT">SUPPORT</Option>
                                    <Option key="6" value="ISP_RESELLER">ISP RESELLER</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="selectPartner"
                                label="Partner"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select partner" },
                                ]}
                            >
                                <Select 
                                    allowClear
                                    filterOption={(inputValue, option) =>
                                        option?.props.children
                                        .toString()
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase())
                                    }
                                    showSearch
                                    placeholder="Select partner" 
                                    style={{ width: "100" }}
                                >
                                    {approvePartnerListForProductOrder.map((item, index) => {
                                        return (
                                            <Option key={item.partnerId} value={item.partnerId}>{item.partnerId} - {item.partnerName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                <Button type="primary" htmlType="submit" style={{ height: 40, marginTop:30 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
    
                        </Col>
                      
                    </Row>
                </Form>
                {partnerwiseProductOrderList && partnerwiseProductOrderList.length > 0 &&
                <Row className='mt-30'>
                    <Col span={24}>
                        <span>Total Found: {partnerwiseProductOrderList !== null ? partnerwiseProductOrderList?.length : 0}</span>
                        <Input
                            style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                            placeholder="Search..."
                            value={searchv}
                            allowClear
                            onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                        />
                        <Table bordered={true} dataSource={searchv === '' ? partnerwiseProductOrderList : filterTable} columns={columns} />
                    </Col>
                </Row>
                }
            </Card>
            <Modal
                title="Pending Partner Information" 
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ style: { display: 'none' } }}
                centered
            >       
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Client Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.clientName ? rowSingleData?.clientName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Product Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.productName ? rowSingleData?.productName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Package Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.packageName ? rowSingleData?.packageName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Partner Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.partnerName ? rowSingleData?.partnerName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Contact Number
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.contactNo ? rowSingleData?.contactNo : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Email
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.email ? rowSingleData?.email : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Order Date
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.agreementDate ? rowSingleData?.agreementDate : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Aggreement End Date
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.aggreementEndDate ? rowSingleData?.aggreementEndDate : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Total student
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.numberOfStudent ? rowSingleData?.numberOfStudent : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Per Student Rate
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.rate ? rowSingleData?.rate : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Bill cycle
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                        {rowSingleData && rowSingleData?.billCycle ? rowSingleData?.billCycle : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Bill Payable Person
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.billPayablePerson ? rowSingleData?.billPayablePerson : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Address
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.address ? rowSingleData?.address : ''}
                        </Col>
                    </Row>
            </Modal>
        </>
    )
}
