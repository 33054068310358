import { Button, Col, DatePicker, Form, Input, Result, Row, Space, Card, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { token } from '../../../store/states/auth/auth'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import TextArea from 'antd/lib/input/TextArea'


export default function IIGSmsBuy(props) {

    const [iigClientForm] = Form.useForm();


    const fetchgetSmsBalanceSinglePartnerInfo = useStoreActions(state => state.iig.fetchgetSmsBalanceSinglePartnerInfo);
    const getSmsBalanceSinglePartnerInfo = useStoreState(state => state.iig.getSmsBalanceSinglePartnerInfo);

    useEffect(() => {
        setTimeout(function () {
            setPageTitle();
        })
        fetchgetSmsBalanceSinglePartnerInfo();
    }, [])


    const onIspClientAddFormSubmit = (value) => {
        window.open(`${process.env.REACT_APP_API_ROOT}/sms/purchase/pay?totalSms=${value.totalSms}&access_token=${token}`, '_blank')
        iigClientForm.resetFields()
        setamount(0)
    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };


    const [ispPackage, setispPackage] = useState<any>(null);

    const onchangeIspPackage = (val) => {
        console.log(val)
        setispPackage(val)
    }


    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP Client Add'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [amount, setamount] = useState<any>(0);
    return (
        <>
            <Card title="SMS Buy" >

                <Form
                    layout="horizontal"
                    {...layout}
                    onFinish={onIspClientAddFormSubmit}
                    id="basic-info"
                    form={iigClientForm}
                >
                    <div className="__orderForm p-0 custom-form">
                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                <Card title="Current SMS">
                                    <span style={{ fontWeight: "bold" }}>Total SMS :</span> <span>{getSmsBalanceSinglePartnerInfo?.balance}</span>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                <Card title="Buy SMS">
                                    <Form.Item
                                        name="totalSms"
                                        label="Total SMS:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please insert total sms number" },
                                        ]}
                                    >
                                        <InputNumber type="text" placeholder="Total SMS" min={10} style={{ width: "100%" }} onChange={(e) => setamount(e)}></InputNumber>
                                    </Form.Item>
                                    {amount > 0 &&
                                        <span style={{ fontWeight: "bold" }}> Amount: {(amount*.3).toFixed(2)} TK </span>
                                    }
                                    <Space size="small" style={{ float: "right" }}>
                                        <Button type="primary" htmlType="submit" >
                                            Buy
                                        </Button>
                                    </Space>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Form>

            </Card>

        </>
    )
}
