import { post, get, getSpay} from "../http";


export const fetchpartnerProfile = () => get("/partner/profile");
export const fetchDistrictList = () => get("/location/info/district-list");
export const fetchThanaList = (id) => get(`/location/info/thana-list/by/district-id?districtId=${id}`);

export const fetchpublicclientList = () => getSpay("/public/client/list");
export const fetchclientTansactionSummary = (payload) => getSpay(`/public/client/transaction/summary?fromDate=${payload?.fromDate}&toDate=${payload?.toDate}`);
export const fetchclientTansactionVendorSummary = (payload) => getSpay(`/public/client/transaction/vendor/summary?fromDate=${payload?.fromDate}&toDate=${payload?.toDate}`);
export const fetchdateToDateSingleClientTansactionDetails = (payload) => getSpay(`/public/date-to-date/single-client/transaction/details?fromDate=${payload?.fromDate}&toDate=${payload?.toDate}&clientId=${payload?.clientId}`);
export const fetchdateToDateTansactionDetails = (payload) => getSpay(`/public/date-to-date/transaction/details?fromDate=${payload?.fromDate}&toDate=${payload?.toDate}`);
export const fetchsingleClientTansactionSummary = (payload) => getSpay(`/public/single-client/transaction/summary?fromDate=${payload?.fromDate}&toDate=${payload?.toDate}&clientId=${payload?.clientId}`);
export const fetchsingleClientVendorTansactionSummary = (payload) => getSpay(`/public/single-client/vendor/transaction/summary?fromDate=${payload?.fromDate}&toDate=${payload?.toDate}&clientId=${payload?.clientId}`);

