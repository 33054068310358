import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectUnderPartner {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectUnderPartner = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectUnderPartner) => {

  const underDesignationList = useStoreState(state => state.partner.underDesignationList);
  console.log('selected', selected);
  
  const onSelect = (id) => {
    if (underDesignationList) {
      const val = underDesignationList.find((item) => item.partnerId === id);
      onChange(val);
    }
  };

  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Under Partner"
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {underDesignationList ? (
        underDesignationList.map((type, idx) => (
          <Option key={type.partnerId} value={type.partnerId}>
            {type.partnerName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Under Partner</Option>
      )}
    </Select>
  );
};
