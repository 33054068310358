import { Button, Card, Col, DatePicker, Form, Row, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClientPublic } from '../../select/SelectClientPublic';
import Text from 'antd/lib/typography/Text';


export default function SingleClientVendorTransactionSummary(props) {

    const singleClientVendorTansactionSummary = useStoreState((state) => state.common.singleClientVendorTansactionSummary);
    const fetchsingleClientVendorTansactionSummary = useStoreActions((state) => state.common.fetchsingleClientVendorTansactionSummary);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            clientId: value?.clientId,
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }

        fetchsingleClientVendorTansactionSummary(postData);
    };


    const columns = [

        {
            title: 'Date',
            dataIndex: 'trnDate',
            key: 'trnDate',
        },
        {
            title: 'Vendor',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
        },        
        {
            title: 'Transaction Amount',
            dataIndex: 'trnAmount',
            key: 'trnAmount',
        },
    ]



    return (
        <>
            <Card title="Single Client Vendor Transaction Summary" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="clientId"
                                label="Client"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select client" },
                                ]}
                            >
                               <SelectClientPublic/>
                            </Form.Item>
                        </Col>                        
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                <Button type="primary" htmlType="submit" style={{ height: 40, marginTop:30 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
    
                        </Col>
                      
                    </Row>
                </Form>
                {singleClientVendorTansactionSummary !== null &&
                    <>

                        <Table
                            columns={columns}
                            dataSource={singleClientVendorTansactionSummary}
                            bordered={true}
                            summary={pageData => {
                                let total = 0;
                                singleClientVendorTansactionSummary.forEach(({ trnAmount }) => {
                                    total += trnAmount;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} > <div style={{textAlign:'right'}}>Total</div></Table.Summary.Cell>
                                            <Table.Summary.Cell index={1}>
                
                                            </Table.Summary.Cell>                                            
                                            <Table.Summary.Cell index={2}>
                                                <Text type="danger" strong>{total}</Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>

                                    </>
                                );
                            }}
                        />

                    </>
                }
            </Card>
        </>
    )
}
