import { DeleteOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectProduct } from '../../select/SelectProduct'
import GetColumnSearchProps from '../../utils/getColumnSearchProps';

export default function BillPaymentReport(props) {

    const searchApipClientBillPaidUnpaidList = useStoreState(state => state.bill.searchApipClientBillPaidUnpaidList);

    const fetchsearchApipClientBillPaidUnpaidList = useStoreActions(state => state.bill.fetchsearchApipClientBillPaidUnpaidList);


    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        let postData: any = {
            year: values?.year,
            productId: values?.product_id?.productId,
        }
        console.log(postData)
        fetchsearchApipClientBillPaidUnpaidList(postData);
    };


    useEffect(() => {
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Report'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const columns = [
        {
            title: 'Products Id',
            dataIndex: 'clientProvideId',
            key: 'clientProvideId',
            ...GetColumnSearchProps('clientProvideId', 'Products Id'),
        },
        {
            title: 'Client/Product Name',
            dataIndex: 'clientName',
            key: 'clientName',
            ...GetColumnSearchProps('clientName', 'Client/Product Name'),
        },
        {
            title: 'Area/Address',
            dataIndex: 'address',
            key: 'address',
            ...GetColumnSearchProps('address', 'Area/Address'),
        },
        {
            title: 'Status',
            dataIndex: 'clientStatus',
            key: 'clientStatus',
            ...GetColumnSearchProps('clientStatus', 'Status'),
        },
        {
            title: 'Bill Month',
            dataIndex: 'billMonth',
            key: 'billMonth',
            ...GetColumnSearchProps('billMonth', 'Bill Month'),
        },
        {
            title: 'Paid',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            ...GetColumnSearchProps('paidAmount', 'Paid'),
        },
        {
            title: 'Due/Unpaid',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            ...GetColumnSearchProps('dueAmount', 'Due/Unpaid'),
        },


    ]

    const currentyear = new Date().getFullYear();

    // console.log(currentyear)

    const optionsYear = [
        { value: currentyear - 1, label: currentyear - 1 },
        { value: currentyear, label: currentyear },
        { value: currentyear + 1, label: currentyear + 1 }
    ];
    const [productId, setProductId] = useState<any>(null);

    const onchangeproductId = (val) => {
        setProductId(val);
    }

    return (
        <>
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} className="box-shadow p-15">
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="year"
                            label="Year:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please Select Year" },
                            ]}
                        >
                            <Select allowClear placeholder="Select Year" options={optionsYear} />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="product_id"
                            label="Product:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select your product" },
                            ]}
                        >
                            <SelectProduct onChange={(val) => onchangeproductId(val)} selected={productId}

                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                                    </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>
            {searchApipClientBillPaidUnpaidList.length > 0 &&
                <Card title="Product wise Paid/Due List" >

                    <Table bordered={true} dataSource={searchApipClientBillPaidUnpaidList} columns={columns} />

                </Card>

            }
        </>
    )
}
