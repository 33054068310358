import { post, get, del, update} from "../http";

export const fetchispPackageInfoList = () => get(`/isp/package-info/list`);
export const fetchispClientInfoListForPip = () => get(`/isp/client-info/list-pip`);
export const fetchispClientInfoListForSDL = (payload) => get(`/isp/client-info/list?partnerId=${payload}`);
export const saveIspPackageInfo = (payload) => post(`/isp/package-info/save`, payload);
export const sendSmsUnpaidIspClient = (payload) => post(`/isp/sms/send`, payload);
export const updateIspPackageInfo = (payload) => post(`/isp/package-info/update`, payload);
export const saveIspClientInfo = (payload) => post(`/isp/client-info/save`, payload);
export const saveIspClientInfoBulk = (payload) => post(`/isp/client-info/bulk/save`, payload);
export const saveIspDiscountInfo = (payload) => post(`/isp/discount/save`, payload);
export const updateIspClientInfo = (payload) => post(`/isp/client-info/update`, payload);
export const updateIspClientInfoSDL = (payload) => post(`/isp/client-info/update/sdl`, payload);
export const ispClientBillGenerate = (payload) => post(`/isp/client-bill/generate`, payload);
export const ispClientBillGenerateForIig = (payload) => post(`/isp/client-bill/generate/for/iig`, payload);
export const fetchispClientBillInfoListForPip = (payload) => get(`/isp/client-bill/report/list-pip?billStatus=${payload.billStatus}&billMonth=${payload.billMonth}&billYear=${payload.billYear}`);
export const fetchispClientBillInfoList = (payload) => get(`/isp/client-bill/report/list?billStatus=${payload.billStatus}&partnerId=${payload.partnerId}&billMonth=${payload.billMonth}&billYear=${payload.billYear}`);
export const fetchispClientSingleInfo = (payload) => get(`/public/isp/client/info/single?clientCustomId=${payload.clientCustomId}&year=${payload.year}`);
export const fetchgetGatewayPageUrl = (payload) => post(`/public/isp-spay/bill/payment?billId=${payload}`, '');
// export const fetchgetGatewayPageUrl = (payload) => post(`/public/isp/bill/payment?billId=${payload}`, '');
export const downloadVoucher = (payload) => get(`/public/jasper/invoice/download?clientCustomId=${payload.clientCustomId}&billingYear=${payload.year}`);
export const downloadIspBillDisbursement = (payload) => get(`/public/jasper/isp/bill/disbursement?billingMonth=${payload.billingMonth}&billingYear=${payload.billingYear}`);
export const fetchispClientBillInfoForSdlList = (payload) => get(`/isp/client-bill/report/list/for/sdl?billingMonth=${payload.billingMonth}&billingYear=${payload.billingYear}`);
export const fetchispClientBillInfoForSdlListDate = (payload) => get(`/isp/client-bill/report/date/wise/list/for/sdl?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchispPartnerPaymentInfoList = (payload) => get(`/isp/partner/disbursement/list?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchdateWiseIspClientBillInfoList = (payload) => get(`/isp/client-bill/report/list/date-wise/for/sdl?fromDate=${payload.fromDate}&toDate=${payload.toDate}&partnerId=${payload.partnerId}`);
export const ispPartnerPaymentInfoSave = (payload) => post(`/isp/partner/disbursement/save`, payload);
export const ispPartnerPaymentInfoUpdate = (payload) => post(`/isp/partner/disbursement/update`, payload);
export const fetchispDiscountList = () => get(`/isp/discount/list`);
export const deleteDiscount = (payload) => del(`/isp/discount/delete?id=${payload}`);
export const fetchbillPaymentInfoStatus = (payload) => get(`/isp/client-bill/status/for/support?billingMonth=${payload.billingMonth}&billingYear=${payload.billingYear}&clientCustomId=${payload.clientCustomId}`);
export const billPaymentIpn = (payload) => post(`/isp/client-bill/payment/for/mestro`, payload);
export const fetchIspClientListDateWiseUrl = (payload) => get(`/product/order/list/date/wise?fromDate=${payload.fromDate}&orderStatus=${payload.orderStatus}&toDate=${payload.toDate}`);
export const fetchIspClientProductWiseListUrl = (payload) => get(`/client-details/list/by/productid?productId=${payload}`);
export const orderClientIfoUpdateUrl = (payload) => post(`/client-details/update`, payload);
export const saveProductBillGenerateSingle = (payload) => post(`/school-university/bill-generate/single`, payload);
