import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectCategoryOrg {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectCategoryOrg = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectCategoryOrg) => {
  const selectedCat = useStoreState((state) => state.order.selectedCat);

  const [itemList, setitemList] = React.useState<any>([]);

  React.useEffect(() => {
    if (selectedCat === "Education") {
      setitemList([
        {
          id: 'Primary School',
          value: 'Primary School'
        },
        {
          id: 'High School',
          value: 'High School'
        },
        {
          id: 'College',
          value: 'College'
        },        
        {
          id: 'University',
          value: 'University'
        },
      ])
    } else if (selectedCat === "Business") {
      setitemList([
        {
          id: 'Restuarant',
          value: 'Restuarant'
        },
        {
          id: 'Super Shop',
          value: 'Super Shop'
        },
        {
          id: 'Computer Shop',
          value: 'Computer Shop'
        },
        {
          id: 'Mobile Shop',
          value: 'Mobile Shop'
        },
        {
          id: 'Clothing Store',
          value: 'Clothing Store'
        },
        {
          id: 'Medicine Shop',
          value: 'Medicine Shop'
        },
        {
          id: 'Bakery Shop',
          value: 'Bakery Shop'
        },
        {
          id: 'Computer Servicing',
          value: 'Computer Servicing'
        },
        {
          id: 'Cosmetics Shop',
          value: 'Cosmetics Shop'
        },
        {
          id: 'Electronics Shop',
          value: 'Electronics Shop'
        },
        {
          id: 'Internet Service Provider(ISP)',
          value: 'Internet Service Provider(ISP)'
        },
        {
          id: 'Dish Service Provider',
          value: 'Dish Service Provider'
        },
        {
          id: 'Sweets Shop',
          value: 'Sweets Shop'
        }
      ])
    } else if (selectedCat === "NGO") {
      setitemList([
        {
          id: 'NGO',
          value: 'NGO'
        },
      ])
    }
  }, [selectedCat])



  const onSelect = (id) => {
    if (itemList) {
      const val = itemList.find((item) => item.id === id);
      onChange(val);
    }
  };

  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected?.id}
      style={style}
      placeholder="Select Category"
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.id} value={type.id}>
            {type.value}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Categories</Option>
      )}
    </Select>
  );
};
