import { post, get, del} from "../http";


export const pendingList = () => get("");
export const partnerSignup = (payload) => post("/public/partner/sign-up",payload);
export const fetchPendingList = () => get("/partner/pending/list");
export const fetchpartnerListByLevel = (payload) => get(`/partner/parent/list/by/level?level=${payload}`);
export const fetchpartnerListByLevelOrigin = (payload) => get(`/partner/list/by/level?level=${payload}`);
export const partnerAssign = (payload) => post("/partner/assign",payload);
export const partnerPendingUpdateUrl = (payload) => post("/partner/update",payload);
export const applicantPartnerDelete = (payload) => del(`/partner/applicant/delete?partnerId=${payload}`);
export const fetchpartnerList = (payload) => get("/partner/report/designation/partner/list?designation="+payload);
export const fetchpartnerProfile = (payload) => get("/partner/report/profile/view?partnerId="+payload);
export const fetchpartnerApproveListUrl = (payload) => get("/partner/approved/list/by/designation?designation="+payload);

export const fetchpartnerListForIspUrl = (payload) => get("/partner/approved/list/by/designation?designation="+payload);

export const fetchUnderDesignationListUrl = (payload) => get("/partner/parent/list/by/child/designation?designation="+payload);

export const pendingPartnerAssignUrl = (payload) => post("/partner/assign", payload);
export const pendingPartnerAssignUpdateUrl = (payload) => post("/partner/assign/update", payload);

export const updatePendingPartnerUrl = (payload) => post("/partner/update", payload);

export const fetchOrderListByStatusUrl = (payload) => get("/product/order/list/executive?orderStatus="+payload);

export const fetchOrderListByPartnerWiseUrl = (payload) => get(`/product/order/list/partner/wise?orderStatus=${payload.orderStatus}&partnerId=${payload.partnerId}`)