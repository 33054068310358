import { Button, Col, DatePicker, Form, Input, Result, Row, Space, Card, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectIPartnerMultiple } from '../../select/SelectIPartnerMultiple'
import { SelectYear } from '../../select/SelectYear'
import { SelectMonth } from '../../select/SelectMonth'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import moment from 'moment'


export default function IIGBillGenerate(props) {

    const [billGenerateForm] = Form.useForm();

    useEffect(() => {

        setTimeout(function () {
            setPageTitle();
        })
    }, [])

    const ispClientBillGenerateForIig = useStoreActions(state => state.isp.ispClientBillGenerateForIig);
   
    const onBlillGenerateSubmit = (value) => {
        value.billPaymentDate = moment(value?.billPaymentDate).format("YYYY-MM-DD");

        console.log(value)
        ispClientBillGenerateForIig(value);

      billGenerateForm.resetFields();

    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };




    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'BILL GENERATE'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [partnerId, setPartnerId] = useState<any>(null);

    const onchnagePartner = (val) => {
        setPartnerId(val)
    }

    return (
        <>
            <Card title="" >

                <Form
                    layout="horizontal"
                    {...layout}
                    onFinish={onBlillGenerateSubmit}
                    id="basic-info"
                    form={billGenerateForm}
                >
                    <div className="__orderForm p-0 custom-form">
                        <Row gutter={8}>

                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                            <Form.Item
                                name="billPaymentDate"
                                label="Bill Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date"/>

                            </Form.Item>
                        </Col>


                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Form.Item
                                    name="billingYear"
                                    label="Year:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select billingYear" },
                                    ]}
                                >
                                    <SelectYear />
                                </Form.Item>
                            </Col>                            
                            
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Form.Item
                                    name="billingMonth"
                                    label="Month:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select billingMonth" },
                                    ]}
                                >
                                    <SelectMonth />
                                </Form.Item>
                            </Col>                            
                            
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Space size="small" style={{ float: "right" }}>
                                    <Button type="primary" htmlType="submit" >
                                        Generate
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Form>

            </Card>

        </>
    )
}
