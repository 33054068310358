import { Layout, Menu, Breadcrumb } from 'antd';
import React, { useState } from 'react';
import { Route, Switch, Link } from "react-router-dom";
import { useStoreState } from './store/hooks/easyPeasy';
import TopNavBar from './topNavbar'
import { Sidebar } from './layouts/sidebar/Sidebar';
import logo from "../../../../src/assets/images/suite_logo.png";
import Dashboard from './container/pages/dashboard/Dashboard.page';
import Pendinglist from './container/pages/partner/PendingListSuperAdmin.page';
import ApprovedPartnerSDL from './container/pages/partner/ApprovedPartnerSDL.page';
import OrderListSuperAdmin from './container/pages/partner/OrderListSuperAdmin.page';
import PreApproveOrderListSuperAdmin from './container/pages/partner/PreApproveOrderListSuperAdmin.page';
import OrderFormPartner from './container/pages/partner/OrderFormPartner.page';
import BillGenerateMonthlySuperAdmin from './container/pages/partner/BillGenerateMonthlySuperAdmin.page';
import BillGenerateHalfYearlySuperAdmin from './container/pages/partner/BillGenerateHalfYearlySuperAdmin.page';
import BillGenerateYearlySuperAdmin from './container/pages/partner/BillGenerateYearlySuperAdmin.page';
import BillGenerateListSuperAdmin from './container/pages/partner/BillGenerateListSuperAdmin.page';
import BillDisbursSuperAdmin from './container/pages/partner/BillDisbursSuperAdmin.page';
import BillPaymentAPIPReport from './container/pages/partner/BillPaymentAPIPReport.page';
import BillPaymentPIPReport from './container/pages/partner/BillPaymentPIPReport.page';
import BillPaymentSDLReport from './container/pages/partner/BillPaymentSDLReport.page';
import BillPaymentSDLMonthlyReport from './container/pages/partner/BillPaymentSDLMonthlyReport.page';
import OrderStatusAPIPReport from './container/pages/partner/OrderStatusAPIPReport.page';
import OrderStatusSDLReport from './container/pages/partner/OrderStatusSDLReport.page';
import IspClientAddFormPIP from './container/pages/partner/IspClientAddFormPIP.page';
import IspClientBulkAddFormPIP from './container/pages/partner/IspClientBulkAddFormPIP.page';
import IspPackageSDLCreate from './container/pages/isp/IspPackageSDLCreate.page';
import IspClientListPIP from './container/pages/isp/IspClientListPIP.page';
import IspClientListSDL from './container/pages/isp/IspClientListSDL.page';
import IspBillListPIP from './container/pages/isp/IspBillListPIP.page';
 import IspBillGenerateSDL from './container/pages/isp/IspBillGenerateSDL.page';
import IspBillStatusSDL from './container/pages/isp/IspBillStatusSDL.page';
import IspBillListSDL from './container/pages/isp/IspBillListSDL.page';
import IspBillDisbursmentSDL from './container/pages/isp/IspBillDisbursmentSDL.page';
import IspDisbursementListSDL from './container/pages/isp/IspDisbursementListSDL.page';
import IspBillListMonthlySDL from './container/pages/isp/IspBillListMonthlySDL.page';
import IspBillListDailySDL from './container/pages/isp/IspBillListDailySDL.page';
import IspDisbursementListDailySDL from './container/pages/isp/IspBillListSDLDaywise.page';
import IigClientAddFormPIP from './container/pages/iig/IigClientAddFormPIP.page';
import IIGClientListPIP from './container/pages/iig/IIGClientListPIP.page';
import IIGBillListPIP from './container/pages/iig/IIGBillListPIP.page';
import IIGSmsBuy from './container/pages/iig/IIGSmsBuy.page';
import IIGBillGenerate from './container/pages/iig/IIGBillGenerate.page';
import IIGDiscountList from './container/pages/iig/IIGDiscountList.page';
import Profile from './container/pages/signup/Profile.page';
import SchoolSummary from './container/pages/public/ofps/SchoolSummary';
import SchoolInstitueSummary from './container/pages/public/ofps/SchoolInstitueSummary';
import ClientWiseTransactionSummary from './container/pages/spay/ClientWiseTransactionSummary';
import ClientVendorWiseTransactionSummary from './container/pages/spay/ClientVendorWiseTransactionSummary';
import SingleClientTransactionSummary from './container/pages/spay/SingleClientTransactionSummary';
import SingleClientVendorTransactionSummary from './container/pages/spay/SingleClientVendorTransactionSummary';
import DateWiseTransactionSummary from './container/pages/spay/DateWiseTransactionSummary';
import ClientDateWiseTransactionSummary from './container/pages/spay/ClientDateWiseTransactionSummary';
import IspBillSolve from './container/pages/isp/IspBillSolve';
import { AppstoreOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import IspOrderListDateWiseSDL from './container/pages/isp/IspOrderListDateWiseSDL.page';
import BillCollectionMenualSuperAdmin from './container/pages/partner/BillCollectionMenualSuperAdmin.page';
import BillGenerateWrapperAdmin from './container/pages/partner/BillGenerateWrapperAdmin.page';
import OrderStatusPendingListAPIPReport from './container/pages/partner/OrderStatusPendingListAPIPReport.page';
import OrderStatusPreApproveListAPIPReport from './container/pages/partner/OrderStatusPreApproveListAPIPReport.page';
import OrderStatusApproveListAPIPReport from './container/pages/partner/OrderStatusApproveListAPIPReport.page';
import ClientInfoUpdateSDL from './container/pages/partner/ClientInfoUpdateSDL.page';
import BillGenerateUnPaidListSuperAdmin from './container/pages/partner/BillGenerateUnPaidListSuperAdmin.page';
import BillGeneratePaidListSuperAdmin from './container/pages/partner/BillGeneratePaidListSuperAdmin.page';
import ProductOrderListExecutiveByOrderStatus from './container/pages/partner/ProductOrderListExecutiveByOrderStatus.page';
import ProductOrderListPartnerWise from './container/pages/partner/ProductOrderListPartnerWise';
import BillGenerateSingleAdmin from './container/pages/partner/BillGenerateSingleAdmin.page';
import SpgBillSchool from './container/pages/spg/bill/SpgBillSchool';
import SpgBillUniversity from './container/pages/spg/bill/SpgBillUniversity';

const { Header, Content, Sider } = Layout;
// Application
export default function Container() {
	const user = useStoreState(state => state.auth.user)
	const [collapsed, setCollapsed] = useState(false);
	const toggle = () => setCollapsed(!collapsed);
	var isMobile = false; //initiate as false
	if (
		/iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
	) {
		isMobile = true;
	}
	const onCollapse = collapsed => {
		//console.log("hello")
		//console.log(collapsed);
		setCollapsed(collapsed)
	};
	return (
		<Layout style={{ minHeight: '100vh' }}>

			<Sider className="side-menu-wrapper" collapsible collapsed={collapsed} onCollapse={onCollapse} breakpoint="lg" onBreakpoint={(broken) => {
				//console.log(broken);
			}} >
				<div className="logo">
					{!collapsed &&
						<>
							<Link to={'/'}  style={{color:"#fff"}} >Sheba Suite</Link>
							
						</>
					}
					{collapsed &&
						<div style={{ marginLeft: 0 }} ><AppstoreOutlined /></div>
					}
					{/* {!collapsed && <span style={{color:"white", fontSize:12}}> {user?.instituteName}</span>} */}
				</div>
				{user && <div onClick={() => setCollapsed(isMobile ? true : false)}> <Sidebar /></div>}
			</Sider>

			<Layout className="site-layout">
				<Header className="site-layout-background">



					<TopNavBar />




				</Header>
				<Content className="p-15">
					<Switch>
						<Route exact path="/" component={Dashboard} />
						<Route exact path="/profile" component={Profile} />
						<Route exact path="/pending" component={Pendinglist} />
						<Route exact path="/approved-partner" component={ApprovedPartnerSDL} />
						<Route exact path="/order-form" component={OrderFormPartner} />
						<Route exact path="/order-list" component={OrderListSuperAdmin} />
						<Route exact path="/preorder-list" component={PreApproveOrderListSuperAdmin} />
						<Route exact path="/bill-generate-monthly" component={BillGenerateMonthlySuperAdmin} />
						<Route exact path="/bill-generate-half-yearly" component={BillGenerateHalfYearlySuperAdmin} />
						<Route exact path="/bill-generate-yearly" component={BillGenerateYearlySuperAdmin} />
						<Route exact path="/bill-generate-list-shebashikkha" component={BillGenerateListSuperAdmin} />
						<Route exact path="/bill-disbursment" component={BillDisbursSuperAdmin} />
						<Route exact path="/bill-payment-apip" component={BillPaymentAPIPReport} />
						<Route exact path="/bill-payment-pip" component={BillPaymentPIPReport} />
						<Route exact path="/bill-payment-sdl" component={BillPaymentSDLReport} />
						<Route exact path="/bill-payment-sdl-monthly" component={BillPaymentSDLMonthlyReport} />
						<Route exact path="/order-status" component={OrderStatusAPIPReport} />
						<Route exact path="/sdl-order-status" component={OrderStatusSDLReport} />
						<Route exact path="/isp-client-add" component={IspClientAddFormPIP} />
						<Route exact path="/isp-client-add-bulk" component={IspClientBulkAddFormPIP} />
						<Route exact path="/isp-package-create" component={IspPackageSDLCreate} />
						<Route exact path="/isp-client-list" component={IspClientListPIP} />
						<Route exact path="/isp-client-list-sdl" component={IspClientListSDL} />
						<Route exact path="/isp-bill-generate" component={IspBillGenerateSDL} />
						<Route exact path="/isp-bill-status" component={IspBillStatusSDL} />
						<Route exact path="/isp-bill-list" component={IspBillListPIP} />
						<Route exact path="/isp-bill-list-sdl" component={IspBillListSDL} />
						<Route exact path="/isp-bill-list-monthly-sdl" component={IspBillListMonthlySDL} />
						<Route exact path="/isp-bill-list-datewise-sdl" component={IspBillListDailySDL} />
						<Route exact path="/isp-bill-list-daily-sdl" component={IspDisbursementListDailySDL} />
						<Route exact path="/isp-bill-disburstment-sdl" component={IspBillDisbursmentSDL} />
						<Route exact path="/isp-bill-disburstment-list-sdl" component={IspDisbursementListSDL} />
						<Route exact path="/isp-bill-problem" component={IspBillSolve} />
						<Route exact path="/iig-client-add" component={IigClientAddFormPIP} />
						<Route exact path="/iig-client-list" component={IIGClientListPIP} />
						<Route exact path="/iig-discount-list" component={IIGDiscountList} />
						<Route exact path="/iig-bill-list" component={IIGBillListPIP} />
						<Route exact path="/iig-sms-buy" component={IIGSmsBuy} />
						<Route exact path="/iig-bill-generate" component={IIGBillGenerate} />
						<Route exact path="/ofps-school-summary" component={SchoolSummary} />
						<Route exact path="/ofps-school-institute-summary" component={SchoolInstitueSummary} />
						<Route exact path="/client-tansaction-summary" component={ClientWiseTransactionSummary} />
						<Route exact path="/date-tansaction-summary" component={DateWiseTransactionSummary} />
						<Route exact path="/client-date-tansaction-summary" component={ClientDateWiseTransactionSummary} />
						<Route exact path="/client-vendor-tansaction-summary" component={ClientVendorWiseTransactionSummary} />
						<Route exact path="/single-client-tansaction-summary" component={SingleClientTransactionSummary} />
						<Route exact path="/single-client-vendor-tansaction-summary" component={SingleClientVendorTransactionSummary} /> 
						<Route exact path="/date-wise-order-list" component={IspOrderListDateWiseSDL} />
						<Route exact path="/client-info-update" component={ClientInfoUpdateSDL} />
						<Route exact path="/bill-generate-menual" component={BillCollectionMenualSuperAdmin} />
						<Route exact path="/bill-generate" component={BillGenerateWrapperAdmin} /> 
						<Route exact path="/order-pending-list" component={OrderStatusPendingListAPIPReport} />
						<Route exact path="/order-pre-approved-list" component={OrderStatusPreApproveListAPIPReport} />
						<Route exact path="/order-approved-list" component={OrderStatusApproveListAPIPReport} />
						<Route exact path="/unpaid-bill-list" component={BillGenerateUnPaidListSuperAdmin} />
						<Route exact path="/paid-bill-list" component={BillGeneratePaidListSuperAdmin} />
						<Route exact path="/product-order-executive-list" component={ProductOrderListExecutiveByOrderStatus} />
						<Route exact path="/product-order-list-partner-wise" component={ProductOrderListPartnerWise} />
						<Route exact path="/single-bill-generate" component={BillGenerateSingleAdmin} />
						<Route exact path="/ofps-conf-list-school" component={SpgBillSchool} />
						<Route exact path="/ofps-conf-list-university" component={SpgBillUniversity} />
						{/* <Route exact path="/payment" component={Payment} /> */}
					</Switch>
				</Content>
			</Layout>
		</Layout>
	);
}
