import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { SelectProductHalfYearly } from '../../select/SelectProductHalfYearly'
import moment from "moment";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectBillCycle } from '../../select/SelectBillCycle';
import { DeleteOutlined, DollarCircleFilled, EditFilled, EditOutlined, SaveFilled } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function BillCollectionMenualSuperAdmin(props) {

    const menualBillGenerateList = useStoreState((state) => state.bill.menualBillGenerateList);
    const fetchBillGenerateInstituteList = useStoreActions((state) => state.order.fetchBillGenerateInstituteList);
    const billGenerateInstituteList = useStoreState((state) => state.order.billGenerateInstituteList);
    const fetchMenualBillGenerateList = useStoreActions((state) => state.bill.fetchMenualBillGenerateList);
    const paymetManualBillGenerator = useStoreActions((state) =>  state.bill.paymetManualBillGenerator);
    const updateBillGenerateDiscountInfo = useStoreActions(state => state.bill.updateBillGenerateDiscountInfo);
    const deleteMenualGenerateBill = useStoreActions((state) =>  state.bill.deleteMenualGenerateBill);
    const [billgenerateForm] = Form.useForm();
    const [paymentForm] = Form.useForm();
    const [discountForm] = Form.useForm();
    const [searchv, setsearchv] = useState<any>('');
    const [searchPostdata, setSearchpostdata] = useState<any>('');
    const [filterTable, setfilterTable] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);
    const [billGenerateId, setBillGenerateId] = useState<any>(null);
    const [billGenerateMenualForm, setBillGenerateMenualForm] = useState<any>();

    const searchFormSubmit = (value) => {
        let postData:any = {
            clientId : value.instituteName,
            year: value.year
        }
        fetchMenualBillGenerateList(postData);
        setSearchpostdata(postData);
    };

    useEffect(() => {
        setPageTitle();
    }, []);

    useEffect(() => {
        setBillGenerateMenualForm(menualBillGenerateList)
    }, menualBillGenerateList)

    const [productId, setProductId] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>(null);

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Bill Collection Menual '
        return pageTitleEle ?  pageTitleEle.innerHTML = pageTitle : ''      
    }

    const columns = [
        {
            title: 'Client ID',
            dataIndex: 'clientProvidedId',
            key: 'clientProvidedId',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Client Unit',
            dataIndex: 'clientUnit',
            key: 'clientUnit',
        },
        {
            title: 'Bill Month',
            dataIndex: 'billingMonth',
            key: 'billingMonth',
        },
        {
            title: 'Bill Year',
            dataIndex: 'billingYear',
            key: 'billingYear',
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Discount Note',
            dataIndex: 'discountNote',
            key: 'discountNote',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
        },
        // {() => setIsDiscountModalVisible(false)}
        {
            title: 'Action',
            key: 'clientId',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Payment">
                        <Button type="primary" icon={<DollarCircleFilled />} onClick={() => { 
                            setIsModalVisible(true);
                            paymentForm.setFieldsValue({
                                billGenerateId : record.billGenerateId,
                                paidAmount: record.payableAmount
                            })
                        }} />
                    </Tooltip>
                    <Tooltip title="Dicount">
                        <Button type="primary" icon={<EditFilled />} onClick={() => { 
                            setIsDiscountModalVisible(true);
                            setBillGenerateId(record.billGenerateId);
                            discountForm.setFieldsValue({
                                discount : record.discount,
                                discountNote : record.discountNote
                            })
                        }} />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure to delete?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteBillGenerateManualList(record.billGenerateId)}
                        >
                            <Tooltip title="Delete">
                                <Button danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )

        }
    ];

    const onchangeproductId = (val) => {
        setProductId(val);
        fetchBillGenerateInstituteList(val.productCustomId);
    }


    let year = new Date().getFullYear();

    
    const search = value => {

        const result = menualBillGenerateList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const paymentFormSubmit = (val) => {
        let payload:any = {
            payloadData:{
                bankTransactionId: val.bankTransactionId,
                billGenerateId: val.billGenerateId,
                billPaymentDate: moment(val?.billPaymentDate).format("YYYY-MM-DD"),
                cardType: val.cardType,
                paidAmount: val.paidAmount,
            },
            search: searchPostdata,
        }
        paymetManualBillGenerator(payload);
        paymentForm.resetFields();
        setIsModalVisible(false);
    }

    const discountSubmitForm = (values) => {
        values.billGenerateId = billGenerateId;
        updateBillGenerateDiscountInfo(values);
        billgenerateForm.submit();
        setIsDiscountModalVisible(false);
    }

    const deleteBillGenerateManualList = (value) => {
        deleteMenualGenerateBill(value);
        billgenerateForm.submit();
    }

    return (
        <>
            <Card title="Bill Collection Menual Form">
                
                <Row>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5}></Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Form
                            layout="vertical"
                            onFinish={searchFormSubmit}
                            id="basic-info"
                            form={billgenerateForm}
                        >
                            <Row>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    <Form.Item
                                        name="product_id"
                                        label="Product:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select your product" },
                                        ]}
                                    >
                                        <SelectProductHalfYearly onChange={(val) => onchangeproductId(val)} selected={productId} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    <Form.Item
                                        name="instituteName"
                                        label="Institute:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select institute" },
                                        ]}
                                    >
                                        <Select 
                                            allowClear
                                            filterOption={(inputValue, option) =>
                                                option?.props.children
                                                .toString()
                                                .toLowerCase()
                                                .includes(inputValue.toLowerCase())
                                            }
                                            showSearch
                                        >
                                            {billGenerateInstituteList.map((item, index) => {
                                                return (
                                                    <Option key={item.clientId} value={item.clientId}>{item.clientProvideId} - {item.clientName}</Option>
                                                )
                                            })}
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    <Form.Item
                                        name="year"
                                        label="Year:"
                                        className="title-Text"
                                        initialValue={year}
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <Select placeholder="Select Year" allowClear>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    <Button type="primary" htmlType="submit" className="pull-right-lg mt-30-lg">
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {menualBillGenerateList &&  menualBillGenerateList?.length > 0 ? 
                        <Row className='mt-15'>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                
                                <>
                                    <span>Total Found: {menualBillGenerateList !== null ? menualBillGenerateList?.length : 0}</span>
                                    <Input
                                        style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                                        placeholder="Search..."
                                        value={searchv}
                                        allowClear
                                        onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                                    />
                                    <Table
                                        bordered={true}
                                        dataSource={searchv === '' ? menualBillGenerateList : filterTable}
                                        columns={columns}
                                        rowKey={(record) => record.clientId}
                                        pagination={false}
                                        locale={{ emptyText: 'Select product to see client list' }}
                                        style={{ maxHeight: 400 }}
                                    />
                                </>
                                
                            </Col>
                            
                        </Row>
                            : ''}
                    </Col>
                </Row>                 
            </Card>
            <Modal
                title="Bill Payment"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                centered
                cancelText="Close"
                okText="Payment"
                maskClosable={false}
                okButtonProps={{ form: 'paymentForm', htmlType: 'submit' }}
            >
                <Form
                    layout='vertical'
                    form={paymentForm}
                    id="paymentForm"
                    onFinish={paymentFormSubmit}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="bankTransactionId"
                                label="Transaction ID"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write transection ID" },
                                ]}
                            >
                                <Input placeholder='Write Transection ID'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="billGenerateId"
                                label="Bill Generate ID"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Set Bill Generate ID" },
                                ]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="billPaymentDate"
                                label="Bill Payment Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Set Bill Generate ID" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="cardType"
                                label="Payment Type"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Select Payment Type" },
                                ]}
                            >
                                <Select placeholder="Select Payment Type">
                                    <Option key={0} value="Cash">Cash</Option>
                                    <Option key={1} value="Bank">Bank</Option>
                                    <Option key={2} value="Mobile Banking">Mobile Banking</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="paidAmount"
                                label="Paid amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Set Bill Paid Amount" },
                                ]}
                            >
                                <Input disabled placeholder='Please Set Bill Paid Amount' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="Discount"
                visible={isDiscountModalVisible}
                onCancel={() => setIsDiscountModalVisible(false)}
                centered
                cancelText="Close"
                okText="Save"
                maskClosable={false}
                okButtonProps={{ form: 'discountForm', htmlType: 'submit' }}
            >
                <Form
                    layout='vertical'
                    form={discountForm}
                    id="discountForm"
                    onFinish={discountSubmitForm}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="discount"
                                label="Discount:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input discount", },
                                ]}
                            >
                                <Input type="text" placeholder="Discount" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="discountNote"
                                label="Discount Note:"
                                className="title-Text"
                            >
                                <Input.TextArea placeholder="Discount Note"></Input.TextArea>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
