import { DeleteOutlined, EditOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'


export default function IspPackageSDLCreate(props) {

    const check = useStoreState(state => state.isp.check);
    const ispPackageInfoList = useStoreState(state => state.isp.ispPackageInfoList);
    const fetchispPackageInfoList = useStoreActions(state => state.isp.fetchispPackageInfoList);
    const saveIspPackageInfo = useStoreActions(state => state.isp.saveIspPackageInfo);
    const updateIspPackageInfo = useStoreActions(state => state.isp.updateIspPackageInfo);


    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();

    const onFinish = (values: any) => {
        saveIspPackageInfo(values)
        form.resetFields();
    };

    const onFinishUpdate = (values: any) => {
        values.packageId= row;
        updateIspPackageInfo(values)
        if (check){
            setIsModalVisible(false);
        }
    };


    useEffect(() => {
        setPageTitle();
    }, []);

    useEffect(() => {
        fetchispPackageInfoList();
    }, [check])


    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [row, setRow] = useState<any>();
    const columns = [

        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Package Price',
            dataIndex: 'packagePrice',
            key: 'packagePrice',

        },
        {
            title: 'Approve',
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Tooltip title="Update Package">
                        <Button type="primary" icon={<EditOutlined />} onClick={() => {  showModal(record);}} >Update</Button>
                    </Tooltip>

                </Space>
            ),
        },

    ]

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (val) => {
        setRow(val?.packageId);
        formUpdate.setFieldsValue({
            packageName: val?.packageName,
            packagePrice: val?.packagePrice,
        });
        setIsModalVisible(true);

    };

    const handleOk = () => {
        // setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    return (
        <>
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} className="box-shadow p-15">
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="packageName"
                            label="Package Name:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please insert package name" },
                            ]}
                        >
                            <Input placeholder="Package Name" ></Input>


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="packagePrice"
                            label="Package Price:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please insert package price" },
                            ]}
                        >
                            <InputNumber min={0} placeholder="Package Price" style={{ width: "100%" }}></InputNumber>
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Save
                            </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>
            {ispPackageInfoList?.length > 0 &&
                <Card title="ISP Package List" >

                    <Table bordered={true} dataSource={ispPackageInfoList} columns={columns} />

                </Card>

            }
            <Modal
                title="Update Package"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"50%"}
                okText="Update"
                centered
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <Form layout="vertical" onFinish={onFinishUpdate} id='update-class' form={formUpdate} >
                    <Row gutter={15} >

                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>

                            <Form.Item
                                name="packageName"
                                label="Package Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert package name" },
                                ]}
                            >
                                <Input placeholder="Package Name" ></Input>


                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>

                            <Form.Item
                                name="packagePrice"
                                label="Package Price:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please insert package price" },
                                ]}
                            >
                                <InputNumber min={0} placeholder="Package Price" style={{ width: "100%" }}></InputNumber>
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                            <Space size="small" style={{ marginTop: 30 }} >
                                <Button type="primary" htmlType="submit" >
                                    Update
                                </Button>
                            </Space>

                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
