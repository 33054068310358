import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { fetchgetSmsBalanceSinglePartnerInfo, fetchiigClientBillInfoListForPip, fetchiigClientInfoListForPip } from '../../../http/iig/iig';

export interface IIG {

	iigClientInfoListForPip: any,
	setiigClientInfoListForPip: Action<IIG, any>;
	fetchiigClientInfoListForPip: Thunk<IIG>;

	iigClientBillInfoListForPip: any,
	setiigClientBillInfoListForPip: Action<IIG, any>;
	fetchiigClientBillInfoListForPip: Thunk<IIG>;

	getSmsBalanceSinglePartnerInfo: any,
	setgetSmsBalanceSinglePartnerInfo: Action<IIG, any>;
	fetchgetSmsBalanceSinglePartnerInfo: Thunk<IIG>;

}

export const iigStore: IIG = {
	iigClientInfoListForPip: null,
	fetchiigClientInfoListForPip: thunk(async (actions, payload) => {
		const response = await fetchiigClientInfoListForPip();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body);
			actions.setiigClientInfoListForPip(body?.item);
		} else {
			actions.setiigClientInfoListForPip(0);
		}
	}),
	setiigClientInfoListForPip: action((state, payload) => {
		state.iigClientInfoListForPip = payload;
	}),

	iigClientBillInfoListForPip: null,
	fetchiigClientBillInfoListForPip: thunk(async (actions, payload) => {
		const response = await fetchiigClientBillInfoListForPip(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setiigClientBillInfoListForPip(body?.item);
			if (body?.item?.length === 0) {
				notification['error']({
					message: 'ISP',
					description: "No Data Found",
				});
			}

		} else {
			actions.setiigClientBillInfoListForPip(null);
		}
	}),
	setiigClientBillInfoListForPip: action((state, payload) => {
		state.iigClientBillInfoListForPip = payload;
	}),	
	getSmsBalanceSinglePartnerInfo: null,
	fetchgetSmsBalanceSinglePartnerInfo: thunk(async (actions, payload) => {
		const response = await fetchgetSmsBalanceSinglePartnerInfo();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();

			actions.setgetSmsBalanceSinglePartnerInfo(body?.item);
		}
	}),
	setgetSmsBalanceSinglePartnerInfo: action((state, payload) => {
		state.getSmsBalanceSinglePartnerInfo = payload;
	}),

}
