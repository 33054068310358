import { Button, Col, DatePicker, Form, Input, Result, Row, Space, Card, InputNumber, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectIPartnerMultiple } from '../../select/SelectIPartnerMultiple'
import { SelectYear } from '../../select/SelectYear'
import { SelectMonth } from '../../select/SelectMonth'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'



export default function IspBillStatusSDL(props) {

    const [billGenerateForm] = Form.useForm();

    useEffect(() => {

        setTimeout(function () {
            setPageTitle();
        })
    }, [])

    const fetchbillPaymentInfoStatus = useStoreActions(state => state.isp.fetchbillPaymentInfoStatus);
    const billPaymentIpn = useStoreActions(state => state.isp.billPaymentIpn);
    const billPaymentInfoStatus = useStoreState(state => state.isp.billPaymentInfoStatus);

    const onBlillGenerateSubmit = (value) => {
        fetchbillPaymentInfoStatus(value);

    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };


    console.log(billPaymentInfoStatus)

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'BILL STATUS'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [clientId, setClientid] = useState<any>(null);


    const updateMestro = () => {
        let payload = {
            "billAmount": billPaymentInfoStatus?.billAmount,
            "bankTransactionId": billPaymentInfoStatus?.bankTransactionId,
            "clientCustomId": clientId
        }
       // console.log(payload)
        billPaymentIpn(payload);
    }

    return (
        <>
            <Card title="" >

                <Form
                    layout="horizontal"
                    {...layout}
                    onFinish={onBlillGenerateSubmit}
                    id="basic-info"
                    form={billGenerateForm}
                >
                    <div className="__orderForm p-0 custom-form">
                        <Row gutter={8}>


                            <Col xs={24} sm={24} md={24} lg={2} xl={2}>  </Col>

                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                <Form.Item
                                    name="billingYear"
                                    label="Year:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select billingYear" },
                                    ]}
                                >
                                    <SelectYear />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                <Form.Item
                                    name="billingMonth"
                                    label="Month:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select billingMonth" },
                                    ]}
                                >
                                    <SelectMonth />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                <Form.Item
                                    name="clientCustomId"
                                    label="Client Id:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please insert client id" },
                                    ]}
                                >
                                    <Input placeholder="Client Id" onChange={e => setClientid(e.target.value)} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                <Space size="small" style={{ float: "right" }}>
                                    <Button type="primary" htmlType="submit" >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        {billPaymentInfoStatus !== null &&
                            <div style={{ marginTop: 30 }}>
                                <Row gutter={8}>
                                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                        <table className="GeneratedTable">

                                            <tbody>
                                                <tr>
                                                    <td>Client Name:</td>
                                                    <td style={{ fontWeight: "bold", float: "right" }}>{billPaymentInfoStatus?.clientName}</td>

                                                </tr>
                                                <tr>
                                                    <td>Mobile:</td>
                                                    <td style={{ fontWeight: "bold", float: "right" }}>{billPaymentInfoStatus?.mobile}</td>
                                                </tr>
                                                <tr>
                                                    <td>Package:</td>
                                                    <td style={{ fontWeight: "bold", float: "right" }}>{billPaymentInfoStatus?.packageName}</td>
                                                </tr>
                                                <tr>
                                                    <td>Price:</td>
                                                    <td style={{ fontWeight: "bold", float: "right" }}>{billPaymentInfoStatus?.billAmount}</td>
                                                </tr>
                                                <tr>
                                                    <td>SDL Bill Status:</td>
                                                    <td style={{ fontWeight: "bold", float: "right" }}>{billPaymentInfoStatus?.billStatusOfSdl}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mestro Bill Status:</td>
                                                    <td style={{ fontWeight: "bold", float: "right" }}>{billPaymentInfoStatus?.billStatusOfMestro}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mestro Message:</td>
                                                    <td style={{ fontWeight: "bold", float: "right" }}>{billPaymentInfoStatus?.mestroApiMessage}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mestro Code:</td>
                                                    <td style={{ fontWeight: "bold", float: "right" }}>{billPaymentInfoStatus?.mestroApiCode}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <Space size="small" style={{ float: "right" }}>
                                            <Popconfirm
                                                title="Are you sure you want to update mestro?"
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={() => updateMestro()}
                                                disabled={billPaymentInfoStatus?.billStatusOfMestro === "Paid" ? true : false}
                                                // disabled={billPaymentInfoStatus?.billStatusOfSdl === 'Unpaid'?true:(billPaymentInfoStatus?.billStatusOfMestro === 'Paid' && billPaymentInfoStatus?.billStatusOfSdl === 'Paid') ? true : false}
                                            >
                                                <Button
                                                    type={billPaymentInfoStatus?.billStatusOfMestro === 'Paid' ? "default" : "primary"}
                                                    disabled={billPaymentInfoStatus?.billStatusOfMestro === 'Paid' ? true : false}
                                                >
                                                    Mestro Updates</Button>
                                            </Popconfirm>
                                            {/* <Button type="primary">SDL Update</Button> */}
                                        </Space>

                                    </Col>
                                </Row>

                            </div>
                        }
                    </div>
                </Form>

            </Card>

        </>
    )
}
