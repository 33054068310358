import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd'
import React, { useState, useEffect } from 'react'
import { SelectProductHalfYearly } from '../../select/SelectProductHalfYearly'
import moment from "moment";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectBillCycle } from '../../select/SelectBillCycle';

const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function BillGenerateHalfYearlySuperAdmin(props) {

    const saveProductBillGenerateHalfYear = useStoreActions(state => state.bill.saveProductBillGenerateHalfYear)
    const clientHalfYearly = useStoreState((state) => state.order.clientHalfYearly);
    const fetchclienHalfYearly = useStoreActions((state) => state.order.fetchclienHalfYearly);
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);
    const [billgenerateForm] = Form.useForm();
    const [searchv, setsearchv] = useState<any>('');
    const [filterTable, setfilterTable] = useState<any>(null);

    const orderFormSubmit = (value) => {
        //  console.log(value)
        let postData:any = [];
        selectedValue.map(function (item, index) {
           postData.push(item.clientId);
        })
        let postHalfYearly = {
            clientIds: postData,
            productId: value?.product_id?.productId,
            month: value?.month,
            year: value?.year,
        }

        console.log('postHalfYearly', postHalfYearly);
        
        saveProductBillGenerateHalfYear(postHalfYearly);
        // billgenerateForm.resetFields();

    };

    useEffect(() => {
        setPageTitle();
    }, []);

    const [productId, setProductId] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>(null);

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Bill Generate Half Yearly'
        return pageTitleEle ?  pageTitleEle.innerHTML = pageTitle : ''      
    }

    const columns = [
        {
            title: 'Client ID',
            dataIndex: 'clientProvideId',
            key: 'clientProvideId',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
            key: 'contactNo',
        }
    ];

    const onchangeproductId = (val) => {
        setProductId(val);
        fetchclienHalfYearly(val?.productCustomId);
    }

    const onchangebillCycle = (val) => {
        //   console.log(val)
        setBillCycle(val)
    }

    let year = new Date().getFullYear();

    
    const search = value => {

        const result = clientHalfYearly.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };


    

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <Form
                    layout="horizontal"
                    {...layout}
                    onFinish={orderFormSubmit}
                    id="basic-info"
                    form={billgenerateForm}
                >
                    <div className="__orderForm p-0 custom-form">
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            name="product_id"
                                            label="Product:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select your product" },
                                            ]}
                                        >
                                            <SelectProductHalfYearly onChange={(val) => onchangeproductId(val)} selected={productId} />
                                        </Form.Item>
                                    </Col>
                                    {/* 
                                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>    
                                        <Form.Item
                                        name="bill_cycle"
                                        label="Bill Cycle:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select bill cycle" },
                                        ]}
                                    >
                                        <SelectBillCycle onChange={(val) => onchangebillCycle(val)} selected={billCycle}

                                        />
                                    </Form.Item> 
                                    </Col>
                                    */}
                                </Row> 
                                <Row>   
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            name="year"
                                            label="Year:"
                                            className="title-Text"
                                            initialValue={year}
                                            rules={[
                                                { required: true, message: "Please select year" },
                                            ]}
                                        >
                                            <Select placeholder="Select Year" allowClear>
                                                <Option value={year - 1}>{year - 1}</Option>
                                                <Option value={year}>{year}</Option>
                                                <Option value={year + 1}>{year + 1}</Option>
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item
                                            name="month"
                                            label="Month:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select month" },
                                            ]}
                                        >
                                            <Select placeholder="Select Month Range" allowClear>
                                                <Option value="January-June">January-June</Option>
                                                <Option value="July-December">July-December</Option>
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                            {clientHalfYearly &&  clientHalfYearly?.length > 0 ? 
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        
                                        <>
                                            <span>Total Found: {clientHalfYearly !== null ? clientHalfYearly?.length : 0}</span>
                                            <Input
                                                style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                                                placeholder="Search..."
                                                value={searchv}
                                                allowClear
                                                onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                                            />
                                            <Table
                                                bordered={true}
                                                dataSource={searchv === '' ? clientHalfYearly : filterTable}
                                                columns={columns}
                                                rowSelection={rowSelection}
                                                rowKey={(record) => record.clientId}
                                                pagination={false}
                                                locale={{ emptyText: 'Select product to see client list' }}
                                                style={{ maxHeight: 400 }}
                                            />
                                        </>
                                       
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Space size="small" className="pull-right" style={{ marginTop: "15px" }}>
                                            <Button type="primary" htmlType="submit" >
                                                Generate
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                                 : ''}
                            </Col>
                        </Row>
                    </div>
                </Form>
        </>
    )
}
