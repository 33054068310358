import { Button, Card, Col, DatePicker, Form, Input, Row, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';


export default function DateWiseTransactionSummary(props) {

    const dateToDateTansactionDetails = useStoreState((state) => state.common.dateToDateTansactionDetails);
    const fetchdateToDateTansactionDetails = useStoreActions((state) => state.common.fetchdateToDateTansactionDetails);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }

        fetchdateToDateTansactionDetails(postData);
    };


    const columns = [

        {
            title: 'Client Name',
            dataIndex: 'clinetName',
            key: 'clinetName',
        },
        {
            title: 'Date',
            dataIndex: 'trnDate',
            key: 'trnDate',
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobileNo',
            key: 'mobileNo',
        },
        {
            title: 'Trn. Method',
            dataIndex: 'trnMethod',
            key: 'trnMethod',
        },
        {
            title: 'Trn. ID',
            dataIndex: 'trnId',
            key: 'trnId',
        },
        {
            title: 'Trn. Amount',
            dataIndex: 'trnAmount',
            key: 'trnAmount',
        },
        {
            title: 'Invoice No.',
            dataIndex: 'invoiceNo',
            key: 'invoiceNo',
        },
        {
            title: 'Val Id',
            dataIndex: 'valId',
            key: 'valId',
        },
    ]

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = dateToDateTansactionDetails.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)

    };

    return (
        <>
            <Card title="Date Wise Transaction Summary" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Button type="primary" htmlType="submit" style={{ height: 40, marginTop: 30 }} icon={<SearchOutlined />}>
                                Search
                            </Button>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {dateToDateTansactionDetails !== null &&
                    <>
                        <Input
                            style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                            placeholder="Search..."
                            value={searchv}
                            allowClear
                            onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                        />
                        <Table
                            columns={columns}
                            dataSource={searchv === '' ? dateToDateTansactionDetails : filterTable}
                            bordered={true}
                            summary={pageData => {
                                let total = 0;
                                dateToDateTansactionDetails.forEach(({ trnAmount }) => {
                                    total += trnAmount;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} > <div style={{ textAlign: 'right' }}>Total</div></Table.Summary.Cell>
                                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={6}>
                                                <Text type="danger" strong>{total}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                        </Table.Summary.Row>

                                    </>
                                );
                            }}
                        />

                    </>
                }
            </Card>
        </>
    )
}
