import { Tabs , Button, Card, Col, DatePicker, Form, Input, InputNumber, notification, Row, Select, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'


export default function BillGenerateSingleAdmin(props) {
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
const [selectedValue, setselectedValue] = useState<any>([]);
    const [form] = Form.useForm();
    
    const singleBillGenerate = (val) => {

    }

    return (
        <>
            <Card title="Single Bill Generate">
                <Form
                    layout="vertical"
                    form={form}
                    id="updatePartner"
                    onFinish={singleBillGenerate}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="product_id"
                                label="Product:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your product" },
                                ]}
                            >
                                {/* <SelectProduct onChange={(val) => {onchangeproductId(val); setselectedRowKeys([]); setselectedValue([])}} selected={productId} /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}
