import { DeleteOutlined, EditOutlined, SaveOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectPartner } from '../../select/SelectIPartner'
import moment from 'moment'
import jsPDF from "jspdf";
import "jspdf-autotable";
import Text from 'antd/lib/typography/Text'
import { SelectIPartnerForIsp } from '../../select/SelectIPartnerForIsp'

export default function IspDisbursementListSDL(props) {

    const check = useStoreState(state => state.isp.check);
    const dateWiseIspClientBillInfoList = useStoreState(state => state.isp.dateWiseIspClientBillInfoList);
    const fetchdateWiseIspClientBillInfoList = useStoreActions(state => state.isp.fetchdateWiseIspClientBillInfoList);
    const [form] = Form.useForm();
    const fetchpartnerListForIsp = useStoreActions((state) => state.partner.fetchpartnerListForIsp);
    const [pdfValue,setpdfValue] = useState<any>();
    const [partnerName,setpartnerName] = useState<any>();

    const onFinish = (values: any) => {
        values.fromDate = moment(values?.fromDate).format("YYYY-MM-DD");
        values.toDate = moment(values?.toDate).format("YYYY-MM-DD");
        values.partnerId= values.partnerId.value
        console.log(values)
        setpdfValue(values)
        fetchdateWiseIspClientBillInfoList(values)
        // form.resetFields();
    };


    useEffect(() => {
        setPageTitle();
        fetchpartnerListForIsp("ISP");
    }, []);


    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }


    const columns = [

        {
            title: 'Id',
            dataIndex: 'clientCustomId',
            key: 'clientCustomId',

        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',

        },
        {
            title: 'Client Mobile',
            dataIndex: 'clientMobile',
            key: 'clientMobile',

        },

        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Bill Amount',
            dataIndex: 'billAmount',
            key: 'billAmount',

        },

        {
            title: 'Payment Date',
            dataIndex: 'billPaymentDate',
            key: 'billPaymentDate',

        },
        {
            title: 'Payable Date',
            dataIndex: 'billPayableDate',
            key: 'billPayableDate',

        },


    ]

    function downloadPdf() {
        const doc = new jsPDF("landscape");
        var totalPagesExp = '{total_pages_count_string}';
        const tableColumn = ["Id", "Client Name", "Client Mobile", "Package Name", "Bill Amount", "Payment Date", "Payable Date"];
        const tableRows: any[][] = [];
        var width = doc.internal.pageSize.getWidth()
        doc.text('Sheba Digital Limited (SDL)', width/2, 20, { align: 'center' })
        doc.setFontSize(12)
        doc.text(`Sheba Net bill details of ${partnerName}`, width/2, 27, { align: 'center' })
        doc.setFontSize(10)
        doc.text(`Date ${pdfValue.fromDate} to ${pdfValue.toDate}`, width/2, 32, { align: 'center' })
        let totalBill =0
        dateWiseIspClientBillInfoList.forEach((item: any) => {
            const itemData = [
                item.clientCustomId,
                item.clientName,
                item.clientMobile,
                item.packageName,
                item.billAmount,
                item.billPaymentDate,
                item.billPayableDate,

            ];
            totalBill+=item.billAmount
            tableRows.push(itemData);
        });
    

        // @ts-ignore

 
        doc.autoTable(tableColumn, tableRows, { 
            startY: 37, 
            pageBreak: "auto", 
            rowPageBreak: "auto", 
            theme:"grid",
            didDrawPage: function (data) {
                doc.setFontSize(10);
            
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height
                  ? pageSize.height
                  : pageSize.getHeight();
                  doc.setTextColor(150)
                  doc.setFontSize(10)
                doc.text('Powered by Sheba Digital Limited | Part of Sheba Group.',  width/2-40, pageHeight - 10);
              } 
              
        },);
        let finalY = (doc as any).lastAutoTable.finalY;
        doc.text(`Total Bill Amount: ${totalBill} `, width-52, finalY + 10)
        

        doc.save(`Bill.pdf`);
    }



    return (
        <>
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} className="box-shadow p-15">
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="fromDate"
                            label="From Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select From Date" />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="toDate"
                            label="To Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select To Date" />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="partnerId"
                            label="Partner:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select partner" },
                            ]}
                        >
                            <SelectIPartnerForIsp name="PIP" level={2} onChange={(e)=>setpartnerName(e?.label)} />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}></Col>
                </Row>
            </Form>
            {dateWiseIspClientBillInfoList?.length > 0 &&
                <Card title="Bill  List" >
                    <Text style={{float:"right", marginBottom:5}}>Total Found: {dateWiseIspClientBillInfoList?.length}</Text>
                    <Table 
                    bordered={true} 
                    dataSource={dateWiseIspClientBillInfoList} 
                    columns={columns} 
                    // pagination={false}
                    rowKey="billId" 
                    summary={pageData => {
                        let totalBill = 0;

                        dateWiseIspClientBillInfoList.forEach(({ billAmount }) => {
                            totalBill += billAmount;
                        });

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}> <Text type="danger" strong>Total</Text></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>

                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>

                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>

                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                    <Text type="danger" strong>{totalBill}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>
                                   
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>
                                       
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>

                                    </Table.Summary.Cell>
                                </Table.Summary.Row>

                            </>
                        );
                    }}
                    />

                    <Button type="primary" icon={<SaveOutlined />} onClick={downloadPdf} style={{float:'right'}}> Download Report </Button>
                </Card>

            }

        </>
    )
}
