import React from 'react';
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "./store/store";
import { Button, Card, Col, List, Popover, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { KeyOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useStoreState } from './store/hooks/easyPeasy';
import { Link } from "react-router-dom";
const { Text } = Typography;

export default function TopNavBar() {

  const logout = useStoreActions(
    (actions: Actions<StoreModel>) => actions.auth.logout
  );
  const logoutclear = useStoreActions(
    (actions: Actions<StoreModel>) => actions.auth.logoutclear
  );

  const user = useStoreState(state => state.auth.user)
  const partnerProfile = useStoreState(state => state.common.partnerProfile);
  const logoutfunc = () => {
    logoutclear(user?.access_token);
    logout("");
  }
  const profilePopover = (onLogout, userData) => (
    <Card
      style={{ width: 300, marginTop: 10, }}
    >
<ul style={{marginBottom:-5}}>
<Link to="/profile" ><li style={{marginLeft:-40,}} className="headerDrop"><div style={{display:"flex", justifyContent:"space-between"}}>Change Password<span><KeyOutlined style={{color:"#1890ff"}} /></span></div></li> </Link>
  <li style={{marginLeft:-40, marginTop:10, cursor:"pointer", }} className="headerDrop" onClick={onLogout}><div style={{display:"flex", justifyContent:"space-between", color:"#1890ff"}}><span  style={{cursor:"pointer"}} >Logout</span><span><LogoutOutlined  style={{color:"#1890ff"}} /></span></div></li>
</ul>
    </Card>
  );
  return (
    <>
      <div style={{ float: "left" }}>
        <h3 id="pageTitle">Sheba Suite</h3>
      </div>
      <div style={{ float: 'right', marginLeft: -10 }}>
        <Popover
          content={profilePopover(logoutfunc, user)}
          placement="bottomLeft"
          trigger="click"
          style={{ float: "right" }}
        >

          <Avatar
            src={(partnerProfile?.partnerImage!==null || partnerProfile?.partnerImage!=='') ?partnerProfile?.partnerImage:'' }
            size="large"
            icon={<UserOutlined />}
            style={{ marginRight: 5 }}
            className="pointer topUserImage"
          />
        </Popover>
      </div>

    </>
  );
}