import { ArrowRightOutlined, CloudDownloadOutlined, GatewayOutlined } from '@ant-design/icons';
import { Button, Layout, Form, Input, Row, Col, Select, Tabs, Space, Card, Tooltip } from 'antd'
import React, { useState } from 'react'
import logo from "../../../../../src/assets/images/isp.png"
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import moment from 'moment'

const { Option } = Select;
const { TabPane } = Tabs;
export default function IspBillPay(props) {

    const fetchispClientSingleInfo = useStoreActions(state => state.isp.fetchispClientSingleInfo);
    const fetchgetGatewayPageUrl = useStoreActions(state => state.isp.fetchgetGatewayPageUrl);
    const downloadVoucher = useStoreActions(state => state.isp.downloadVoucher);
    const ispClientSingleInfo = useStoreState(state => state.isp.ispClientSingleInfo);

    const onSumbit = (value) => {
        console.log(value)
        fetchispClientSingleInfo(value)
    }


    return (
        <>
            <Layout className="isp-form-wrapper">
                <div className="container">


                    <div className="col-md-12" style={{}}>
                        <div style={{ height: "100%", background: "#fff", opacity: .95, borderRadius: 20, paddingLeft: 10, paddingRight: 10 }}>

                            <div style={{ padding: 10, textAlign: "center", paddingBottom: 40, }}>
                                <img src={logo} style={{ height: 30, marginBottom: 5, marginTop: 20 }} /> <br />
                                <span style={{ fontSize: 15 }}>Internet Bill Payment</span>
                            </div>


                            <Form layout="vertical" style={{ textAlign: "center", marginTop: -20, marginBottom: 20 }} onFinish={onSumbit} >
                                <Row gutter={4}  >
                                    <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                        <Form.Item
                                            label="Client Id "
                                            name="clientCustomId"
                                            rules={[{ required: true, message: 'Please input your client id' }]}
                                            style={{ textAlign: "left" }}
                                        >
                                            <Input
                                                placeholder="Client Id "
                                                style={{ width: "100%", borderRadius: 5 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                        <Form.Item
                                            label="Year "
                                            name="year"
                                            rules={[{ required: true, message: 'Please select year' }]}
                                            style={{ textAlign: "left", borderRadius: 5, }}
                                            initialValue="2021"
                                        >
                                            <Select bordered dropdownStyle={{ borderRadius: 5, }} placeholder="Select Year" defaultValue="2021" style={{ textAlign: 'left' }} allowClear>
                                                <Option value="2020">2020</Option>
                                                <Option value="2021">2021</Option>
                                                <Option value="2022">2022</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                        <Space size="small" style={{ marginTop: 30 }} >
                                            <Button type="primary" htmlType="submit" style={{ borderRadius: 5, }}>
                                                Search
                                            </Button>
                                        </Space>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                </Row>
                            </Form>
                            {ispClientSingleInfo !== null && <>
                                <div style={{
                                    // border: "1px solid #ccc",
                                    padding: 10,
                                    borderRadius: 5,
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    marginBottom: 20,
                                    boxShadow: "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"
                                }}>

                                    <span style={{ fontWeight: "bold", marginLeft: 10 }}>{ispClientSingleInfo?.clientName}</span>
                                    <span style={{ fontWeight: "bold", marginRight: 10 }}>{ispClientSingleInfo?.mobileNo}</span>
                                </div>

                                <Tabs defaultActiveKey="1" style={{ marginTop: -30, padding: 20 }}>
                                    <TabPane tab="Unpaid" key="1">
                                        {ispClientSingleInfo?.billingInfos?.filter((item: any) => item.status === 0)?.length === 0 &&
                                            <div key={1} style={{ marginTop: 20, textAlign: 'center', fontSize: "20", fontWeight: "bold", color: "#1BD559" }}>
                                                No unpaid bill found
                                            </div>
                                        }
                                        {ispClientSingleInfo?.billingInfos?.filter((item: any) => item.status === 0).map((item: any, index: any) => (
                                            <div key={index} style={{
                                                padding: 12,
                                                marginBottom: 20,
                                                borderRadius: 5,
                                                boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"
                                            }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                                    <span style={{ fontSize: 15, fontWeight: "bold" }}>Bill Amount</span>
                                                    <span style={{ fontSize: 15, fontWeight: "bold" }}>Bill: {item?.billingMonth}, {item?.billingYear}</span>
                                                    <span style={{ fontSize: 15, fontWeight: "bold" }}>Payable Date: {moment(item?.payableDate).format('DD MMM y')}</span>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                    <span style={{ fontSize: 15, fontWeight: "bold", color: "red" }}>৳ {item?.billAmount}</span>
                                                    <div>
                                                        <Tooltip title="Download Report">
                                                            <span style={{ border: "1px solid #ccc", padding: 5, borderRadius: 5, width: 40, textAlign: "center", background: "#0F6330", color: "white", cursor: "pointer" }}
                                                                onClick={() => {
                                                                    let postData = {
                                                                        billId: item.billId
                                                                    }
                                                                    downloadVoucher(postData)
                                                                }}
                                                            >
                                                                <CloudDownloadOutlined /></span> </Tooltip>
                                                        
                                                            <span
                                                                style={{ border: "1px solid #ccc", padding: 5, borderRadius: 5, width: 100, textAlign: "center", background: "#0F6330", color: "white", cursor: "pointer" }}
                                                                onClick={() => fetchgetGatewayPageUrl(item?.billId)}
                                                            >
                                                                <ArrowRightOutlined /> Pay Bill</span>
                                                    </div>

                                                </div>


                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tab="Paid" key="2">
                                        {ispClientSingleInfo?.billingInfos?.filter((item: any) => item.status === 1)?.length === 0 &&
                                            <div key={1} style={{ marginTop: 20, textAlign: 'center', fontSize: "20", fontWeight: "bold", color: "#1BD559" }}>
                                                No paid bill found
                                            </div>
                                        }
                                        {ispClientSingleInfo?.billingInfos?.filter((item: any) => item.status === 1).map((item: any, index: any) => (
                                            <div key={index} style={{
                                                padding: 12,
                                                marginBottom: 20,
                                                boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px"
                                            }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                                    <span style={{ fontSize: 15, fontWeight: "bold" }}>Bill Amount</span>
                                                    <span style={{ fontSize: 15, fontWeight: "bold" }}>Payable Date: {moment(item?.payableDate).format('DD MMM y')}</span>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                    <span style={{ fontSize: 15, fontWeight: "bold", color: "red" }}>৳ {item?.billAmount}</span>
                                                    <Tooltip title="Download Report">
                                                        <span style={{ border: "1px solid #ccc", padding: 5, borderRadius: 5, width: 40, textAlign: "center", background: "#0F6330", color: "white", cursor: "pointer" }}
                                                            onClick={() => {
                                                                let postData = {
                                                                    billId: item.billId
                                                                }
                                                                downloadVoucher(postData)
                                                            }}
                                                        >
                                                            <CloudDownloadOutlined /></span> </Tooltip>
                                                </div>


                                            </div>
                                        ))}
                                    </TabPane>
                                </Tabs>
                            </>
                            }

                        </div>
                    </div>

                </div>
            </Layout>
        </>
    )
}
