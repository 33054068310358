import { DeleteOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import moment from "moment";
const { TextArea } = Input;
export default function OrderListSuperAdmin(props) {

    const disbursementPayableList = useStoreState(state => state.bill.disbursementPayableList);

    const fetchdisbursementPayableList = useStoreActions(state => state.bill.fetchdisbursementPayableList);
    const saveDisbursement = useStoreActions(state => state.bill.saveDisbursement);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [modalVal, setmodalVal] = useState<any>()

    const [billDisbursForm] = Form.useForm();

    const showModal = (val) => {
        console.log(val)
        billDisbursForm.setFieldsValue({
            partnerId: val?.partnetId,
            partnerName: val?.partnerName,
            billId: val?.distributionIds,
            payableAmount: val?.payableAmount,
        });
        setIsModalVisible(true);

    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    useEffect(() => {
        fetchdisbursementPayableList();
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Bill Disbursment'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }


    const disbursFormSubmit = (value) => {
        let postData= {
            "billPaymentDistributionIds": modalVal?.distributionIdList,
            "disbursementDate": moment(value?.date).format("YYYY-MM-DD"),
            "note": value?.note==undefined?"":value?.note,
            "partnerId": value?.partnerId,
            "totalDisbursementAmount": value?.payableAmount,
            "transactionId": value?.trnxId
          }
          console.log(postData)
          saveDisbursement(postData);
          
          setTimeout(() => {
            fetchdisbursementPayableList();
          }, 300);

          setTimeout(() => {
            setIsModalVisible(false);
          }, 1000);
          
    }

    const columns = [
        {
            title: 'Partner Id',
            dataIndex: 'partnetId',
            key: 'partnetId',
        },
        {
            title: 'Partner Name',
            dataIndex: 'partnerName',
            key: 'partnerName',
        },
        {
            title: 'Bill Ids',
            dataIndex: 'distributionIds',
            key: 'distributionIds',
        },
        {
            title: 'Disbursment Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
        },

        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Pre-Approve Order">
                        <Button type="primary" icon={<ShopOutlined />} onClick={() => { showModal(record); setmodalVal(record) }}>Pay</Button>
                    </Tooltip>
                </Space>
            ),
        },

    ]



    return (
        <>
            <Card title="Bill Disbursment" >
                <Table bordered={true} dataSource={disbursementPayableList} columns={columns} />
            </Card>

            <Modal
                title="" visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={disbursFormSubmit}
                    id="basic-info"
                    form={billDisbursForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                name="partnerId"
                                label="Partner Id"
                                className="title-Text"

                            >
                                <Input style={{ width: '100%' }} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={1} xl={1}></Col>

                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                name="partnerName"
                                label="Partner Name:"
                                className="title-Text"
                            >
                                <Input style={{ width: '100%' }} disabled />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                name="billId"
                                label="Bill Id:"
                                className="title-Text"
                            >
                                <Input style={{ width: '100%' }} disabled />


                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={1} xl={1}></Col>

                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                name="payableAmount"
                                label="Payable Amount:"
                                className="title-Text"

                            >
                                <Input style={{ width: '100%' }} disabled />
                            </Form.Item>

                        </Col>

                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                name="date"
                                label="Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date"/>

                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={1} xl={1}></Col>

                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                name="trnxId"
                                label="Trnx. Id:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter id" },
                                ]}
                            >
                                <Input style={{ width: '100%' }} placeholder="Enter Transaction Id" />
                            </Form.Item>

                        </Col>

                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                name="note"
                                label="Note:"
                                className="title-Text"

                            >
                                <TextArea style={{ width: '100%' }} placeholder="Enter Note" />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Space size="small" style={{ float: "right" }}>
                        <Button type="primary" htmlType="submit" >
                            Submit
                                </Button>
                    </Space>
                    <br />
                </Form>
            </Modal>
        </>
    )
}
