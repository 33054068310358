import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, SaveOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import moment from 'moment';

const { Option } = Select;


export default function IspOrderListDateWiseSDL(props) {


    const ispClientListDateWise = useStoreState(state => state.isp.ispClientListDateWise);
    const fetchIspClientListDateWise = useStoreActions((state) => state.isp.fetchIspClientListDateWise);

    const [payload, setpayload] = useState<any>()
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        let postData:any = {
            fromDate: moment(values?.fromDate).format("YYYY-MM-DD"),
            orderStatus:values.orderStatus,
            toDate: moment(values?.toDate).format("YYYY-MM-DD"),
        }
        setpayload(postData)
        fetchIspClientListDateWise(postData)
    };


    useEffect(() => {
        setPageTitle();
    }, []);


    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }


    const columns = [

        {
            title: 'Client ID',
            dataIndex: 'aggreementEndDate',
            key: 'aggreementEndDate',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Address',
            dataIndex: 'area',
            key: 'area',
        },
        {
            title: 'Create Date',
            dataIndex: 'numberOfStudent',
            key: 'numberOfStudent',
        },
        {
            title: 'Bill Cycle',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Package',
            dataIndex: 'partnerName',
            key: 'partnerName',
        },
        {
            title: 'Bill Payable Person',
            dataIndex: 'contactNo',
            key: 'contactNo',
        },
        {
            title: 'Rate',
            dataIndex: 'contactNo',
            key: 'contactNo',
        },
        {
            title: 'Rate Type',
            dataIndex: 'contactNo',
            key: 'contactNo',
        },
        {
            title: 'Bill Pay Step',
            dataIndex: 'contactNo',
            key: 'contactNo',
        }
    ]





    return (
        <Card title="Date Wise Order List">
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15}>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}></Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="fromDate"
                            label="From Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select From Date" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="toDate"
                            label="To Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select To Date" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="orderStatus"
                            label="Order Status:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select order status" },
                            ]}
                        >
                            <Select placeholder="Select Order Status" style={{ width: "100%" }} allowClear>
                                <Option value="0">Pending</Option>
                                <Option value="1">Approved</Option>
                                <Option value="2">Pre-Approved</Option>
                            </Select>


                        </Form.Item>

                    </Col>

                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>

                </Row>
            </Form>
            {ispClientListDateWise?.length > 0 &&
                < >


                    <Table
                        rowKey="partnerName"
                        bordered={true}
                        dataSource={ispClientListDateWise}
                        columns={columns}
                    
                    />
                    {/* <Button type="primary" icon={<SaveOutlined />} style={{ float: 'right' }}> Download Report </Button> */}
                </>

            }

        </Card>
    )
}
