import { Button, Card, Col, DatePicker, Form, Row, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';


export default function ClientVendorWiseTransactionSummary(props) {

    const clientTansactionVendorSummary = useStoreState((state) => state.common.clientTansactionVendorSummary);
    const fetchclientTansactionVendorSummary = useStoreActions((state) => state.common.fetchclientTansactionVendorSummary);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }

        fetchclientTansactionVendorSummary(postData);
    };


    const columns = [

        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Venodr',
            dataIndex: 'venodr',
            key: 'venodr',
        },
        {
            title: 'Transaction Amount',
            dataIndex: 'trnAmount',
            key: 'trnAmount',
        },
    ]



    return (
        <>
            <Card title="Client and Vendor Wise Transaction Summary" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Button type="primary" htmlType="submit" style={{ height: 40, marginTop: 30 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {clientTansactionVendorSummary !== null &&
                    <>

                        <Table
                            columns={columns}
                            dataSource={clientTansactionVendorSummary}
                            bordered={true}
                            summary={pageData => {
                                let total = 0;
                                clientTansactionVendorSummary.forEach(({ trnAmount }) => {
                                    total += trnAmount;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} > <div style={{ textAlign: 'right' }}>Total</div></Table.Summary.Cell>
                                            <Table.Summary.Cell index={1}>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2}>
                                                <Text type="danger" strong>{total}</Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>

                                    </>
                                );
                            }}
                        />

                    </>
                }
            </Card>
        </>
    )
}
