import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, SaveOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import moment from 'moment';

const { Option } = Select;


export default function IspBillListDailySDL(props) {


    const ispClientBillInfoForSdlListDate = useStoreState(state => state.isp.ispClientBillInfoForSdlListDate);
    const fetchispClientBillInfoForSdlListDate = useStoreActions(state => state.isp.fetchispClientBillInfoForSdlListDate);
    const downloadIspBillDisbursementDateToDate = useStoreActions(state => state.isp.downloadIspBillDisbursementDateToDate);

    const [payload, setpayload] = useState<any>()
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        values.fromDate = moment(values?.fromDate).format("YYYY-MM-DD");
        values.toDate = moment(values?.toDate).format("YYYY-MM-DD");
        setpayload(values)
        fetchispClientBillInfoForSdlListDate(values)
    };


    useEffect(() => {
        setPageTitle();
    }, []);


    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }


    const columns1 = [

        {
            title: 'Pop Manager Name',
            dataIndex: 'partnerName',
            key: 'partnerName',
            render: (text: any, record: any, index) => (
                <strong><i>{record.partnerName}</i></strong>
            ),
        },
        {
            title: 'Area',
            dataIndex: 'areaName',
            key: 'areaName',
            render: (text: any, record: any, index) => (
                <strong><i>{record.areaName}</i></strong>
            ),
        },
        {
            title: 'Total Bill',
            dataIndex: 'pipWiseTotalBillAmount',
            key: 'pipWiseTotalBillAmount',
        },
    ]

    const subcolumns = [
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: 'Price',
            dataIndex: 'packagePrice',
            key: 'packagePrice',
        },
        {
            title: 'Total Client',
            dataIndex: 'totalClient',
            key: 'totalClient',
        },        
        {
            title: 'Paid Client',
            dataIndex: 'totalPaidClient',
            key: 'totalPaidClient',
        },        
        {
            title: 'Unpaid Client',
            dataIndex: 'totalUnpaidClient',
            key: 'totalUnpaidClient',
        },        
        {
            title: 'Disable Client',
            dataIndex: 'totalDisableClient',
            key: 'totalDisableClient',
        },
        {
            title: 'Total Bill',
            dataIndex: 'totalBillAmount',
            key: 'totalBillAmount',
        },
        {
            title: 'Paid Bill',
            dataIndex: 'totalPaidBillAmount',
            key: 'totalPaidBillAmount',
        },        
        {
            title: 'Unpaid Bill',
            dataIndex: 'totalUnpaidBillAmount',
            key: 'totalUnpaidBillAmount',
        },
        {
            title: 'Disable Bill',
            dataIndex: 'totalDisableBillAmount',
            key: 'totalDisableBillAmount',
        },

        {
            title: 'SDL(60%)',
            dataIndex: 'sdlAmount',
            key: 'sdlAmount',
        },
        {
            title: 'PIP(40%)',
            dataIndex: 'pipAmount',
            key: 'pipAmount',
        },
        {
            title: 'PIP Paid Amount ',
            render: (value, row, index) => {
                const obj: any = {
                    children: 44,
                    props: {},
                };

                obj.props.rowSpan = 0;

                return obj;
            },
        },
        {
            title: 'PIP Due Amount ',
            render: (value, row, index) => {
                const obj: any = {
                    children: 44,
                    props: {},
                };

                obj.props.rowSpan = 0;

                return obj;
            },
        },



    ]



    return (
        <Card title="Date Wise Bill List">
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} >

                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>



                    </Col>

                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                    <Form.Item
                            name="fromDate"
                            label="From Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select From Date" />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                    <Form.Item
                            name="toDate"
                            label="To Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select To Date" />
                        </Form.Item>

                    </Col>

                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>


                    </Col>
                </Row>
            </Form>
            {ispClientBillInfoForSdlListDate?.length > 0 &&
                < >


                    <Table
                        rowKey="partnerName"
                        bordered={true}
                        pagination={false}
                        dataSource={ispClientBillInfoForSdlListDate}
                        columns={columns1}
                        expandable={{
                            expandedRowRender: (record: any) => (
                                <div style={{ overflow: 'auto' }} >
                                    <Table
                                        rowKey="packageName"
                                        bordered={true}
                                        pagination={false}
                                        dataSource={record.packageWiseBillInfos}
                                        columns={subcolumns}
                                        style={{ overflow: 'auto' }}
                                        summary={pageData => {
                                            return (
                                                <>
                                                    <Table.Summary.Row>
                                                        <Table.Summary.Cell index={0}> <Text type="danger" strong>Total</Text></Table.Summary.Cell>
                                                        <Table.Summary.Cell index={1}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={2}>
                                                            <Text type="danger" strong>{record.pipWiseTotalClient}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={3}>
                                                            <Text type="danger" strong>{record.pipWiseTotalPaidClient}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={4}>
                                                            <Text type="danger" strong>{record.pipWiseTotalUnpaidClient}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={5}>
                                                            <Text type="danger" strong>{record.pipWiseTotalDisableClient}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={6}>
                                                            <Text type="danger" strong>{record.pipWiseTotalBillAmount}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={7}>
                                                            <Text type="danger" strong>{record.pipWiseTotalPaidBillAmount}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={8}>
                                                            <Text type="danger" strong>{record.pipWiseTotalUnpaidBillAmount}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={9}>
                                                            <Text type="danger" strong>{record.pipWiseTotalDisableBillAmount}</Text>
                                                        </Table.Summary.Cell>
    
                                                        <Table.Summary.Cell index={10}>
                                                            <Text type="danger" strong>{record.pipWiseTotalSdlBillAmount}</Text>
                                                        </Table.Summary.Cell>                
                                                         <Table.Summary.Cell index={11}>
                                                            <Text type="danger" strong>{record.pipWiseTotalPipBillAmount}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={12}>
                                                            <div style={{ marginTop: "-50%" }}>
                                                                <Text type="danger" strong>{record.pipPaidAmount}</Text>
                                                            </div>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={13}>
                                                            <div style={{ marginTop: "-50%" }}>
                                                                <Text type="danger" strong>{record.pipDueAmount}</Text>
                                                            </div>
                                                        </Table.Summary.Cell>
                                                    </Table.Summary.Row>

                                                </>
                                            );
                                        }}
                                    />
                                </div>
                            )

                        }}

                    />
                    <br />
                    <Button type="primary" icon={<SaveOutlined />} onClick={() => downloadIspBillDisbursementDateToDate(payload)} style={{ float: 'right' }}> Download Report </Button>
                </>

            }

        </Card>
    )
}
