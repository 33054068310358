import { DeleteOutlined, FileExcelOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectProduct } from '../../select/SelectProduct'
import GetColumnSearchProps from '../../utils/getColumnSearchProps';
import ReactExport from "react-export-excel";
const { Option } = Select;
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;
export default function OrderStatusPendingListAPIPReport(props) {

    const pendingApproveProductOrderList = useStoreState(state => state.order.pendingApproveProductOrderList);
    const fetchpendingApproveProductOrderList = useStoreActions(state => state.order.fetchpendingApproveProductOrderList);
    const [searchv, setsearchv] = useState<any>('');
    const [filterTable, setfilterTable] = useState<any>(null);
    useEffect(() => {
        setPageTitle();
        fetchpendingApproveProductOrderList(0);
    }, [])

    const search = value => {

        const result = pendingApproveProductOrderList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Pending Order'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
            key: 'contactNo',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
        },
        {
            title: 'Number Of Student',
            dataIndex: 'numberOfStudent',
            key: 'numberOfStudent',
        },
        {
            title: 'BillCycle',
            dataIndex: 'billCycle',
            key: 'billCycle',
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Rate Type',
            dataIndex: 'rateType',
            key: 'rateType',
        }
    ];



    return (
        <>
            <Card title="Pending Order Status" >
                {pendingApproveProductOrderList.length > 0 &&
                    
                    <>
                        <span>Total Found: {pendingApproveProductOrderList !== null ? pendingApproveProductOrderList?.length : 0}</span>
                        <Input
                            style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                            placeholder="Search..."
                            value={searchv}
                            allowClear
                            onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                        />
                        <Table
                            bordered={true}
                            dataSource={searchv === '' ? pendingApproveProductOrderList : filterTable}
                            columns={columns}
                            rowKey={(record) => record.clientId}
                            locale={{ emptyText: 'Select product to see client list' }}
                        />
                        <Row>
                            <Col span={24}>
                                <Space size="small" style={{ float: "right" }}>
                                <ExcelFile
                                        element={<Button type="primary" icon={<FileExcelOutlined />} >
                                            Download Excel
                                        </Button>}
                                        filename={`Pending Order Status Report`}
                                    >
                                        <ExcelSheet data={pendingApproveProductOrderList} name="Pending Order Status">
                                            <ExcelColumn label="Client Name" value="clientName" />
                                            <ExcelColumn label="Package Name" value="packageName" />
                                            <ExcelColumn label="Product Name" value="productName" />
                                            <ExcelColumn label="Email" value="email" />
                                            <ExcelColumn label="Contact No" value="contactNo" />
                                            <ExcelColumn label="Address" value="address" />
                                            <ExcelColumn label="Area" value="area" />
                                            <ExcelColumn label="Number Of Student" value="numberOfStudent" />
                                            <ExcelColumn label="Bill Cycle" value="billCycle" />
                                            <ExcelColumn label="Rate" value="rate" />
                                            <ExcelColumn label="Rate Type" value="rateType" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </Space>
                            </Col>
                        </Row>
                    </>
                    
                }
            </Card>
        </>
    )
}
