import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd'
import React, { useState, useEffect } from 'react'
import { SelectProduct } from '../../select/SelectProduct'
import moment from "moment";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectBillCycle } from '../../select/SelectBillCycle';
import { SelectProductForBillGenerate } from '../../select/SelectProductForBillGenerate';

const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function BillGenerateMonthlySuperAdmin(props) {

    const saveProductBillGenerateMonthly = useStoreActions(state => state.bill.saveProductBillGenerateMonthly)
    const [billgenerateForm] = Form.useForm();

    const orderFormSubmit = (value) => {
        //  console.log(value)
        let postMonthly = {
            productId: value?.product_id?.productId,
            month: value?.month,
            year: value?.year,
        }
        saveProductBillGenerateMonthly(postMonthly);
        // billgenerateForm.resetFields();

    };

    useEffect(() => {
        setPageTitle();
    }, []);

    const [productId, setProductId] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>(null);

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Bill Generate Monthly'
        return pageTitleEle ?  pageTitleEle.innerHTML = pageTitle : ''      
    }

    const onchangeproductId = (val) => {
        setProductId(val);
    }
    const onchangebillCycle = (val) => {
        //   console.log(val)
        setBillCycle(val)
    }

    let year = new Date().getFullYear();

    return (
        <>
            <Form
                layout="horizontal"
                {...layout}
                onFinish={orderFormSubmit}
                id="basic-info"
                form={billgenerateForm}
            >
                <div className="__orderForm p-0 custom-form">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="product_id"
                                label="Product:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your product" },
                                ]}
                            >
                                <SelectProductForBillGenerate onChange={(val) => onchangeproductId(val)} selected={productId}

                                />
                            </Form.Item>
                        </Col>
                        {/* 
                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>    
                            <Form.Item
                            name="bill_cycle"
                            label="Bill Cycle:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select bill cycle" },
                            ]}
                        >
                            <SelectBillCycle onChange={(val) => onchangebillCycle(val)} selected={billCycle}

                            />
                        </Form.Item> 
                        </Col>
                        */}
                    </Row> 
                    <Row>   
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="year"
                                label="Year:"
                                className="title-Text"
                                initialValue={year}
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >
                                <Select placeholder="Select Year" allowClear>
                                    <Option value={year - 1}>{year - 1}</Option>
                                    <Option value={year}>{year}</Option>
                                    <Option value={year + 1}>{year + 1}</Option>
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="month"
                                label="Month:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select month" },
                                ]}
                            >
                                <Select placeholder="Select Month" allowClear>
                                    <Option value="January">January</Option>
                                    <Option value="February">February</Option>
                                    <Option value="March">March</Option>
                                    <Option value="April">April</Option>
                                    <Option value="May">May</Option>
                                    <Option value="June">June</Option>
                                    <Option value="July">July</Option>
                                    <Option value="August">August</Option>
                                    <Option value="September">September</Option>
                                    <Option value="October">October</Option>
                                    <Option value="November">November</Option>
                                    <Option value="December">December</Option>
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={8} xl={168}>
                            <Space size="small" className="pull-right">
                                <Button type="primary" htmlType="submit" >
                                    Generate
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}
