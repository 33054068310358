import { Card, Col, Divider, Row } from 'antd'
import React, { useEffect } from 'react'
import OrganizationChart from "@dabeng/react-orgchart";
import "./chart.css";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';

export default function Dashboard(props) {

    const fetchpartnerProfile = useStoreActions(state => state.common.fetchpartnerProfile);
    const partnerProfile = useStoreState(state => state.common.partnerProfile);

    useEffect(() => {
        fetchpartnerProfile();
        setPageTitle();
    }, []);

  //  console.log(partnerProfile);

    function childrenfunc(item) {
        return
    }

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Dashboard'
        return pageTitleEle ?  pageTitleEle.innerHTML = pageTitle : ''      
    }

    // const ds = {
    //     id: 1,
    //     name: partnerProfile?.partnerName,
    //     title: partnerProfile?.designation,
    //     children: partnerProfile?.tree.map(function (item, index) {
    //         return {
    //             id: index,
    //             name: item,
    //             title: partnerProfile?.designation === "SDL" ? "PIP" : partnerProfile?.designation === "PIP" ? "APIP" : ""
    //         }
    //     })
    // };


    return (
        <>
            <br />
            <Card title="" >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} className="__profile">
                        <div className="card-block text-center text-white">
                            <div className="m-b-25"> <img src={(partnerProfile?.partnerImage!==null || partnerProfile?.partnerImage!=='') ?partnerProfile?.partnerImage:'https://img.icons8.com/bubbles/100/000000/user.png' } className="img-radius" alt="User-Profile-Image" style={{height:100, width:100, borderRadius:20}} /> </div>
                            <span className="f-w-600">Name : {partnerProfile?.partnerName}</span>
                            <p className="f-w-600">Id: {partnerProfile?.partnerCustomId}</p>
                        </div>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16} >
                        <div className="card-block">
                            <span className="f-w-601">Information</span>
                            <Divider style={{ marginTop: 10 }}></Divider>
                            <p className="f-w-601">Designation: {partnerProfile?.designation}</p>
                            <p className="f-w-601">Area: {partnerProfile?.area}</p>
                        </div>
                    </Col>
                </Row>
            </Card>
            {/* <OrganizationChart
                datasource={ds}
                chartClass="myChart"
            // NodeTemplate={MyNode}
            /> */}
        </>
    )
}
