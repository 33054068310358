import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DeleteOutlined, EditOutlined, FileExcelOutlined, PayCircleOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import { v4 as uuidv4 } from "uuid";
import { Excel } from 'antd-table-saveas-excel';

export default function SpgBillUniversity() {

    const updateOfpsConfigurationAmount2 = useStoreActions<any>((state) => state.common.updateOfpsConfigurationAmount2);
    const fetchuniveristyOfpsConfigurationList = useStoreActions<any>((state) => state.common.fetchuniveristyOfpsConfigurationList);
    const univeristyOfpsConfigurationList = useStoreState((state) => state.common.univeristyOfpsConfigurationList);
    const loading = useStoreState((state) => state.common.loading);
    const [configId, setConfigId] = useState<any>(null);

    let columns = [
        { title: 'Institute Id', dataIndex: 'instituteId', key: 'instituteId', showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: 'instituteName', showOnResponse: true, showOnDesktop: true },
        { title: 'Address', dataIndex: 'address', key: 'address', showOnResponse: true, showOnDesktop: true },
        { title: 'Account No', dataIndex: 'accountNo', key: 'accountNo', showOnResponse: true, showOnDesktop: true },
        { title: 'Service Charge', dataIndex: 'serviceCharge', key: 'serviceCharge', showOnResponse: true, showOnDesktop: true },
        { title: 'Step', dataIndex: 'step', key: 'step', showOnResponse: true, showOnDesktop: true },
        { title: 'Package Name', dataIndex: 'packageName', packageName: 'step', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'status', key: uuidv4(), showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
                <>
                    <Button type='primary' icon={<EditOutlined />} onClick={() => {
                        setConfigId(record.configId);
                        showModal(record);
                    }} >
                        Update </Button>
                </>
            )
        }
    ];

    let columns2 = [
        { title: 'Institute Id', dataIndex: 'instituteId', key: 'instituteId', showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: 'instituteName', showOnResponse: true, showOnDesktop: true },
        { title: 'Address', dataIndex: 'address', key: 'address', showOnResponse: true, showOnDesktop: true },
        { title: 'Account No', dataIndex: 'accountNo', key: 'accountNo', showOnResponse: true, showOnDesktop: true },
        { title: 'Service Charge', dataIndex: 'serviceCharge', key: 'serviceCharge', showOnResponse: true, showOnDesktop: true },
        { title: 'Step', dataIndex: 'step', key: 'step', showOnResponse: true, showOnDesktop: true },
        { title: 'Package Name', dataIndex: 'packageName', packageName: 'step', showOnResponse: true, showOnDesktop: true },
    ];

    useEffect(() => {
        fetchuniveristyOfpsConfigurationList();
    }, [])



    const [isModalVisible, setIsModalVisible] = useState(false);

    const [formUpdate] = Form.useForm();

    const showModal = (val) => {
        formUpdate.setFieldsValue({
            serviceCharge: val?.serviceCharge,
            step: val?.step,
        });
        setIsModalVisible(true);

    };

    const handleOk = () => {
        // setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onFinishUpdate = (values: any) => {
        values.configId = configId;
        updateOfpsConfigurationAmount2(values);
        setIsModalVisible(false);
        setConfigId(null);
    };

    return (
        <>

            <Card title="University OFPS Configuration List">
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                        {!loading &&
                            <div className="datatable-responsive-demo">

                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: univeristyOfpsConfigurationList,
                                        filterData: univeristyOfpsConfigurationList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                                <Space style={{ float: 'right' }} size="middle">
                                    < Button
                                        type='primary'

                                        icon={<FileExcelOutlined />}
                                        onClick={() => {
                                            const excel: any = new Excel();
                                            excel
                                                .addSheet(`Sheet 1`)
                                                .addColumns(columns2)
                                                .addDataSource(univeristyOfpsConfigurationList)
                                                .saveAs(`SPG University Bill List.xlsx`);
                                        }}
                                    >
                                        Download Excel
                                    </ Button >

                                </Space>
                            </div>
                        }
                    </Col>
                </Row>
                <Modal
                    title="Update"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={"40%"}
                    okText="Update"
                    centered
                    destroyOnClose={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    <Form layout="vertical" onFinish={onFinishUpdate} id='update-class' form={formUpdate} >
                        <Row gutter={15} >

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                <Form.Item
                                    name="serviceCharge"
                                    label="Service Charge:"
                                    className="title-Text"

                                    rules={[
                                        { required: true, message: "Please input service charge", },
                                    ]}
                                >
                                    <Input type="text" placeholder="Service Charge"></Input>
                                </Form.Item>

                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                <Form.Item
                                    name="step"
                                    label="Step:"
                                    className="title-Text"

                                    rules={[
                                        { required: true, message: "Please input step", },
                                    ]}
                                >
                                    <Input type="text" placeholder="Step"></Input>
                                </Form.Item>

                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                <Space size="small" style={{ marginTop: 30, float: "right" }} >
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                                </Space>

                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Card>
        </>
    )
}