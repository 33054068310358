import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectClientPublic {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  level?: any;
  style?: any
}

export const SelectClientPublic = ({
  onChange,
  selected,
  defaultSelected,
  level,
  style
}: SelectClientPublic) => {

  const itemList = useStoreState((state) => state.common.publicclientList);
  const itemListFetch = useStoreActions((state) => state.common.fetchpublicclientList);

  if (itemList === null) {
    itemListFetch(level);
  }

  const onSelect = (id) => {
    if (itemList) {
      const val = itemList.find((item) => item.profileId === id);
      onChange(val?.profileId);
    }
  };


  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder={`Select Client`}
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.profileId} value={type.profileId}>
            {type.companyName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Client</Option>
      )}
    </Select>
  );
};
