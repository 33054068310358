import { Button, Col, DatePicker, Form, Input, Result, Row, Space, Card, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectPartner } from '../../select/SelectIPartner'
import { SelectYear } from '../../select/SelectYear'
import { SelectMonth } from '../../select/SelectMonth'
import { SelectBank } from '../../select/SelectBank'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import moment from 'moment'
import { SelectIPartnerForIsp } from '../../select/SelectIPartnerForIsp'


export default function IspBillDisbursmentSDL(props) {

    const [billDisburbForm] = Form.useForm();
    const fetchpartnerListForIsp = useStoreActions((state) => state.partner.fetchpartnerListForIsp);
    useEffect(() => {

        setTimeout(function () {
            setPageTitle();
        })
        fetchpartnerListForIsp("ISP");
    }, [])

    const ispPartnerPaymentInfoSave = useStoreActions(state => state.isp.ispPartnerPaymentInfoSave);

    const onBlillDisbursSubmit = (value) => {
        value.paymentDate = moment(value?.paymentDate).format("YYYY-MM-DD");
        value.paymentMethod=value.paymentMethod==undefined?'':value.paymentMethod;
        value.transactionId=value.transactionId==undefined?'':value.transactionId;
        value.paidAmount= parseFloat(value.paidAmount);
        value.bankName=value.bankName.BankName;
        value.partnerId=value.partnerId.value;
        // console.log(value)
        ispPartnerPaymentInfoSave(value);
        billDisburbForm.resetFields();

    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };




    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'BILL DISBURS'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [partnerId, setPartnerId] = useState<any>(null);

    const onchnagePartner = (val) => {
        setPartnerId(val)
    }

    return (
        <>
            <Card title="" >
                <Row gutter={8}>
                    <Form
                        layout="horizontal"
                        {...layout}
                        onFinish={onBlillDisbursSubmit}
                        id="basic-info"
                        form={billDisburbForm}
                    >
                        <div className="__orderForm p-0 custom-form">
                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="bankName"
                                        label="Bank:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select bank" },
                                        ]}
                                    >
                                        <SelectBank />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="paidAmount"
                                        label="Paid Amount:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter paid amount" },
                                        ]}
                                    >

                                        <Input style={{ width: '100%' }} placeholder="Paid Amount" />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="partnerId"
                                        label="Partner:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select partner" },
                                        ]}
                                    >
                                        <SelectIPartnerForIsp name="PIP" level={2} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="paymentDate"
                                        label="Payment Date:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter date" },
                                        ]}
                                    >

                                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="paymentMethod"
                                        label="Payment Method:"
                                        className="title-Text"

                                    >
                                        <Input style={{ width: '100%' }} placeholder="Payment Method" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="transactionId"
                                        label="Transaction Id:"
                                        className="title-Text"
                                    >
                                        <Input style={{ width: '100%' }} placeholder="Transaction Id" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>

                                <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Space size="small" style={{ float: "right" }}>
                                        <Button type="primary" htmlType="submit" >
                                            Save
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Row>
            </Card>

        </>
    )
}
