import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, FileExcelOutlined, SaveOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectBillCycleUpperCase } from '../../select/SelectBillCycleUpperCase';
import { SelectPackageCustom } from '../../select/SelectPackageCustom';
import TableView from '../../../contents/AntTableResponsive';
import ReactExport from "react-export-excel";
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

const { Option } = Select;


export default function ClientInfoUpdateSDL(props) {

    const [updateClientInfoForm] = Form.useForm();
    const ispClientProductWiseList = useStoreState(state => state.isp.ispClientProductWiseList);
    const fetchIspClientProductWiseList = useStoreActions((state) => state.isp.fetchIspClientProductWiseList);
    const fetchpackageListByProductId = useStoreActions((state) => state.order.fetchpackageListByProductId);
    const orderClientIfoUpdate = useStoreActions((state) => state.isp.orderClientIfoUpdate)
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [billCycle, setBillCycle] = useState<any>("");
    const [clientStatus, setClientStatus] = useState<any>("");
    const [selectedPackage, setSelectedPackage] = useState<any>("");
    const [selectedBillType, setSelectedBillType] = useState<any>("");
    const [selectedClientId, setSelectedClientId] = useState<any>("");
    const [selectedClientProvidedId, setSelectedClientProvidedId] = useState<any>("");
    const [selectedPartnerId, setSelectedPartnerId] = useState<any>("");
    const [payload, setpayload] = useState<any>();
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [clientProductWiseList, setClientProductWiseList] = useState<any>();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        fetchIspClientProductWiseList(values?.selectProduct);
        setSelectedProduct(values?.selectProduct);
    };

    const billCycleChange = (e) => {
        setBillCycle(e.value)
    }

    useEffect(() => {
        setClientProductWiseList(ispClientProductWiseList);
    }, [ispClientProductWiseList])
    
    const columns = [

        {
            title: 'Client ID',
            dataIndex: 'clientProvideId',
            key: 'clientProvideId',
            showOnResponse: true, 
            showOnDesktop: true,
            width:100
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:250
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            showOnResponse: true, 
            showOnDesktop: true,
            width:200
        },
        {
            title: 'Create Date',
            dataIndex: 'createDate',
            key: 'createDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:130
        },
        {
            title: 'Minimum Student',
            dataIndex: 'minimumStudent',
            key: 'minimumStudent',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Bill Cycle',
            dataIndex: 'billCycle',
            key: 'billCycle',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Package',
            dataIndex: 'packageName',
            key: 'packageName',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Bill Payable Person',
            dataIndex: 'billPayablePerson',
            key: 'billPayablePerson',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Bill Type',
            dataIndex: 'billType',
            key: 'billType',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Action',
            showOnResponse: true, 
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <Tooltip title="Update">
                        <Button type="primary" icon={<EditOutlined />} onClick={() => {
                            setIsViewModalVisible(true);
                            setBillCycle(record.billCycle);
                            setSelectedPackage(record?.packageId);
                            fetchpackageListByProductId(record?.productId);
                            setSelectedBillType(record?.billType);
                            setSelectedClientId(record.clientId);
                            setSelectedClientProvidedId(record.clientProvideId);
                            setSelectedPartnerId(record.partnerId);
                            updateClientInfoForm.setFieldsValue({
                                billCycle : record?.billCycle,
                                minStudent: record?.minimumStudent,
                                billPayablePerson: record?.billPayablePerson,
                                billType: record?.billType,
                                billUnit: record?.rate ,
                                packageId: record?.packageId ,
                                clientStatus: record.clientStatus == 0 ? "Pending" : '' || record.clientStatus == 1 ? "Approved" : ''|| record.clientStatus == 2 ? "Pre-Approved" : ''
                            })
                        }} ></Button>
                    </Tooltip>
                </>
            )
        }
    ]


    const clientInfoUpdateFormSubmit = (value) => {

        let postdata:any = {
            "billCycle": billCycle,
            "billPayablePerson": value?.billPayablePerson,
            "billType": value?.billType,
            "billUnit": value?.billUnit,
            "clientId": selectedClientId,
            "clientProvidedId" : selectedClientProvidedId,
            "packageId": selectedPackage,
            "partnerId": selectedPartnerId,
            "minimumStudent": value?.minStudent,
            "productId":form.getFieldValue('selectProduct'),
            
        }
        orderClientIfoUpdate(postdata);
        // fetchIspClientProductWiseList(selectedProduct);
        form.submit();
        setIsViewModalVisible(false);
    }
    const onChnageRateType = (value) => {

    }

    const onChnagePackageId = (value) => {
        setSelectedPackage(value.packageId);
    }

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search =value => {

        const result = clientProductWiseList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    return (
        <>
            <Card title="Client Info Update">
                <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                    <Row gutter={15} >
                        <Col xs={24} sm={24} md={24} lg={7} xl={7}></Col>

                        <Col xs={24} sm={24} md={24} lg={7} xl={7}>

                            <Form.Item
                                name="selectProduct"
                                label="Select Product:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your product" },
                                ]}
                            >
                                <Select placeholder="Select Product" style={{ width: "100%" }} allowClear>
                                    <Option value="101">School/College</Option>
                                    <Option value="102">University</Option>
                                </Select>

                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                            <Space size="small" style={{ marginTop: 30 }} >
                                <Button type="primary" htmlType="submit" >
                                    Search
                                </Button>
                            </Space>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    </Row>
                </Form>
                {clientProductWiseList?.length > 0 &&
                    < >

                        {/* <span>Total Found: {clientProductWiseList !== null ? clientProductWiseList?.length : 0}</span>
                        <Input
                            style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                            placeholder="Search..."
                            value={searchv}
                            allowClear
                            onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                        />
                        <Table
                            rowKey="partnerName"
                            bordered={true}
                            columns={columns}
                            dataSource={searchv === '' ? clientProductWiseList : filterTable}
                        
                        /> */}
                        {/* <Button type="primary" icon={<SaveOutlined />} style={{ float: 'right' }}> Download Report </Button> */}
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:columns,
                                rowKey:"clientId",
                                dataSource: clientProductWiseList,
                                filterData: clientProductWiseList,
                                pagination: true,
                                bordered: true,                      
                            }}
                            mobileBreakPoint={768}
                        />
                        <Row>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                            <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Client Info Report`}
                            >
                                <ExcelSheet data={clientProductWiseList} name="Client Info Report">
                                    <ExcelColumn label="Client ID" value="clientProvideId" />
                                    <ExcelColumn label="Client Name" value="clientName" />
                                    <ExcelColumn label="Address" value="address" />
                                    <ExcelColumn label="Create Date" value="agreementDate" />
                                    <ExcelColumn label="Minimum Student" value="minimumStudent" /> 
                                    <ExcelColumn label="Package" value="packageName" />
                                    <ExcelColumn label="Bill Cycle" value="billCycle" />
                                    <ExcelColumn label="Rate" value="rate" /> 
                                    <ExcelColumn label="Bill Type" value="rateType" /> 
                                    <ExcelColumn label="Bill Payable Person" value="billPayablePerson" /> 
                                </ExcelSheet>
                            </ExcelFile>
                        </Space>
                    </Col>
                </Row>
                    </>

                }
            </Card>
            <Modal
                title="Product Order Information Update"
                visible={isViewModalVisible}
                okButtonProps={{ form: 'updateClientInfo', htmlType: 'submit' }}
                onCancel={() => setIsViewModalVisible(false)}
                width={1000}
                okText="Update"
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    form={updateClientInfoForm}
                    id="updateClientInfo"
                    onFinish={clientInfoUpdateFormSubmit}
                >
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="billCycle"
                                label="Bill Cycle:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select bill cycle" },
                                ]}
                            >
                                <SelectBillCycleUpperCase  selected={billCycle} onChange={(e) => billCycleChange (e)}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="billPayablePerson"
                                label="Bill Payable Person(Instiute/Student):"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select payable person" },
                                ]}
                            >
                                <Select>
                                    <Option value="STUDENT">Student</Option>
                                    <Option value="INSTITUTE">Instiute</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="billType"
                                label="Bill Type:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select bill cycle type" },
                                ]}
                            >
                                <Select onChange={(e) => onChnageRateType(e)} value={selectedBillType}>
                                    <Option key="0" value="FIXED">FIXED</Option>
                                    <Option key="1" value="PER_STUDENT">PER_STUDENT</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="billUnit"
                                label="Bill Rate:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Write bill unit" },
                                ]}
                            >
                                <Input type="text" placeholder='Write bill unit' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="minStudent"
                                label="Minimum Student:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please Write minumum student" },
                                ]}
                            >
                                <Input type="text" placeholder='Write minumum student' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="packageId"
                                label="Select Package:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your package" },
                                ]}
                            >
                                <SelectPackageCustom selected={selectedPackage}  onChange={(e) => onChnagePackageId(e)}/>

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
