import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Steps, Form, Input, Row, Col, InputNumber, Button, DatePicker, Upload, message, Tooltip, Table, Select, Divider, Space, Popconfirm } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, PlusOutlined, SaveFilled, SaveOutlined, SendOutlined, UploadOutlined } from '@ant-design/icons';
import logo from "../../../../../src/assets/images/suite_logo.png";
import { SelectDistrict } from '../../select/SelectDistrict'
import { SelectThana } from '../../select/SelectThana'
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import moment from "moment";
import ImgCrop from 'antd-img-crop';
const { Option } = Select;

export default function Signup(props) {

    const partnerSignup = useStoreActions(state => state.partner.partnerSignup);
    const signUpStatus = useStoreState(state => state.partner.signUpStatus);
    const [basicInfo] = Form.useForm();
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const [thanaId, setThanaId] = useState<any>(null);
    const [disId, setdisId] = useState<any>(null);


    const basicInfoForm = (value) => {
        console.log('value', value);
        
        let payload:any = {
            "corporateNumber": value.corporateMobileNo,
            "designation": value.designation,
            "email": value.email,
            "fileContent": fileContent,
            "fileName": fileName,
            "fileSave": true,
            "partnerName": value.partnerName,
            "personalNumber": value.personalMobileNo,
            "photo": "string",
            "thanaId": thanaId.thanaId,
            "address": value.address
        }
        partnerSignup(payload);
        basicInfo.resetFields();
        setfileName('');
        setfileContent('');
        setThanaId('');
        setdisId('');
        setFileList([]);

    }

    const [fileList, setFileList] = useState<any>([]);

    const onChangeFile = ({ fileList: newFileList }) => {
        // console.log(newFileList);
        if (newFileList.length == 0) {
            setfileSave(false);
            setfileName("");
            setfileContent("");
        }
        if (newFileList.length == 1) {
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setdisBtn(true)
            setTimeout(() => {
                setfileContent(newFileList[0]?.thumbUrl.split(',')[1]);
                setdisBtn(false)
            }, 2000);

        }
        setFileList(newFileList);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onchangeDistrict = (val) => {
        //   console.log(val)
        setThanaId(null);
        setdisId(val);
    }

    const onchangeThana = (val) => {
        //   console.log(val)
        setThanaId(val);
    }
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    return (
        <div className="signupPage-wrapper-full">
            <div className="login-form-wrapper py-15">
                <div className="overlay-9 bg-white">
                    <div className="logo-wrapper">
                    <img src={logo} alt="login_logo" />
                </div>
                <div className="signupPage-wrapper">
                    <div className="signupPage">
                        <div className="loginBack text-center">
                            Already Registerd? <Link to="/login">Back to login</Link>
                        </div>
                            <Form
                                layout="horizontal"
                                onFinish={basicInfoForm}
                                id="basic-info"
                                form={basicInfo}
                                {...layout}
                                className="custom-form"
                                
                            >
                                <Row gutter={8}>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="partnerName"
                                            label="Name:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please write name" },
                                                {
                                                    max: 60,
                                                    message: "Name cannot be more than 60 characters.",
                                                },
                                            ]}
                                        >
                                            <Input type="text" placeholder="Name"></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="designation"
                                            label="Designation:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please write designation" },
                                            ]}
                                        >
                                            {/* <Input type="text" placeholder="Designation"></Input> */}
                                            <Select placeholder="Select Designation">
                                                <Option value="SDL">SDL</Option>
                                                <Option value="RM">RM</Option>
                                                <Option value="DM">DM</Option>
                                                <Option value="EX">EX</Option>
                                                <Option value="MRC">MRC</Option>
                                                <Option value="BILLING">BILLING</Option>
                                                <Option value="SUPPORT">SUPPORT</Option>
                                                <Option value="ISP_RESELLER">ISP RESELLER</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="personalMobileNo"
                                            label="Personal Number:"
                                            className="title-Text"

                                            rules={[
                                                { required: true, message: "Please write your number", },
                                            ]}
                                        >
                                            <Input type="text" placeholder="01711....."></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="corporateMobileNo"
                                            label="Corporate Number:"
                                            className="title-Text"
                                            rules={[
                                                { required: false, message: "Please write your number", },

                                            ]}
                                        >
                                            <Input type="text" placeholder="01711....."></Input>

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="email"
                                            label="Email:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please write your email address" },
                                                {
                                                    max: 60,
                                                    type: 'email',
                                                    message: "Pleaset Insert valid email.",
                                                },
                                            ]}
                                        >
                                            <Input type="text" placeholder="example@gmail.com"></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="district_id"
                                            label="District:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select your district", },
                                            ]}
                                        >
                                            <SelectDistrict onChange={(val) => onchangeDistrict(val)} selected={disId}

                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="thanaId"
                                            label="Thana:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select your thana", },
                                            ]}
                                        >
                                            <SelectThana onChange={(val) => onchangeThana(val)} selected={thanaId}

                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="address"
                                            label="Address:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please write your address", },
                                            ]}
                                        >
                                            <Input type="text" placeholder="address"></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                        <Form.Item
                                            name="user_image"
                                            label="Photo:"
                                            className="title-Text"
                                        >
                                            <ImgCrop rotate>
                                                <Upload
                                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture"
                                                    accept="image/png, image/jpeg"
                                                    fileList={fileList}
                                                    onChange={onChangeFile}
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                                </Upload>
                                            </ImgCrop>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={18} lg={18}>
                                        <Space size="small" style={{ float: "right" }}>
                                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                                Save
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
