import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectMonth } from '../../select/SelectMonth';
import { SelectYear } from '../../select/SelectYear';
import GetColumnSearchProps from '../../utils/getColumnSearchProps';

const { Option } = Select;


export default function IIGBillListPIP(props) {

    const check = useStoreState(state => state.isp.check);
    const iigClientBillInfoListForPip = useStoreState(state => state.iig.iigClientBillInfoListForPip);
    const fetchiigClientBillInfoListForPip = useStoreActions(state => state.iig.fetchiigClientBillInfoListForPip);
    const downloadVoucher = useStoreActions(state => state.isp.downloadVoucher);
    const sendSmsUnpaidIspClient = useStoreActions(state => state.isp.sendSmsUnpaidIspClient);
    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const [paymentStatus,setpaymentStatus]= useState<any>(0);
    const columns1 = [

        {
            title: 'Client Id',
            dataIndex: 'clientCustomId',
            key: 'clientCustomId',


        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',

        },
        {
            title: 'Mobile No',
            dataIndex: 'clientMobile',
            key: 'clientMobile',


        },
        {
            title: 'Address',
            dataIndex: 'mailingAddress',
            key: 'mailingAddress',

        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Package Price',
            dataIndex: 'billAmount',
            key: 'billAmount',

        },
        {
            title: 'Payment Date',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    {record?.billPaymentDate === "null" ? 'N/A' : record?.billPaymentDate}

                </Space>
            ),
        },
        {
            title: 'Download Report',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Button icon={<CloudDownloadOutlined />}
                        type="primary"
                        onClick={() => {
                            let postData = {
                                billId: record.billId
                            }
                            downloadVoucher(postData)
                        }}
                    >
                        Download</Button>
                </Space>
            ),
        }



    ]
    const columns2 = [

        {
            title: 'Client Id',
            dataIndex: 'clientCustomId',
            key: 'clientCustomId',


        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',


        },
        {
            title: 'Mobile No',
            dataIndex: 'clientMobile',
            key: 'clientMobile',


        },
        {
            title: 'Address',
            dataIndex: 'mailingAddress',
            key: 'mailingAddress',

        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Package Price',
            dataIndex: 'billAmount',
            key: 'billAmount',

        },

        {
            title: 'Payable Date',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    {record?.billPayableDate === "null" ? 'N/A' : record?.billPayableDate}

                </Space>
            ),
        },
        {
            title: 'Download Report',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Button icon={<CloudDownloadOutlined />}
                        type="primary"
                        onClick={() => {
                            let postData = {
                                billId: record?.billId,
                            }
                            downloadVoucher(postData)
                        }}
                    >
                        Download</Button>
                </Space>
            ),
        }


    ]
    const [colData, setcolData] = useState<any>([])

    const onFinish = (values: any) => {

        if (values.billStatus == 1) {
            setcolData(columns1)
        }

        if (values.billStatus == 0) {
            setcolData(columns2)
        }

        setfilterTable(null);
        setsearchv('')
        fetchiigClientBillInfoListForPip(values)
    };




    useEffect(() => {
        setPageTitle();
    }, []);




    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const sendSms = () => {

        let data = selectedValue?.map((item, key) => {
            return {
                billAmount: item.billAmount,
                billGenerateDate: item.billGenerateDate,
                billId: item.billId,
                billPayableDate: item.billPayableDate,
                billingMonth: item.billingMonth,
                billingYear: item.billingYear,
                clientCustomId: item.clientCustomId,
                clientId: item.clientId,
                clientMobile: item.clientMobile,
                clientName: item.clientName,
                mailingAddress: item.mailingAddress,
                packageName: item.packageName,

            }
        })
      
        sendSmsUnpaidIspClient({
            "ispClientBillInfoResponseList": data,
            "paymentStatus": paymentStatus
        })
        setselectedRowKeys([]);
        setselectedValue([]);
    }
    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = iigClientBillInfoListForPip.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };



    return (
        <Card title="Bill List" >
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15}>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}></Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="billStatus"
                            label="Bill Status:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select bill status" },
                            ]}
                        >
                            <Select placeholder="Select Bill Status" style={{ width: "100%" }} onChange={e=>setpaymentStatus(e)} allowClear>
                                <Option value="1">Paid</Option>
                                <Option value="0">Unpaid</Option>
                                <Option value="2">Disabled</Option>
                            </Select>


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="billMonth"
                            label="Month:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select month" },
                            ]}
                        >
                            <SelectMonth />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="billYear"
                            label="Year:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year" },
                            ]}
                        >
                            <SelectYear />


                        </Form.Item>

                    </Col>

                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>

                </Row>
            </Form>
            {iigClientBillInfoListForPip?.length > 0 &&
                < >
                    <span>Total Found: {iigClientBillInfoListForPip !== null ? iigClientBillInfoListForPip?.length : 0}</span>
                    <Input
                        style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                        placeholder="Search..."
                        value={searchv}
                        allowClear
                        onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                    />
                    <Table
                        bordered={true}
                        dataSource={searchv === '' ? iigClientBillInfoListForPip : filterTable}
                        rowSelection={rowSelection}
                        columns={colData}
                        rowKey="clientCustomId"
                        summary={pageData => {
                            let totalBill = 0;

                            iigClientBillInfoListForPip.forEach(({ billAmount }) => {
                                totalBill += billAmount;
                            });

                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>
                                            
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>
                                        <Text type="danger" strong>{totalBill}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>

                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>

                                </>
                            );
                        }}
                    />
  <Button type="primary" style={{ float: "right" }} onClick={() => sendSms()}>Send SMS</Button>
                </>

            }

        </Card>
    )
}
