import { Alert, Form, Input, Button, Checkbox, Layout, Row, Col, Tooltip, Spin } from 'antd';
import React from 'react';
import { Redirect } from "react-router-dom";
import { InfoCircleOutlined, UserOutlined, LoadingOutlined, HomeOutlined, MailOutlined, PhoneOutlined, KeyOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import logo from "../../../../src/assets/images/suite_logo.png";
import containerBG from "../../../../src/assets/images/container_bg.png";
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
// const FormItem = Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// interface IProps {
//     logInConnect: (cred: any) => void;
//     authFailed: boolean;
//     authFailedMessage: string | undefined;
// }

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 8 },
// };

// const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
// };

// const { Option } = Select;



// function onFinish() {

// }

export function Login() {
    // const authFailed = false;
    // const authFailedMessage = '';
    const authenticate = useStoreActions(state => state.auth.authenticate);
    const setBusy = useStoreActions(state => state.auth.setBusy);

    const error = useStoreState(state => state.auth.error);
    const isBusy = useStoreState(state => state.auth.busy);
    const user = useStoreState(state => state.auth.user);
    let rememberChoice: boolean = true;

    if (user) {
        return <Redirect to="/" />
    }

    const onRememberChange = (event) => {
        rememberChoice = event.target.checked;
    }

    const handleSubmit = async (payload) => {
        setBusy(true);
        payload.remember = rememberChoice;
        authenticate(payload);
    };

    const style = {
        container: {
            position: 'absolute' as 'absolute',
            background: '#ecf0f1',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };
    const flexClass = 'd-flex align-items-center justify-content-start flex-wrap';
    return (
        // <Layout style={style.container} className="login-form-wrapper">
        <Layout className="login-form-wrapper">
            <div className="container">
                <div className="row box-shadow border-radius-35">
                    <div className="col-md-6 d-none d-md-block">
                        <img src={containerBG} className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <div className="login-form h-100">
                            <div className="logo-wrapper">
                                <div className="loginLogo">
                                    <img src={logo} className="img-fluid" style={{marginTop:20}} />
                                </div>
                            </div>
                            <p className="page-sub-title">Sign in </p>
                            <Form name="login" onFinish={handleSubmit}>
                                <Form.Item name='username' rules={[{ required: true, message: 'Please input your username or email!' }]}>
                                    <Input
                                        name="username"
                                        placeholder="Your username"
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        suffix={
                                            <Tooltip title="username">
                                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>

                                <Form.Item name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
                                    <Input.Password
                                        name="password"
                                        placeholder="Password"
                                        prefix={<KeyOutlined className="site-form-item-icon" />}
                                        suffix={
                                            <Tooltip title="password">
                                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>

                                <Row>
                                    <Col md={24} lg={24} xs={24} className="mb-20">
                                        <Checkbox defaultChecked onChange={onRememberChange}>Remember me</Checkbox>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} lg={24} xs={24}>
                                        <Button type="primary" className="submitButton" htmlType="submit" disabled={isBusy}>
                                            {isBusy ? <Spin indicator={antIcon} /> : 'Login'}
                                        </Button>

                                    </Col>
                                </Row>
                                {
                                    error ? <Alert style={{ marginTop: '20px' }} message={error} type="error" /> : null
                                }
                                <hr />
                                <div className="signUp-wrapper">
                                    <span> Not a member? <Link className="signup" to="/signup">SIGN UP</Link></span>  
                                    <span> <Link className="forgetPassword" to="/forgot-password">Forgot Password?</Link></span>
                                </div>
                        
                                {/* <div className="loginIcons">
                                    <div className={flexClass}><HomeOutlined />House 55, Road 4/A, Dhanmondi R/A, Dhaka 1209</div>
                                    <div className={flexClass}><MailOutlined /><a href="mailto:example@example.com">info@shebasuite.com</a></div>
                                    <div className={flexClass}><PhoneOutlined /><a href="tel:+8809612191919"> +880 9612-191919</a></div>
                                </div> */}

                            </Form>
                            {/* <div className="footer">
                                <span style={{ marginRight: "8px" }}> <a href='http://aws.okkhor.org'>My Dashboard</a> : Just a dashboard </span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
