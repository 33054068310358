import { post, get, del} from "../http";


export const saveProductBillGenerateMonthly = (payload) => post(`/product/bill-generate/monthly/save?customProductId=${payload.customProductId}&month=${payload.month}&year=${payload.year}`, '');
export const saveProductBillGenerateYearly = (payload) => post(`/product/bill-generate/yearly/save`, payload);
export const saveProductBillGenerateYearly2 = (payload) => post(`/school-university/bill-generate/yearly`, payload);
export const fetchclientUnit = (id) => get(`/client-details/unity?clientId=${id}`);
export const fetchsearchApipClientBillPaidUnpaidList = (value) => get(`/product/bill/report/apip/client-bill/paid-unpaid/view?productId=${value?.productId}&year=${value?.year}`);
export const fetchsearchPipClientBillPaidUnpaidList = (value) => get(`/product/bill/report/pip/client-bill/paid-unpaid/view?productId=${value?.productId}&year=${value?.year}`);
export const fetchsearchSdlClientBillPaidUnpaidList = (value) => get(`/product/bill/report/sdl/client-bill/paid-unpaid/view?productId=${value?.productId}&year=${value?.year}`);
export const fetchsearchProductWiseMonthlyPaidUnpaidList = (value) => get(`/product/bill/report/sdl/product-wise/monthly/paid-unpaid/view?month=${value?.month}&year=${value?.year}`);
export const fetchsearchApipClientBillPaidUnpaidListSDL = (value) => get(`/product/bill/report/apip/client-bill/paid-unpaid/view/by/sdl?productId=${value?.productId}&year=${value?.year}&apipId=${value?.apipId}`);
export const fetchdisbursementPayableList = () => get(`/product-bill/disbursement/payable/list`);
export const saveDisbursement = (payload) => post(`/product-bill/disbursement/save`, payload);
export const fetchispBillPaymentLogList = (value) => get(`/isp/bill-payment/ipn/log-list?customClientId=${value?.customClientId}&logDate=${value?.logDate}`);
export const fetchispClientBillPaymentCheck = (id) => get(`/isp/bill-payment/ipn/check?valId=${id}`);
export const ispClientBillPaymentUpdate = (value) => post(`/isp/bill-payment/ipn/update?valId=${value?.valId}&mestroCall=${value?.mestroCall}`);
export const saveProductBillGenerateMonthly2 = (payload) => post(`/school-university/bill-generate/monthly`, payload);
export const saveProductBillGenerateHalfYear = (payload) => post(`/school-university/bill-generate/half-yearly`, payload);
export const fetchbillGenerateInfoViewList = (payload) => post(`/school-university/bill-generate/list`, payload);
export const updateBillGenerateInfo = (payload) => post(`/school-university/bill-generate/update`, payload);
export const fetchMenualBillGenerateListUrl = (payload) => get(`/product/manual/bill-collection/single/client/bill-generate/list?clientId=${payload.clientId}&year=${payload.year}`);
export const manualBillGenerateurl = (payload) => post(`/product/manual/bill-collection/save`, payload);
export const deleteMenualGenerateBillUrl = (payload) => del(`/school-university/bill-generate/delete?billGenerateId=${payload}`);