import { DeleteOutlined, EyeOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Descriptions, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectProduct } from '../../select/SelectProduct'
import GetColumnSearchProps from '../../utils/getColumnSearchProps';

export default function IspBillSolve(props) {

    const ispBillPaymentLogList = useStoreState(state => state.bill.ispBillPaymentLogList);
    const fetchispBillPaymentLogList = useStoreActions(state => state.bill.fetchispBillPaymentLogList);
    const ispClientBillPaymentCheck = useStoreState(state => state.bill.ispClientBillPaymentCheck);
    const fetchispClientBillPaymentCheck = useStoreActions(state => state.bill.fetchispClientBillPaymentCheck);
    const ispClientBillPaymentUpdate = useStoreActions(state => state.bill.ispClientBillPaymentUpdate);

    const fdata = [];
    const [hasData, sethasData] = useState<boolean>(false)

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [apipD, setapipD] = useState<any>();
    const [apipName, setapipName] = useState<any>();

    const onFinish = (values: any) => {
        sethasData(true)
        values.customClientId = values.customClientIdx
        values.logDate = moment(values?.logDate).format("YYYY-MM-DD");
        setapipD(values);
        fetchispBillPaymentLogList(values);
    };

    const apipDetails = (values: any) => {
        setapipName(values)
        fetchispClientBillPaymentCheck(values?.valId);
    };

    useEffect(() => {
        if (ispClientBillPaymentCheck !== null) {
            setIsModalVisible(true);
        }

    }, [ispClientBillPaymentCheck])

    console.log(ispClientBillPaymentCheck)

    useEffect(() => {
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Bill Problem'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }
    const [isModalVisible, setIsModalVisible] = useState(false);


    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',

        },
        {
            title: 'Log Date',
            dataIndex: 'logDate',
            key: 'logDate',

        },        
        {
            title: 'Val Id',
            dataIndex: 'valId',
            key: 'valId',

        },
        {
            title: 'Billing Month',
            dataIndex: 'billingMonth',
            key: 'billingMonth',

        },
        {
            title: 'Billing Year',
            dataIndex: 'billingYear',
            key: 'billingYear',

        },
        {
            title: 'Trn Status',
            dataIndex: 'trnStatus',
            key: 'trnStatus',
        },
        {
            title: 'Bank Transaction Id',
            dataIndex: 'bankTransactionId',
            key: 'bankTransactionId',
        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Check">
                        <Button type="primary" icon={<EyeOutlined />} onClick={() => { apipDetails(record) }} >Check</Button>
                    </Tooltip>
                </Space>
            ),
        },

    ]

    const onupdate = (values: any) => {
        values.mestroCall = values.mestroCall === 1 ? true : false
        values.valId = ispClientBillPaymentCheck?.valId
        ispClientBillPaymentUpdate(values);
        setIsModalVisible(false);
        form2.resetFields();
    }

    return (
        <Card title="Bill Problem" >
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} >
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                        <Form.Item
                            name="customClientIdx"
                            label="Client Id:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please input client id" },
                            ]}
                        >
                            <Input placeholder='Client Id' allowClear className='exclude' style={{ border: '1px solid #03D665', }} />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                        <Form.Item
                            name="logDate"
                            label="Log Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%', height: 50 }} placeholder="Select Date" />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" style={{ height: 50 }} >
                                Search
                            </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>
            {ispBillPaymentLogList.length > 0 && hasData &&

                <Table bordered={true} dataSource={ispBillPaymentLogList} columns={columns} />


            }
            <Modal title={"Bill Problem"}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                footer={null}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Close"
                width="50%"
            >
                <Descriptions
                    // title="User Info"
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                    <Descriptions.Item label="Card Type">{ispClientBillPaymentCheck?.cardType}</Descriptions.Item>
                    <Descriptions.Item label="Bank Tran Id">{ispClientBillPaymentCheck?.bankTransactionId}</Descriptions.Item>
                    <Descriptions.Item label="Payable Month">{ispClientBillPaymentCheck?.valueB}</Descriptions.Item>
                    <Descriptions.Item label="Payable Year">{ispClientBillPaymentCheck?.valueC}</Descriptions.Item>
                    <Descriptions.Item label="Tran Date">{ispClientBillPaymentCheck?.trnDate}</Descriptions.Item>
                    <Descriptions.Item label="Customer Name">{ispClientBillPaymentCheck?.customerName}</Descriptions.Item>
                    <Descriptions.Item label="Val Id">{ispClientBillPaymentCheck?.valId}</Descriptions.Item>
                </Descriptions>

                <Form layout="vertical" onFinish={onupdate} id='create-class' form={form2} >
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <Form.Item
                            name="mestroCall"
                            label="Mestro Call:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select mestro call" },
                            ]}
                        >
                            <Select placeholder="Select Option" allowClear style={{ width: 250 }}>
                                <Select.Option value={1}>Yes</Select.Option>
                                <Select.Option value={0}>No</Select.Option>
                            </Select>
                        </Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginTop: 30, marginLeft: 20 }} >
                            Submit
                        </Button>
                    </div>
                </Form>

            </Modal>
        </Card>
    )
}
