import { DeleteOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import GetColumnSearchProps from '../../utils/getColumnSearchProps';
export default function BillPaymentReport(props) {

    const searchProductWiseMonthlyPaidUnpaidList = useStoreState(state => state.bill.searchProductWiseMonthlyPaidUnpaidList);
    const fetchsearchProductWiseMonthlyPaidUnpaidList = useStoreActions(state => state.bill.fetchsearchProductWiseMonthlyPaidUnpaidList);


    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        let postData: any = {
            year: values?.year,
            month: values?.month,
        }
        fetchsearchProductWiseMonthlyPaidUnpaidList(postData);
    };


    useEffect(() => {
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Report'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const columns = [

        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            ...GetColumnSearchProps('productName', 'Product Name'),
        },
        {
            title: 'Generated Amount',
            dataIndex: 'generatedBillAmount',
            key: 'generatedBillAmount',
            ...GetColumnSearchProps('generatedBillAmount', 'Generated Amount'),
        },
        {
            title: 'Total Paid',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            ...GetColumnSearchProps('paidAmount', 'Total Paid'),
        },
        {
            title: 'Total Due',
            dataIndex: 'due',
            key: 'due',
            ...GetColumnSearchProps('due', 'Total Due'),
        }

    ]

    const currentyear = new Date().getFullYear();

    // console.log(currentyear)

    const optionsYear = [
        { value: currentyear - 1, label: currentyear - 1 },
        { value: currentyear, label: currentyear },
        { value: currentyear + 1, label: currentyear + 1 }
    ];

    const optionsMonth = [
        { value: "January", label: "January" },
        { value: "February", label: "February" },
        { value: "March", label: "March" },
        { value: "April", label: "April" },
        { value: "May", label: "May" },
        { value: "June", label: "June" },
        { value: "July", label: "July" },
        { value: "August", label: "August" },
        { value: "September", label: "September" },
        { value: "October", label: "October" },
        { value: "November", label: "November" },
        { value: "December", label: "December" }

    ]


    return (
        <>
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} className="box-shadow p-15">
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="year"
                            label="Year:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please Select Year" },
                            ]}
                        >
                            <Select allowClear placeholder="Select Year" options={optionsYear} />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="month"
                            label="Product:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select your product" },
                            ]}
                        >
                            <Select allowClear placeholder="Select Month" options={optionsMonth} />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                                    </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>
            {searchProductWiseMonthlyPaidUnpaidList.length > 0 &&
                <Card title="Month wise Paid/Due List" >

                    <Table bordered={true} dataSource={searchProductWiseMonthlyPaidUnpaidList} columns={columns} />

                </Card>

            }
        </>
    )
}
