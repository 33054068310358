import { DeleteOutlined, EditOutlined, EyeOutlined, TeamOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Select, Space, Tooltip, Upload, Typography,Table} from 'antd';

import ImgCrop from 'antd-img-crop'
import Avatar from 'antd/lib/avatar/avatar'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectDistrict } from '../../select/SelectDistrict'
import { SelectDistrictForUpdateModal } from '../../select/SelectDistrictForUpdateModal'
import { SelectThana } from '../../select/SelectThana'
import { SelectThanaForUpdateModal } from '../../select/SelectThanaForUpdateModal'
import { SelectUnderPartner } from '../../select/SelectUnderPartner'
const { Option } = Select;
const { Paragraph } = Typography;
export default function PendingListSuperAdmin(props) {

    const pendingList = useStoreState(state => state.partner.pendingList);
    const underPartnerList = useStoreState(state => state.partner.underPartnerList);
    const fetchPendingList = useStoreActions(state => state.partner.fetchPendingList);
    const fetchpartnerListByLevel = useStoreActions(state => state.partner.fetchpartnerListByLevel);
    const partnerAssign = useStoreActions(state => state.partner.partnerAssign);
    const applicantPartnerDelete = useStoreActions(state => state.partner.applicantPartnerDelete);
    const thanaListFetch = useStoreActions((state) => state.common.thanaListFetch);
    const updatePartnerDetails = useStoreActions((state) => state.partner.updatePartnerDetails);
    const pendingPartnerAssign = useStoreActions((state) => state.partner.pendingPartnerAssign);

    const [assignForm] = Form.useForm();
    const [updatePartnerForm] = Form.useForm();
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const [disId, setdisId] = useState<any>(null);
    const [disIdUpdateval, setdisIdUpdate] = useState<any>(null);    
    const [thanaId, setThanaId] = useState<any>(null);
    const [thanaIdUpdateVal, setThanaIdUpdate] = useState<any>(null);
    const [rowId, setRowID] = useState<any>(null);
    const fetechpartnerProfile = useStoreActions(state => state.partner.fetchpartnerProfile);
    const fetchpartnerProfile2 = useStoreActions((state) => state.common.fetchpartnerProfile2);
    const partnerProfile = useStoreState((state) => state.common.partnerProfile);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalUpdateFormVisible, setIsModalUpdateFormVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const fetchunderDesignationList = useStoreActions((state) => state.partner.fetchunderDesignationList);
    const [districtUpdateAssignValue, setDistrictUpdateAssignValue] = useState<any>();
    const [thanaUpdateAssignValue, setThanaUpdateAssignValue] = useState<any>();
    const [thanaUpdateIdAssignValue, setThanaUpdateIdAssignValue] = useState<any>();
    const [designationIdUpdateAssignValue, setDesignationIdUpdateAssignValue] = useState<any>();
    const [underPartnerId, setUnderPartnerId] = useState<any>();
    const [underParentId, setUnderParentId] = useState<any>();
    const [underDesignationUpdateValue, setUnderDesignationUpdateValue] = useState<any>([]);
    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const [rowSingleData, setRowSingleData] = useState<any>();
    const pendingPartnerUpdate = useStoreActions((state) => state.partner.pendingPartnerUpdate);
    const [parentId, setParentId] = useState<any>();
    const [userRoles, setUserRoles] = useState<any>();

    useEffect(() => {
        fetchPendingList();
        setPageTitle();
        fetchpartnerProfile2();
    }, [])
    // let roles:any = localStorage.getItem("roles")
    // let role = JSON.parse(roles);
    
    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Applied Partner'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [assignModal, setassignModal] = useState<boolean>(false)

    const onchangeDistrict = (val) => {
        console.log('val', val.districtId);
        
        setThanaId(null);
        setdisId(val.districtId)
        setThanaIdUpdate(null)
        setdisIdUpdate(val.districtId);
    };
    

    const onchangeThana = (val) => {
        setThanaId(val)
        setThanaIdUpdate(val.thanaId)
    };


    const deletePartner = value => {
        applicantPartnerDelete(value);
    }  

    const showModal = (value) => {
        setLoading(true);
        fetechpartnerProfile(value);
        setIsModalVisible(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const updatePartnerFormSubmit = (value) => {
        
        let postData:any = {
            corporateNumber: value?.corporateMobileNumberUpdate,
            designation: value?.designationUpdate,
            email: value?.partnerEmailUpdate,
            fileContent: fileContent,
            fileName: fileName,
            fileSave: true,
            partnerId: parentId,
            partnerName: value?.partnerNameUpdate,
            personalNumber: value?.personalMobileNumberUpdate,
            photo: "string",
            thanaId: thanaIdUpdateVal
        }
        pendingPartnerUpdate(postData);
        setIsModalVisible(false);
        setIsModalUpdateFormVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalUpdateFormVisible(false);
    };
    const columns = [
        {
            title: 'Partner ID',
            dataIndex: 'partnerCustomId',
            key: 'partnerCustomId',
        },
        {
            title: 'Partner Name',
            dataIndex: 'partnerName',
            key: 'partnerName',
        },
        {
            title: 'Personal Mobile No.',
            dataIndex: 'personalNumber',
            key: 'personalNumber',
        },
        {
            title: 'Corporate Number',
            dataIndex: 'corporateNumber',
            key: 'corporateNumber',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'District Name',
            dataIndex: 'districtName',
            key: 'districtName',
        },
        {
            title: 'Thana',
            dataIndex: 'thanaName',
            key: 'thanaName',
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <>
                    {partnerProfile?.partnerRoles?.includes('ROLE_ADMIN') && partnerProfile.partnerRoles?.includes('ROLE_SUPER_ADMIN') &&
                        <Space size="middle">
                            <Tooltip title="Partner Information View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                        showModal(record.partnerId); 
                                        setRowSingleData(record);
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title="Update Partner Information">
                                    <Button type='primary' 
                                        onClick={() => {
                                            setIsModalUpdateFormVisible(true);
                                            console.log(record.partnerId)
                                            updatePartnerForm.setFieldsValue({
                                                partnerNameUpdate : record.partnerName,
                                                personalMobileNumberUpdate: record.personalNumber,
                                                corporateMobileNumberUpdate: record.corporateNumber,
                                                partnerEmailUpdate: record.email,
                                                designationUpdate: record.designation
                                            });
                                            
                                            setdisIdUpdate(record.districtId);
                                            thanaListFetch(record.districtId);
                                            setThanaIdUpdate(record.thanaId);
                                            setParentId(record.partnerId);
                                        }} 
                                        icon={<EditOutlined />} 
                                            
                                    />
                            </Tooltip>
                            <Tooltip title="Partner Approved">
                                <Button type="primary" icon={<TeamOutlined />} onClick={() => { 
                                    (setassignModal(true)); 
                                    setRowID(record?.partnerId) 
                                    assignForm.setFieldsValue({
                                        designationPartnerUpdate : record.designation,
                                        districtUpdate: record.districtName,
                                        thanaIdUpdate: record.thanaName,
                                        partnerViewName: record.partnerName,
                                        partnerViewID: record.partnerCustomId,
                                    })
                                    setDistrictUpdateAssignValue(record.districtId);
                                    setThanaUpdateAssignValue(record.thanaName);
                                    setThanaUpdateIdAssignValue(record.thanaId);
                                    thanaListFetch(record.districtId);
                                    fetchunderDesignationList(record.designation);
                                    setUnderParentId(record.partnerId);
                                }} />
                            </Tooltip>
                            <Popconfirm
                                title="Are you sure to delete this?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => deletePartner(record?.partnerId)}
                            >
                                
                                <Tooltip title="Delete">
                                    <Button danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>

                        </Space>
                    }
                    {partnerProfile?.partnerRoles?.includes('ROLE_RM') && 
                        <Space size="middle">
                            <Tooltip title="Partner Information View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                        showModal(record.partnerId); 
                                        setRowSingleData(record);
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    }
                    {partnerProfile?.partnerRoles?.includes('ROLE_BILLING') && 
                        <Space size="middle">
                            <Tooltip title="Partner Information View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                        showModal(record.partnerId); 
                                        setRowSingleData(record);
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    } 
                </>
            ),
        },
    ]
    const columns2 = [
        {
            title: 'Exam Title',
            dataIndex: 'examTitle',

        },
        {
            title: 'Major/Group',
            dataIndex: 'major',
        },
        {
            title: 'Passing Year',
            dataIndex: 'passingYear',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
        },
        {
            title: 'Institute/University',
            dataIndex: 'instituteName',
        },
        {
            title: 'Result',
            dataIndex: 'result',
        },
    ];

    const search = value => {

        const result = pendingList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };


    const onchangeDistrictUpdate = (value) => {
        setDistrictUpdateAssignValue(value.districtId);
        thanaListFetch(value.districtId);
        setThanaUpdateIdAssignValue(null);
        setThanaUpdateAssignValue(null);
    }

    const onChangeThanaUpdate = (value) => {
        setThanaUpdateIdAssignValue(value.thanaId);
        setThanaUpdateAssignValue(value.thanaName);
    }

    const onChangeDesigntation = (value) => {
        fetchunderDesignationList(value);
        setDesignationIdUpdateAssignValue(value);
    }

    const onChnageUnderPartner = (value) => {
        setUnderDesignationUpdateValue(value.partnerId);
        setUnderPartnerId(value.partnerParentId)
    }

    const onChangeFile = ({ fileList: newFileList }) => {
        // console.log(newFileList);
        if (newFileList.length == 0) {
            setfileSave(false);
            setfileName("");
            setfileContent("");
        }
        if (newFileList.length == 1) {
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setdisBtn(true)
            setTimeout(() => {
                setfileContent(newFileList[0]?.thumbUrl.split(',')[1]);
                setdisBtn(false)
            }, 2000);

        }
        setFileList(newFileList);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const [fileList, setFileList] = useState<any>([
    ]);
    
    const partnerAssignUpdateFormSubmit = (value) => {
        let postData:any = {
            "areaId": thanaUpdateIdAssignValue,
            "designation": value.designationPartnerUpdate,
            "parentId": underPartnerId,
            "partnerId": underParentId
        }
        pendingPartnerAssign(postData);
        assignForm.resetFields();
        setassignModal(false);
    }

    
    

    return (
        <>
            <Card title="Applied Partner List" >

                <Table bordered={true} dataSource={pendingList} columns={columns} />
                <Modal
                    title="Partner Approved"
                    visible={assignModal}
                    onCancel={() => setassignModal(false)}
                    centered
                    cancelText="Close"
                    okText="Update"
                    maskClosable={false}
                    okButtonProps={{ form: 'updateApprover', htmlType: 'submit' }}
                >
                    <Form
                        layout='vertical'
                        form={assignForm}
                        id="updateApprover"
                        onFinish={partnerAssignUpdateFormSubmit}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="partnerViewID"
                                    label="Partner ID"
                                    className="title-Text"
                                >
                                    <Input disabled/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="partnerViewName"
                                    label="Partner Name"
                                    className="title-Text"
                                >
                                    <Input disabled/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="designationPartnerUpdate"
                                    label="Designation:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select your status" },
                                    ]}
                                >
                                    <Select placeholder="Select Designation" style={{ width: "100" }} onChange={(val) => onChangeDesigntation(val)}>
                                        <Option value="SDL">SDL</Option>
                                        <Option value="RM">RM</Option>
                                        <Option value="DM">DM</Option>
                                        <Option value="EX">EX</Option>
                                        <Option value="BILLING">BILLING</Option>
                                        <Option value="SUPPORT">SUPPORT</Option>
                                        <Option value="ISP_RESELLER">ISP RESELLER</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="underPartnerUpdate"
                                    label="Under Partner:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select your partner" },
                                    ]}
                                >
                                    <SelectUnderPartner onChange={(val) => onChnageUnderPartner(val)}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="districtUpdate"
                                    label="District"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select district" },
                                    ]}
                                >
                                    <SelectDistrictForUpdateModal onChange={(val) => onchangeDistrictUpdate(val)} selected={districtUpdateAssignValue}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="thanaIdUpdate"
                                        label="Thana"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select your thana", },
                                        ]}
                                    >
                                        <SelectThanaForUpdateModal onChange={(val) => onChangeThanaUpdate(val)} selected={thanaUpdateAssignValue} />
                                    </Form.Item>
                                </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal
                    title="Partner Information" 
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    okButtonProps={{ style: { display: 'none' } }}
                    centered
                    width={700}
                    okText="Save"
                >
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Partner ID
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.partnerCustomId ? rowSingleData?.partnerCustomId : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Partner Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.partnerName ? rowSingleData?.partnerName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Designation
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.designation ? rowSingleData?.designation : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Corporate Number
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.corporateNumber ? rowSingleData?.corporateNumber : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Personal Number
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.personalNumber ? rowSingleData?.personalNumber : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Email
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.email ? rowSingleData?.email : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                District Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.districtName ? rowSingleData?.districtName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Thana
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.thanaName ? rowSingleData?.thanaName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Address
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={16}>
                            {rowSingleData && rowSingleData?.address ? rowSingleData?.address : ''}
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    title="Update Pending Partner" 
                    visible={isModalUpdateFormVisible}
                    onCancel={handleCancel}
                    centered
                    cancelText="Close"
                    okText="Update"
                    maskClosable={false}
                    okButtonProps={{ form: 'updatePartnerFromData', htmlType: 'submit' }}
                    width={"60%"}
                    style={{ width: "60%", overflowY: 'auto' }}
                >
                    <Form
                        layout="vertical"
                        form={updatePartnerForm}
                        id="updatePartnerFromData"
                        onFinish={updatePartnerFormSubmit}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="partnerNameUpdate"
                                    label="Partner Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write partner name" },
                                    ]}
                                >
                                    <Input placeholder='Write Partner Name' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="personalMobileNumberUpdate"
                                    label="Personal Mobile No."
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write personal mobile no" },
                                    ]}
                                >
                                    <Input placeholder='Write Personal Mobile No' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="corporateMobileNumberUpdate"
                                    label="Corporate Number"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write corporate number" },
                                    ]}
                                >
                                    <Input placeholder='Write Corporate Number' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="partnerEmailUpdate"
                                    label="Email"
                                    className="title-Text"
                                >
                                    <Input placeholder='Write Partner Email' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="districtIdUpdate"
                                    label="District"
                                    className="title-Text"
                                    // rules={[
                                    //     { required: true, message: "Please select your district", },
                                    // ]}
                                >
                                    <SelectDistrictForUpdateModal onChange={(e) => onchangeDistrict(e)} selected={disIdUpdateval}

                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="thanaIdUpdate"
                                    label="Thana"
                                    className="title-Text"
                                    // rules={[
                                    //     { required: true, message: "Please select your thana", },
                                    // ]}
                                >
                                    <SelectThanaForUpdateModal onChange={(val) => onchangeThana(val)} selected={thanaIdUpdateVal}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="designationUpdate"
                                    label="Designation"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write designation", },
                                    ]}
                                >
                                    <Select placeholder="Select Designation">
                                        <Option key="0" value="SDL">SDL</Option>
                                        <Option key="1" value="RM">RM</Option>
                                        <Option key="2" value="DM">DM</Option>
                                        <Option key="3" value="EX">EX</Option>
                                        <Option key="4" value="BILLING">BILLING</Option>
                                        <Option key="5" value="SUPPORT">SUPPORT</Option>
                                        <Option key="6" value="ISP_RESELLER">ISP RESELLER</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    name="user_image"
                                    label="Photo:"
                                    className="title-Text"
                                >
                                    <ImgCrop rotate>
                                        <Upload
                                            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture"
                                            accept="image/png, image/jpeg"
                                            fileList={fileList}
                                            onChange={onChangeFile}
                                            onPreview={onPreview}
                                        >
                                            {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Modal>

            </Card>
        </> 
    )
}
