import { DeleteOutlined, EyeOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectProduct } from '../../select/SelectProduct'
import GetColumnSearchProps from '../../utils/getColumnSearchProps';

export default function BillPaymentReport(props) {

    const searchSdlClientBillPaidUnpaidList = useStoreState(state => state.bill.searchSdlClientBillPaidUnpaidList);
    const fetchsearchSdlClientBillPaidUnpaidList = useStoreActions(state => state.bill.fetchsearchSdlClientBillPaidUnpaidList);
    const searchApipClientBillPaidUnpaidListSDL = useStoreState(state => state.bill.searchApipClientBillPaidUnpaidListSDL);
    const fetchsearchApipClientBillPaidUnpaidListSDL = useStoreActions(state => state.bill.fetchsearchApipClientBillPaidUnpaidListSDL);

    const fdata = [];
    const [hasData, sethasData] = useState<boolean>(false)

    const [form] = Form.useForm();
    const [apipD, setapipD] = useState<any>();
    const [apipName, setapipName] = useState<any>();
    const onFinish = (values: any) => {
        sethasData(true)
        let postData: any = {
            year: values?.year,
            productId: values?.product_id?.productId,

        };
        setapipD(postData);
        // localStorage.setItem('year', values?.year);
        // localStorage.setItem('productId', values?.product_id?.productId);
        //  console.log(postData)
        fetchsearchSdlClientBillPaidUnpaidList(postData);
    };

    const apipDetails = (values: any) => {
        setapipName(values?.apipName)
        apipD.apipId = values?.apipId;
        // let fetchData ={
        //     year: localStorage.getItem('year'),
        //     productId: localStorage.getItem('productId'),
        //     apipId:values?.apipId
        // };
        // console.log(fetchData);
        fetchsearchApipClientBillPaidUnpaidListSDL(apipD);

        setTimeout(() => {
            setIsModalVisible(true);
        }, 200);
    };


    useEffect(() => {
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Report'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }
    const [isModalVisible, setIsModalVisible] = useState(false);


    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'PIP Name',
            dataIndex: 'pipName',
            key: 'pipName',
            ...GetColumnSearchProps('pipName', 'PIP Name'),
        },
        {
            title: 'APIP Name',
            dataIndex: 'apipName',
            key: 'apipName',
            ...GetColumnSearchProps('apipName', 'APIP Name'),
        },
        {
            title: 'APIP Mobile No.',
            dataIndex: 'apipMobileNo',
            key: 'apipMobileNo',
            ...GetColumnSearchProps('apipMobileNo', 'APIP Mobile no.'),
        },
        {
            title: 'Area/Address',
            dataIndex: 'area',
            key: 'area',
            ...GetColumnSearchProps('area', 'Area/Address'),
        },
        {
            title: 'Paid',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            ...GetColumnSearchProps('paidAmount', 'Paid'),
        },
        {
            title: 'Due',
            dataIndex: 'due',
            key: 'due',
            ...GetColumnSearchProps('due', 'Due'),
        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="APIP Bill">
                        <Button type="primary" icon={<EyeOutlined />} onClick={() => { apipDetails(record) }} >View</Button>
                    </Tooltip>
                </Space>
            ),
        },

    ]

    const columnsAPIP = [
        {
            title: 'Product Id',
            dataIndex: 'clientProvideId',
            key: 'clientProvideId',
            ...GetColumnSearchProps('clientProvideId', 'Product Id'),
        },
        {
            title: 'Client/Product Name',
            dataIndex: 'clientName',
            key: 'clientName',
            ...GetColumnSearchProps('clientName', 'Client/Product Name'),
        },
        {
            title: 'Area/Address',
            dataIndex: 'address',
            key: 'address',
            ...GetColumnSearchProps('address', 'Area/Address'),
        },
        {
            title: 'Status',
            dataIndex: 'clientStatus',
            key: 'clientStatus',
            ...GetColumnSearchProps('clientStatus', 'Status'),
        },
        {
            title: 'Bill Month',
            dataIndex: 'billMonth',
            key: 'billMonth',
            ...GetColumnSearchProps('billMonth', 'Bill Month'),
        },
        {
            title: 'Paid',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            ...GetColumnSearchProps('paidAmount', 'Paid'),
        },
        {
            title: 'Due/Unpaid',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            ...GetColumnSearchProps('dueAmount', 'Due/Unpaid'),
        },


    ]

    const currentyear = new Date().getFullYear();

    // console.log(currentyear)

    const optionsYear = [
        { value: currentyear - 1, label: currentyear - 1 },
        { value: currentyear, label: currentyear },
        { value: currentyear + 1, label: currentyear + 1 }
    ];
    const [productId, setProductId] = useState<any>(null);

    const onchangeproductId = (val) => {
        setProductId(val);
    };


    return (
        <>
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} className="box-shadow p-15">
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="year"
                            label="Year:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please Select Year" },
                            ]}
                        >
                            <Select allowClear placeholder="Select Year" options={optionsYear} />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="product_id"
                            label="Product:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select your product" },
                            ]}
                        >
                            <SelectProduct onChange={(val) => onchangeproductId(val)} selected={productId}

                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                                    </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>
            {searchSdlClientBillPaidUnpaidList.length > 0 && hasData &&
                <Card title="Product wise Paid/Due List" >
                        <Table bordered={true} dataSource={searchSdlClientBillPaidUnpaidList} columns={columns} />
                </Card>

            }
            <Modal title={"APIP Bill Payment of " + apipName}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Close"
                width="60%"
            >
                <Table bordered={true} dataSource={searchApipClientBillPaidUnpaidListSDL} columns={columnsAPIP} />
            </Modal>
        </>
    )
}
