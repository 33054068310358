import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { fetchDistrictList, fetchThanaList, fetchpartnerProfile, fetchclientTansactionSummary, fetchclientTansactionVendorSummary, fetchdateToDateSingleClientTansactionDetails, fetchdateToDateTansactionDetails, fetchsingleClientTansactionSummary, fetchsingleClientVendorTansactionSummary, fetchpublicclientList } from '../../../http/common/common';

export interface Common {
	districtList: any,
	thanaList: any,
	setdistrictList: Action<Common, any>;
	setthanaList: Action<Common, any>;
	districtListFetch: Thunk<Common>;
	thanaListFetch: Thunk<Common>;
	partnerProfile: any;
	setpartnerProfile: Action<Common, any>;
	fetchpartnerProfile: Thunk<Common>
	fetchpartnerProfile2: Thunk<Common>


	publicclientList: any,
	setpublicclientList: Action<Common, any>;
	fetchpublicclientList: Thunk<Common>;

	clientTansactionSummary: any,
	setclientTansactionSummary: Action<Common, any>;
	fetchclientTansactionSummary: Thunk<Common, any>;

	clientTansactionVendorSummary: any,
	setclientTansactionVendorSummary: Action<Common, any>;
	fetchclientTansactionVendorSummary: Thunk<Common, any>;

	dateToDateSingleClientTansactionDetails: any,
	setdateToDateSingleClientTansactionDetails: Action<Common, any>;
	fetchdateToDateSingleClientTansactionDetails: Thunk<Common, any>;

	dateToDateTansactionDetails: any,
	setdateToDateTansactionDetails: Action<Common, any>;
	fetchdateToDateTansactionDetails: Thunk<Common, any>;

	singleClientTansactionSummary: any,
	setsingleClientTansactionSummary: Action<Common, any>;
	fetchsingleClientTansactionSummary: Thunk<Common, any>;

	singleClientVendorTansactionSummary: any,
	setsingleClientVendorTansactionSummary: Action<Common, any>;
	fetchsingleClientVendorTansactionSummary: Thunk<Common, any>;


	updateOfpsConfigurationAmount: Thunk<Common>;
	fetchschoolOfpsConfigurationList: Thunk<Common>;
	setchoolOfpsConfigurationList: Action<Common, any>;
	schoolOfpsConfigurationList: any;

	updateOfpsConfigurationAmount2: Thunk<Common>;
	fetchuniveristyOfpsConfigurationList: Thunk<Common>;
	setuniveristyOfpsConfigurationList: Action<Common, any>;
	univeristyOfpsConfigurationList: any;


	loading: boolean;
	setLoading: Action<Common, boolean>;
}

export const commonStore: Common = {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),
	districtList: null,
	thanaList: null,
	partnerProfile: null,
	districtListFetch: thunk(async (actions) => {
		const response = await fetchDistrictList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setdistrictList(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	thanaListFetch: thunk(async (actions, id) => {
		const response = await fetchThanaList(id);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setthanaList(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	setdistrictList: action((state, payload) => {
		state.districtList = payload;
	}),
	setthanaList: action((state, payload) => {
		state.thanaList = payload;
	}),

	fetchpartnerProfile: thunk(async (actions) => {
		const response = await fetchpartnerProfile();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setpartnerProfile(body?.item);
		} else if (response.status === 401) {
			notification['error']({
				message: 'Session',
				description: "Session has expired",
			});
			setTimeout(() => {
				localStorage.clear();
				window.location.reload()
			}, 500);
		} else {
			notification['error']({
				message: 'Sheba Suite',
				description: "Something went wrong",
			});
		}
	}),
	fetchpartnerProfile2: thunk(async (actions) => {
		const response = await fetchpartnerProfile();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setpartnerProfile(body?.item);
		} else if (response.status === 401) {
			const jwt: any = localStorage.getItem("jwt");
			if (jwt) {
				notification['error']({
					message: 'Session',
					description: "Session has expired",
				});
				setTimeout(() => {
					localStorage.removeItem("jwt");
					window.location.reload()
				}, 500);
			}


		}
	}),
	setpartnerProfile: action((state, payload) => {
		state.partnerProfile = payload;
	}),

	publicclientList: null,
	fetchpublicclientList: thunk(async (actions, payload) => {
		const response = await fetchpublicclientList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();

			actions.setpublicclientList(body?.item);
		}
	}),
	setpublicclientList: action((state, payload) => {
		state.publicclientList = payload;
	}),


	clientTansactionSummary: null,
	fetchclientTansactionSummary: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchclientTansactionSummary(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setclientTansactionSummary(body?.item);
			} else {
				notification['error']({
					message: 'Sheba Payment',
					description: 'No data found',
				});
				actions.setclientTansactionSummary(null)
			}

		} else {
			actions.setclientTansactionSummary(null);
		}
	}),
	setclientTansactionSummary: action((state, payload) => {
		state.clientTansactionSummary = payload;
	}),
	//
	clientTansactionVendorSummary: null,
	fetchclientTansactionVendorSummary: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchclientTansactionVendorSummary(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setclientTansactionVendorSummary(body?.item);
			} else {
				notification['error']({
					message: 'Sheba Payment',
					description: 'No data found',
				});
				actions.setclientTansactionVendorSummary(null)
			}

		} else {
			actions.setclientTansactionVendorSummary(null);
		}
	}),
	setclientTansactionVendorSummary: action((state, payload) => {
		state.clientTansactionVendorSummary = payload;
	}),
	//
	dateToDateSingleClientTansactionDetails: null,
	fetchdateToDateSingleClientTansactionDetails: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchdateToDateSingleClientTansactionDetails(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setdateToDateSingleClientTansactionDetails(body?.item);
			} else {
				notification['error']({
					message: 'Sheba Payment',
					description: 'No data found',
				});
				actions.setdateToDateSingleClientTansactionDetails(null)
			}

		} else {
			actions.setdateToDateSingleClientTansactionDetails(null);
		}
	}),
	setdateToDateSingleClientTansactionDetails: action((state, payload) => {
		state.dateToDateSingleClientTansactionDetails = payload;
	}),
	//
	dateToDateTansactionDetails: null,
	fetchdateToDateTansactionDetails: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchdateToDateTansactionDetails(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setdateToDateTansactionDetails(body?.item);
			} else {
				notification['error']({
					message: 'Sheba Payment',
					description: 'No data found',
				});
				actions.setdateToDateTansactionDetails(null)
			}

		} else {
			actions.setdateToDateTansactionDetails(null);
		}
	}),
	setdateToDateTansactionDetails: action((state, payload) => {
		state.dateToDateTansactionDetails = payload;
	}),
	//
	singleClientTansactionSummary: null,
	fetchsingleClientTansactionSummary: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchsingleClientTansactionSummary(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setsingleClientTansactionSummary(body?.item);
			} else {
				notification['error']({
					message: 'Sheba Payment',
					description: 'No data found',
				});
				actions.setsingleClientTansactionSummary(null)
			}

		} else {
			actions.setsingleClientTansactionSummary(null);
		}
	}),
	setsingleClientTansactionSummary: action((state, payload) => {
		state.singleClientTansactionSummary = payload;
	}),
	//
	singleClientVendorTansactionSummary: null,
	fetchsingleClientVendorTansactionSummary: thunk(async (actions, payload) => {
		// console.log(payload)
		const response = await fetchsingleClientVendorTansactionSummary(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item.length > 0) {
				actions.setsingleClientVendorTansactionSummary(body?.item);
			} else {
				notification['error']({
					message: 'Sheba Payment',
					description: 'No data found',
				});
				actions.setsingleClientVendorTansactionSummary(null)
			}

		} else {
			actions.setsingleClientVendorTansactionSummary(null);
		}
	}),
	setsingleClientVendorTansactionSummary: action((state, payload) => {
		state.singleClientVendorTansactionSummary = payload;
	}),
	//
	schoolOfpsConfigurationList: [],
	fetchschoolOfpsConfigurationList: thunk(async (actions, payload) => {
		let myHeaders = new Headers();
		myHeaders.append("username", "shebasuite");
		myHeaders.append("password", "shebasuite1432");

		let requestOptions: any = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		const BASE_URL_SCHOOL = process.env.REACT_APP_API_SHEBASHIKKHA
		fetch(BASE_URL_SCHOOL + '/public/ofps/configuration/list', requestOptions)
			.then(response => response.json())
			.then(body =>
				actions.setchoolOfpsConfigurationList(body?.item)
			)
			.then(result => console.log(result))
			.catch(error => console.log('error', error));
	}),

	setchoolOfpsConfigurationList: action((state, payload) => {
		state.schoolOfpsConfigurationList = payload;
	}),

	updateOfpsConfigurationAmount: thunk(async (actions, payload: any) => {
		let myHeaders = new Headers();
		myHeaders.append("username", "shebasuite");
		myHeaders.append("password", "shebasuite1432");

		let requestOptions: any = {
			method: 'POST',
			headers: myHeaders,
			redirect: 'follow'
		};
		const BASE_URL_SCHOOL = process.env.REACT_APP_API_SHEBASHIKKHA
		fetch(BASE_URL_SCHOOL + `/public/ofps/configuration/amount/update?configId=${payload?.configId}&serviceCharge=${payload?.serviceCharge}&step=${payload?.step}`, requestOptions)
			.then(response => response.json())
			.then(body => {
				actions.fetchschoolOfpsConfigurationList();
				if (body?.messageType === 1) {
					notification['success']({
						message: 'Bill',
						description: body?.message,
					});
				}
			}
			)
			.then(result =>
				console.log(result))
			.catch(error => {
				notification['error']({
					message: 'Bill',
					description: "Something went wrong",
				});
				console.log('error', error)
			});
	}),
	univeristyOfpsConfigurationList: [],
	fetchuniveristyOfpsConfigurationList: thunk(async (actions, payload) => {
		let myHeaders = new Headers();
		myHeaders.append("username", "shebasuite");
		myHeaders.append("password", "shebasuite1432");

		let requestOptions: any = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		const BASE_URL_UNIVERISTY = process.env.REACT_APP_API_SHEBASHIKKHA_UNI
		fetch(BASE_URL_UNIVERISTY + '/public/ofps/configuration/list', requestOptions)
			.then(response => response.json())
			.then(body =>
				actions.setuniveristyOfpsConfigurationList(body?.item)
			)
			.then(result => console.log(result))
			.catch(error => console.log('error', error));
	}),

	setuniveristyOfpsConfigurationList: action((state, payload) => {
		state.univeristyOfpsConfigurationList = payload;
	}),

	updateOfpsConfigurationAmount2: thunk(async (actions, payload: any) => {
		let myHeaders = new Headers();
		myHeaders.append("username", "shebasuite");
		myHeaders.append("password", "shebasuite1432");

		let requestOptions: any = {
			method: 'POST',
			headers: myHeaders,
			redirect: 'follow'
		};
		const BASE_URL_UNIVERISTY = process.env.REACT_APP_API_SHEBASHIKKHA_UNI
		fetch(BASE_URL_UNIVERISTY + `/public/ofps/configuration/amount/update?configId=${payload?.configId}&serviceCharge=${payload?.serviceCharge}&step=${payload?.step}`, requestOptions)
			.then(response => response.json())
			.then(body => {
				actions.fetchuniveristyOfpsConfigurationList();
				if (body?.messageType === 1) {
					notification['success']({
						message: 'Bill',
						description: body?.message,
					});
				}
			}
			)
			.then(result =>
				console.log(result))
			.catch(error => {
				notification['error']({
					message: 'Bill',
					description: "Something went wrong",
				});
				console.log('error', error)
			});
	}),

}
