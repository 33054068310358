import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, SaveOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectPartner } from '../../select/SelectIPartner'
import { SelectMonth } from '../../select/SelectMonth';
import { SelectYear } from '../../select/SelectYear';
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactExport from "react-export-excel";
import { SelectIPartnerForIsp } from '../../select/SelectIPartnerForIsp';

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;
const { Option } = Select;


export default function IspBillListSDL(props) {

    const check = useStoreState(state => state.isp.check);
    const ispClientBillInfoList = useStoreState(state => state.isp.ispClientBillInfoList);
    const fetchispClientBillInfoList = useStoreActions(state => state.isp.fetchispClientBillInfoList);
    const sendSmsUnpaidIspClient = useStoreActions(state => state.isp.sendSmsUnpaidIspClient);
    const fetchpartnerListForIsp = useStoreActions((state) => state.partner.fetchpartnerListForIsp);


    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const downloadVoucher = useStoreActions(state => state.isp.downloadVoucher);
    const columns1 = [

        {
            title: 'Client Id',
            dataIndex: 'clientCustomId',
            key: 'clientCustomId',


        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',


        },
        {
            title: 'Mobile No',
            dataIndex: 'clientMobile',
            key: 'clientMobile',


        },
        {
            title: 'Address',
            dataIndex: 'mailingAddress',
            key: 'mailingAddress',

        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Package Price',
            dataIndex: 'billAmount',
            key: 'billAmount',

        },
        {
            title: 'Payment Date',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    {record?.billPaymentDate === "null" ? 'N/A' : record?.billPaymentDate}

                </Space>
            ),
        },
        {
            title: 'Download Report',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Button icon={<CloudDownloadOutlined />}
                        type="primary"
                        onClick={() => {
                            let postData = {
                                billId: record.billId
                            }
                            downloadVoucher(postData)
                        }}
                    >
                        Download</Button>
                </Space>
            ),
        },

    ]


    const columns2 = [

        {
            title: 'Client Id',
            dataIndex: 'clientCustomId',
            key: 'clientCustomId',


        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',


        },
        {
            title: 'Mobile No',
            dataIndex: 'clientMobile',
            key: 'clientMobile',


        },
        {
            title: 'Address',
            dataIndex: 'mailingAddress',
            key: 'mailingAddress',

        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Package Price',
            dataIndex: 'billAmount',
            key: 'billAmount',
        },

        {
            title: 'Payable Date',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    {record?.billPayableDate === "null" ? 'N/A' : record?.billPayableDate}

                </Space>
            ),
        },
        {
            title: 'Download Report',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Button icon={<CloudDownloadOutlined />}
                        type="primary"
                        onClick={() => {
                            let postData = {
                                billId: record.billId
                            }
                            downloadVoucher(postData)
                        }}
                    >
                        Download</Button>
                </Space>
            ),
        },


    ]
    const [colData, setcolData] = useState<any>([])
    const [colDataPdf, setcolDataPdf] = useState<boolean>(false)


    const onFinish = (values: any) => {
        if (values.billStatus == 1) {
            setcolData(columns1)
            setcolDataPdf(true)
        }

        if (values.billStatus == 0) {
            setcolData(columns2)
            setcolDataPdf(false)
        }

        let postData: any = {
            billStatus: values.billStatus,
            partnerId: values.partnerId.value,
            billMonth: values.billMonth,
            billYear: values.billYear,
        }
        console.log(values)
        setfilterTable(null);
        setsearchv('')
        fetchispClientBillInfoList(postData)
    };


    useEffect(() => {
        setPageTitle();
        fetchpartnerListForIsp("ISP");
    }, []);


    function downloadPdf() {

        const doc = new jsPDF("landscape");
        var totalPagesExp = '{total_pages_count_string}';
        const tableRows: any[][] = [];
        var width = doc.internal.pageSize.getWidth()
        doc.text('Sheba Technologies Limited (STL)', width / 2, 20, { align: 'center' })
        doc.setFontSize(10)
        doc.text(`${colDataPdf ? "Paid" : "Unpaid"} Bill Report for the Month of ${month},${year}`, width / 2, 30, { align: 'center' })
        // doc.setFontSize(10)
        doc.text(`POP Manager Name:${partnerId?.label}`, width / 2, 35, { align: 'center' })
        let totalBill = 0
        ispClientBillInfoList.forEach((item: any) => {
            const itemData = [
                item.clientCustomId,
                item.clientName,
                item.clientMobile,
                item.mailingAddress,
                item.packageName,
                item.billAmount,
                colDataPdf ? item.billPaymentDate : item.billPayableDate,


            ];
            totalBill += item.billAmount
            tableRows.push(itemData);
        });


        // @ts-ignore


        doc.autoTable(colDataPdf ? ["Client Id", "Client Name", "Mobile No", "Address", "Package Name", "Payment Price", "Payment Date"] : ["Client Id", "Client Name", "Mobile No", "Address", "Package Name", "Payment Price", "Payable Date"], tableRows, {
            startY: 40,
            pageBreak: "auto",
            rowPageBreak: "auto",
            theme: "plain",
            didDrawPage: function (data) {
                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                doc.setTextColor(150)
                doc.setFontSize(10)
                doc.text('Powered by Sheba Digital Limited | Part of Sheba Group.', width / 2 - 40, pageHeight - 10);
            }

        });
        let finalY = (doc as any).lastAutoTable.finalY;
        doc.setFontSize(11);
        doc.text(`Total Bill Amount: ${totalBill} `, width - 70, finalY + 10)


        doc.save(`Bill.pdf`);
    }



    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [row, setRow] = useState<any>();



    const [partnerId, setPartnerId] = useState<any>(null);
    const [month, setMonth] = useState<any>(null);
    const [year, setYear] = useState<any>(null);

    const onchnagePartner = (val) => {
        setPartnerId(val)
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const sendSms = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({ message: 'Select the table row first' });
            return;
        }
        let data = selectedValue?.map((item, key) => {
            return {
                billAmount: item.billAmount,
                billGenerateDate: item.billGenerateDate,
                billId: item.billId,
                billPayableDate: item.billPayableDate,
                billingMonth: item.billingMonth,
                billingYear: item.billingYear,
                clientCustomId: item.clientCustomId,
                clientId: item.clientId,
                clientMobile: item.clientMobile,
                clientName: item.clientName,
                mailingAddress: item.mailingAddress,
                packageName: item.packageName,

            }
        })
        sendSmsUnpaidIspClient({
            "ispClientBillInfoResponseList": data,
            "paymentStatus": 0
        })
        setselectedRowKeys([]);
        setselectedValue([]);
    }

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = ispClientBillInfoList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)

    };


    return (
        <Card title="Bill List" >
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} >

                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="billStatus"
                            label="Bill Status:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select bill status" },
                            ]}
                        >
                            <Select placeholder="Select Bill Status" style={{ width: "100%" }} allowClear>
                                <Option value="1">Paid</Option>
                                <Option value="0">Unpaid</Option>
                                <Option value="2">Disabled</Option>
                            </Select>


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="partnerId"
                            label="Partner:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select bill status" },
                            ]}
                        >
                            <SelectIPartnerForIsp onChange={(val) => onchnagePartner(val)} selected={partnerId} name="PIP" level={2} />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="billMonth"
                            label="Month:"
                            className="title-Text"
                            id="selectMonth"
                            rules={[
                                { required: true, message: "Please select month" },
                            ]}
                        >
                            <SelectMonth onChange={(val) => setMonth(val)} selected={month} />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="billYear"
                            label="Year:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year" },
                            ]}
                        >
                            <SelectYear onChange={(val) => setYear(val)} selected={year} />


                        </Form.Item>

                    </Col>

                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>

                </Row>
            </Form>
            {ispClientBillInfoList?.length > 0 &&
                < >
                    <span>Total Found: {ispClientBillInfoList !== null ? ispClientBillInfoList?.length : 0}</span>
                    <Input
                        style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                        placeholder="Search..."
                        value={searchv}
                        allowClear
                        onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                    />

                    <Table
                        rowSelection={rowSelection}
                        rowKey="clientCustomId"
                        bordered={true}
                        // pagination={false}
                        dataSource={searchv === '' ? ispClientBillInfoList : filterTable}
                        columns={colData}
                        summary={pageData => {
                            let totalBill = 0;

                            ispClientBillInfoList.forEach(({ billAmount }) => {
                                totalBill += billAmount;
                            });

                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>
                                            <Text type="danger" strong>{totalBill}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>

                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}>

                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>

                                </>
                            );
                        }}

                    />
                    <Space style={{ float: 'right' }} size="middle">
                        <ExcelFile element={<Button type="primary" icon={<FileExcelOutlined />} > Download Excel </Button>}>
                            <ExcelSheet data={ispClientBillInfoList} name="Bill">
                                <ExcelColumn label="Client Id" value="clientCustomId" />
                                <ExcelColumn label="Client Name" value="clientName" />
                                <ExcelColumn label="Mobile No" value="clientMobile" />
                                <ExcelColumn label="Address" value="mailingAddress" />
                                <ExcelColumn label="Package Name" value="packageName" />
                                <ExcelColumn label="Package Price" value="billAmount" />
                                {colDataPdf ? <ExcelColumn label="Payment Date" value="billPaymentDate" /> : <ExcelColumn label="Payable Date" value="billPayableDate" />}
                            </ExcelSheet>
                        </ExcelFile>
                        <Button type="primary" icon={<FilePdfOutlined />} onClick={downloadPdf} > Download PDF </Button>
                        <Button type="primary" onClick={() => sendSms()}>Send SMS</Button>
                    </Space>
                </>

            }

        </Card>
    )
}
