import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
import { BANK_LIST } from './banks'
const { Option } = Select;


export interface SelectBank {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}


export const SelectBank = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectBank) => {



  const onSelect = (name) => {
    if (BANK_LIST) {
      const val: any = BANK_LIST.find((item) => item.BankName === name);
      onChange(val);
    }
  };

  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Bank"
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {BANK_LIST ? (
        BANK_LIST.map((type, idx) => (
          <Option key={type.BankName} value={type.BankName}>
            {type.BankName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching banks</Option>
      )}
    </Select>
  );
};
