import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectIPartnerForIsp {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  name?: any;
  level?: any;
  style?: any
}

export const SelectIPartnerForIsp = ({
  onChange,
  selected,
  defaultSelected,
  name,
  level,
  style
}: SelectIPartnerForIsp) => {
  const itemList = useStoreState((state) => state.partner.partnerListForIsp);
  // const itemList = [
  //   {
  //     id: 'Fast',
  //     value: 'Fast'
  //   },
  //   {
  //     id: 'Ultra',
  //     value: 'Ultra'
  //   },
  // ];
  const itemListFetch = useStoreActions((state) => state.partner.fetchpartnerListForIsp);
  // const itemFetch = useStoreActions((state) => state.common.itemFetch);

  if (itemList === null) {
    itemListFetch(level);
  }

  const onSelect = (id) => {
    if (itemList) {
      const val = itemList.find((item) => item.value === id);
      onChange(val);
    }
  };




  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected?.value}
      style={style}
      placeholder={`Select ${name}`}
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.value} value={type.value}>
            {type.label}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Partner</Option>
      )}
    </Select>
  );
};
