import { message, notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { fetchproductList, fetchclienHalfYearlyUrl, fetchpackageListByProductId, postsaveProductOrder, fetchpendingProductOrderList, deleteProductOrder, approveProductOrder, preApproveProductOrder, fetchpreApproveProductOrderList, fetchclientList, fetchpendingApproveProductOrderList, fetchproductOrderList, downloadAggrementCopyUrl, updatePendingDataUrl, updatePreApproveDataUrl, fetchclientHalfYearlyDetailsUrl, fetchInstituteListByProductCustomIdUrl } from '../../../http/partner/order';
import FileSaver from 'file-saver';

function b64toFile(b64Data, filename, contentType) {
    var sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays:any = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);

        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var file = new File(byteArrays, filename, {type: contentType});
    return file;
}

export interface Order {
    productList: any,
    setproductList: Action<Order, any>;
    fetchproductList: Thunk<Order>;
    clientList: any,
    setclientList: Action<Order, any>;
    fetchclientList: Thunk<Order, any>;
    packageListByProductId: any,
    setpackageListByProductId: Action<Order, any>;
    fetchpackageListByProductId: Thunk<Order>;
    selectedCat: any,
    setselectedCat: Action<Order, any>;
    postsaveProductOrder: Thunk<Order, any>;
    orderStatus: boolean;
    pendingProductOrderList: any,
    setpendingProductOrderList: Action<Order, any>,
    fetchpendingProductOrderList: Thunk<Order>;
    preApproveProductOrderList: any,
    setpreApproveProductOrderListt: Action<Order, any>,
    fetchpreApproveProductOrderList: Thunk<Order>;
    deleteProductOrder: Thunk<Order, any>;
    approveProductOrder: Thunk<Order, any>;
    preApproveProductOrderPost: Thunk<Order, any>;
    ordersuccess: boolean,
    setordersuccess: Action<Order, any>

    clientHalfYearly: any,
	setclientHalfYearly: Action<Order, any>;
	fetchclienHalfYearly: Thunk<Order, any>;

    pendingApproveProductOrderList: any,
    setpendingApproveProductOrderList: Action<Order, any>;
    fetchpendingApproveProductOrderList: Thunk<Order, any>;

    loading: boolean,
    setLoading: Action<Order, any>;

    productOrderList: any,
    setproductOrderList: Action<Order, any>;
    fetchproductOrderList: Thunk<Order, any>;

    downloadAggrementCopy: Thunk<Order, any>

    pendingOrderInfoUpdate: Thunk<Order, any>;

    preApproveOrderInfoUpdate: Thunk<Order, any>;

    billGenerateInstituteList: any,
	setBillGenerateInstituteList: Action<Order, any>;
	fetchBillGenerateInstituteList: Thunk<Order, any>;

}


export const orderStore: Order = {
    ordersuccess: false,
    setordersuccess: action((state, payload) => {
        state.ordersuccess = payload;
    }),
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
    productList: [],
    fetchproductList: thunk(async (actions) => {
        const response = await fetchproductList();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                actions.setproductList(body?.item);
            } else {
                notification['error']({
                    message: 'Order Status',
                    description: body?.message,
                });
            }

        } else {
            actions.setproductList([]);
        }
    }),
    setproductList: action((state, payload) => {
        state.productList = payload;
    }),
    ///*******/////
    pendingApproveProductOrderList: [],
    fetchpendingApproveProductOrderList: thunk(async (actions, payload) => {
        const response = await fetchpendingApproveProductOrderList(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length>0){
                actions.setpendingApproveProductOrderList(body?.item);
            }
            else {
                actions.setpendingApproveProductOrderList([]);
                notification.warn({message:"No data found"})
            }
        } else {
            actions.setpendingApproveProductOrderList([]);
        }
    }),
    setpendingApproveProductOrderList: action((state, payload) => {
        state.pendingApproveProductOrderList = payload;
    }),

    productOrderList: [],
    fetchproductOrderList: thunk(async (actions, payload) => {
        actions.setLoading(true)
        const response = await fetchproductOrderList(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false)
            const body = await response.json();
            if (body?.item?.length>0){
                actions.setproductOrderList(body?.item);
            }
            else {
                actions.setproductOrderList([]);
                notification.warn({message:"No data found"})
            }
        } else {
            actions.setLoading(false)
            actions.setproductOrderList([]);
            notification.warn({message:"No data found"})
        }
    }),
    setproductOrderList: action((state, payload) => {
        state.productOrderList = payload;
    }),
    ///*******/////
    clientList: [],
    fetchclientList: thunk(async (actions, payload) => {
        const response = await fetchclientList(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            // console.log(body);
            actions.setclientList(body?.item);
        } else {
            actions.setclientList([]);
        }
    }),
    setclientList: action((state, payload) => {
        state.clientList = payload;
    }),
    ///*******/////
    packageListByProductId: [],
    fetchpackageListByProductId: thunk(async (actions, payload) => {
        const response = await fetchpackageListByProductId(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setpackageListByProductId(body?.item);
        } else {
            actions.setpackageListByProductId([]);
        }
    }),
    setpackageListByProductId: action((state, payload) => {
        state.packageListByProductId = payload;
    }),
    ///*******/////
    selectedCat: null,
    setselectedCat: action((state, payload) => {
        state.selectedCat = payload;
    }),

    ///*******/////
    orderStatus: false,

    postsaveProductOrder: thunk(async (actions, payload) => {
        const response = await postsaveProductOrder(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            console.log(body);
            if (body.messageType == 1) {
                notification['success']({
                    message: 'Order Form',
                    description: body?.message,
                });
                actions.setordersuccess(true);
            } else {
                notification['error']({
                    message: 'Order Form',
                    description: body?.message,
                });
                actions.setordersuccess(false);
            }
            // actions.setpackageListByProductId(body?.item);
        } else {

        }
    }),

    ///*******/////

    pendingProductOrderList: [],
    fetchpendingProductOrderList: thunk(async (actions) => {
        const response = await fetchpendingProductOrderList();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setpendingProductOrderList(body?.item);
        } else {
            actions.setpendingProductOrderList([]);
        }
    }),
    setpendingProductOrderList: action((state, payload) => {
        state.pendingProductOrderList = payload;
    }),
    ///*******/////

    ///*******/////

    preApproveProductOrderList: [],
    fetchpreApproveProductOrderList: thunk(async (actions) => {
        const response = await fetchpreApproveProductOrderList();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            console.log(body);
            actions.setpreApproveProductOrderListt(body?.item);
        } else {
            actions.setpreApproveProductOrderListt([]);
        }
    }),
    setpreApproveProductOrderListt: action((state, payload) => {
        state.preApproveProductOrderList = payload;
    }),
    ///*******/////
    deleteProductOrder: thunk(async (actions, payload) => {
        const response = await deleteProductOrder(payload);
        //console.log(response)
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();

            notification['success']({
                message: 'Order Delete',
                description: 'Selected partner request has been deleted successfully',
            });
            const newresponse = await fetchpendingProductOrderList();
            if (newresponse.status === 200) {
                const body: any = await newresponse.json();
                if (body.messageType == 1) {
                    actions.setpendingProductOrderList(body.item);
                }
                actions.fetchpreApproveProductOrderList();
            } else {
                //const body = await response.json();
                actions.setpendingProductOrderList([]);
            }

        } else {
            //const body = await response.json();
            notification['error']({
                message: 'Order Delete',
                description: 'Something went wrong ',
            });
            //	actions.setSignUpStatus(null);
        }
    }),
    ///*******/////
    approveProductOrder: thunk(async (actions, payload) => {
        const response = await approveProductOrder(payload);
        //console.log(response)
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification['success']({
                    message: 'Product Order',
                    description: body.message,
                })
            } else {
                notification['error']({
                    message: 'Product Order',
                    description: body.message,
                })
            }
            const newresponse = await fetchpreApproveProductOrderList();
            if (newresponse.status === 200) {
                const body: any = await newresponse.json();
                if (body.messageType == 1) {
                    actions.setpreApproveProductOrderListt(body.item);
                }
            } else {
                //const body = await response.json();
                actions.setpreApproveProductOrderListt([]);
            }

        } else {
            //const body = await response.json();
            notification['error']({
                message: 'Product Order',
                description: 'Something went wrong ',
            });
            //	actions.setSignUpStatus(null);
        }
    }),    ///*******/////
    preApproveProductOrderPost: thunk(async (actions, payload) => {
        const response = await preApproveProductOrder(payload);
        //console.log(response)
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            console.log(body);
            notification['success']({
                message: 'Product Order',
                description: "Selected product is now in preapprove-order list",
            })
            // if (body.messageType == 1) {
            //     notification['success']({
            //         message: 'Product Order',
            //         description: body.message,
            //     })
            // } else {
            //     notification['error']({
            //         message: 'Product Order',
            //         description: body.message,
            //     })
            // }
            const newresponse = await fetchpendingProductOrderList();
            if (newresponse.status === 200) {
                const body: any = await newresponse.json();
                if (body.messageType == 1) {
                    actions.setpendingProductOrderList(body.item);
                }
            } else {
                //const body = await response.json();
                actions.setpendingProductOrderList([]);
            }

        } else {
            //const body = await response.json();
            notification['error']({
                message: 'Product Order',
                description: 'Something went wrong ',
            });
            //	actions.setSignUpStatus(null);
        }
    }),
    downloadAggrementCopy: thunk(async (actions, payload) => {
		const response = await downloadAggrementCopyUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
            if (body?.messageType===1){
                let next = b64toFile(body?.item,'aggrement-copy.pdf','application/octet-stream' )
                var fileName = "aggrement-copy.pdf";
                FileSaver.saveAs(next, fileName);
                return;
            } else {
                notification.error({message:"No Data Found"})
            }

	
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

    pendingOrderInfoUpdate: thunk(async (actions, payload) => {
        const response = await updatePendingDataUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            console.log(body);
            if (body.messageType == 1) {
                notification['success']({
                    message: 'Order Form',
                    description: body?.message,
                });
                actions.setordersuccess(true);
                actions.fetchpendingProductOrderList();
            } else {
                notification['error']({
                    message: 'Order Form',
                    description: body?.message,
                });
                actions.setordersuccess(false);
            }
            // actions.setpackageListByProductId(body?.item);
        } else {

        }
    }),

    preApproveOrderInfoUpdate: thunk(async (actions, payload) => {
        const response = await updatePreApproveDataUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            console.log(body);
            if (body.messageType == 1) {
                notification['success']({
                    message: 'Order Form',
                    description: body?.message,
                });
                actions.setordersuccess(true);
                actions.fetchpreApproveProductOrderList()
            } else {
                notification['error']({
                    message: 'Order Form',
                    description: body?.message,
                });
                actions.setordersuccess(false);
            }
            // actions.setpackageListByProductId(body?.item);
        } else {

        }
    }),

    clientHalfYearly: [],
	fetchclienHalfYearly: thunk(async (actions, payload) => {
		const response = await fetchclienHalfYearlyUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();

			actions.setclientHalfYearly(body?.item);
		} else {
			actions.setclientHalfYearly([]);
		}
	}),
	setclientHalfYearly: action((state, payload) => {
		state.clientHalfYearly = payload;
	}),

    billGenerateInstituteList: [],
	fetchBillGenerateInstituteList: thunk(async (actions, payload) => {
		const response = await fetchInstituteListByProductCustomIdUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();           
			actions.setBillGenerateInstituteList(body?.item);
		} else {
			actions.setBillGenerateInstituteList([]);
		}
	}),
	setBillGenerateInstituteList: action((state, payload) => {
		state.billGenerateInstituteList = payload;
	}),
}
