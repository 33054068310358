import { DeleteOutlined, EditOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Table, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import xlsxParser from 'xlsx-parse-json';
import { SelectPartner } from '../../select/SelectIPartner';
import { SelectISPPackage } from '../../select/SelectISPPackage';
import { SelectIPartnerForIsp } from '../../select/SelectIPartnerForIsp';



export default function IspClientBulkAddFormPIP(props) {

    const [form] = Form.useForm();
    const saveIspClientInfoBulk = useStoreActions((state) => state.isp.saveIspClientInfoBulk);
    const [fileList, setFileList] = useState<any>([]);
    const [tableData, settableData] = useState<any>([]);
    const fetchpartnerListForIsp = useStoreActions((state) => state.partner.fetchpartnerListForIsp);

    const onChangeFile = ({ fileList: newFileList }) => {
        // console.log(newFileList);
        newFileList[0].status = "done"
        xlsxParser
            .onFileSelection(newFileList[0]?.originFileObj)
            .then(data => {
                let temp = data?.Sheet1?.map(function (item, index) {
                    return {
                        key: index,
                        clientCustomId: item['Client Id'],
                        clientName: item['Client Name'],
                        mobileNo: item['Mobile'],
                        mailingAddress: item["Address"],
                    }
                })
                settableData(temp)
            });

    };

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };



    const columns = [
        {
            title: 'Client Id',
            dataIndex: 'clientCustomId',
            key: 'clientCustomId',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobileNo',
            key: 'mobileNo',
        },
        {
            title: "Address",
            dataIndex: 'mailingAddress',
            key: 'mailingAddress',
        }
    ];

    const [partnerId, setPartnerId] = useState<any>(null);
    const onchnagePartner = (val) => {
        setPartnerId(val)
    };
    const [ispPackage, setispPackage] = useState<any>(null);

    const onchangeIspPackage = (val) => {
        console.log(val)
        setispPackage(val)
    }

    useEffect(() => {
        fetchpartnerListForIsp("ISP");
    },[])

    return (
        <>
            <Card title="Bulk Client Add" >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" title="Partner:">Partner</label></div>
                        <SelectIPartnerForIsp onChange={(val) => onchnagePartner(val)} selected={partnerId} name="PIP" level={2} style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" title="Package:">Package</label></div>
                        <SelectISPPackage onChange={(val) => onchangeIspPackage(val)} selected={ispPackage} style={{ width: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div style={{ marginTop: 30 }}>
                            <Upload
                                listType="text"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                fileList={fileList}
                                onChange={onChangeFile}
                                style={{ maxWidth: 220, }}

                            >
                                {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload Excel File</Button></>}
                            </Upload>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                        <Button style={{ marginTop: 30 }} type="primary" onClick={() => window.open('https://res.cloudinary.com/dnyoqhb7g/raw/upload/v1642593824/ISP/IspClientRegistration_e2km3o.xlsx', '_blank')}>Download Sample File</Button>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={1} xl={1}> </Col>

                </Row>
                <br />
                {tableData?.length > 0 &&
                    <>

                        <Table bordered={true} dataSource={tableData} columns={columns} />
                        <br />
                        <Button style={{ float: "right" }} type="primary" onClick={() => {
                            if (partnerId === null) {
                                notification.error({ message: "Please Select Partner" });
                                return;
                            }
                            if (ispPackage === null) {
                                notification.error({ message: "Please Select ISP Package" });
                                return;
                            }
                            if (tableData.length === 0) {
                                notification.error({ message: "Please Upload Excel File" });
                                return;
                            }
                            let payload = {
                                "ispClientInfoBasicRequestList": tableData,
                                "packageId": ispPackage?.packageId,
                                "partnerId": partnerId?.value
                            }
                            saveIspClientInfoBulk(payload);
                            setPartnerId(null);
                            setispPackage(null);
                            settableData([]);
                        }}>Upload</Button>
                    </>

                }
            </Card>
        </>
    )
}
