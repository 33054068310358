import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectBillCycleByValue {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectBillCycleByValue = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectBillCycleByValue) => {
  //const itemList = useStoreState((state) => state.common.districtList);
  const itemList = [
    {
      id: 'Monthly',
      value: 'MONTHLY'
    },
    {
      id: 'HALF_YEARLY',
      value: 'HALF_YEARLY'
    },
    {
      id: 'YERLY',
      value: 'YERLY'
    }
  ];
  // const itemListFetch = useStoreActions((state) => state.common.itemListFetch);
  // const itemFetch = useStoreActions((state) => state.common.itemFetch);
  // if (itemList === null) {
  //   itemListFetch();
  // }

  const onSelect = (id) => {
    if (itemList) {
      const val:any = itemList.find((item) => item.id === id);
      onChange(val.value);
    }
  };




  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Bill Cycle"
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.id} value={type.id}>
            {type.value}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Bill Cycle</Option>
      )}
    </Select>
  );
};
