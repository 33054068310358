import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { pendingList, partnerSignup, fetchPendingList, fetchpartnerListByLevel, partnerAssign, applicantPartnerDelete, fetchpartnerList, fetchpartnerProfile, fetchpartnerListByLevelOrigin, partnerPendingUpdateUrl, fetchpartnerApproveListUrl, fetchUnderDesignationListUrl, pendingPartnerAssignUrl, pendingPartnerAssignUpdateUrl, updatePendingPartnerUrl, fetchpartnerListForIspUrl, fetchOrderListByStatusUrl, fetchOrderListByPartnerWiseUrl } from '../../../http/partner/partner';

export interface Partner {
	pendingList: any,
	signUpStatus: any,
	underPartnerList: any,
	setUnderPartner: Action<Partner, any>;
	originPartnerList: any,
	setoriginPartnerList: Action<Partner, any>;
	setSignUpStatus: Action<Partner, any>;
	setPendingListItem: Action<Partner, any>;
	partnerSignup: Thunk<Partner, any>;
	partnerAssign: Thunk<Partner, any>;
	fetchPendingList: Thunk<Partner>;
	fetchpartnerListByLevel: Thunk<Partner>;
	fetchpartnerListByLevelOrigin: Thunk<Partner, any>;
	applicantPartnerDelete: Thunk<Partner, any>;

	partnerList: any,
	setpartnerList: Action<Partner, any>;
	fetchpartnerList: Thunk<Partner, any>;

	approvePartnerList: any,
	setApprovePartnerList: Action<Partner, any>;
	fetchApprovePartnerList: Thunk<Partner, any>;

	approvePartnerListForProductOrder: any,
	setApprovePartnerListForProductOrder: Action<Partner, any>;
	fetchApprovePartnerListForProductOrder: Thunk<Partner, any>;

	orderListByStatus: any,
	setOrderListByStatus: Action<Partner, any>;
	fetchOrderListByStatus: Thunk<Partner, any>;

	partnerProfile: any,
	setpartnerProfile: Action<Partner, any>;
	fetchpartnerProfile: Thunk<Partner, any>;

	underDesignationList: any,
	setUnderDesignationList: Action<Partner, any>;
	fetchunderDesignationList: Thunk<Partner, any>;

	updatePartnerDetails: Thunk<Partner, any>

	pendingPartnerAssign: Thunk<Partner, any>,
	pendingPartnerAssignUpdate: Thunk<Partner, any>,

	pendingPartnerUpdate: Thunk<Partner, any>,

	partnerListForIsp: any,
	setpartnerListForIsp: Action<Partner, any>;
	fetchpartnerListForIsp: Thunk<Partner, any>;

	partnerwiseProductOrderList: any,
	setPartnerwiseProductOrderList: Action<Partner, any>;
	fetchPartnerwiseProductOrderList: Thunk<Partner, any>;
}

export const partnerStore: Partner = {
	signUpStatus: false,
	pendingList: [],
	underPartnerList: [],
	originPartnerList: null,
	approvePartnerList:[],
	underDesignationList:[],
	partnerListForIsp:[],
	partnerSignup: thunk(async (actions, payload) => {
		const response = await partnerSignup(payload);
		//console.log(response)
		if (response.status === 201) {
			const body: any = await response.json();
			
			if (body.messageType == 1) {
				actions.setSignUpStatus(true);
			}
			notification.success({ message: body.message });
		} else {
			//const body = await response.json();
			actions.setSignUpStatus(null);
		}
	}),
	fetchPendingList: thunk(async (actions, payload) => {
		const response = await fetchPendingList();
		if (response.status === 200) {
			const body: any = await response.json();
			if (body.messageType == 1) {
				actions.setPendingListItem(body.item);
				console.log('fetchPendingList');
				
			}
		} else {
			//const body = await response.json();
			actions.setPendingListItem([]);
		}
	}),
	fetchpartnerListByLevel: thunk(async (actions, payload) => {
		const response = await fetchpartnerListByLevel(payload);
		if (response.status === 200) {
			const body: any = await response.json();
			if (body.messageType == 1) {

				let value = body.item?.map(function (item) {
					return {
						value: item?.partnerId,
						label: item?.partnerName
					}
				})
				actions.setUnderPartner(value);
			}
		} else {
			//const body = await response.json();
			actions.setUnderPartner([]);
		}
	}),	
	
	fetchpartnerListForIsp: thunk(async (actions, payload) => {
		const response = await fetchpartnerListForIspUrl(payload);
		if (response.status === 200) {
			const body: any = await response.json();
		//	console.log(body)
			if (body.messageType == 1) {

				let value = body.item?.map(function (item) {
					return {
						value: item?.partnerId,
						label: item?.partnerName
					}
				})
				actions.setpartnerListForIsp(value);
			}
		} else {
			//const body = await response.json();
			actions.setpartnerListForIsp([]);
		}
	}),
	setpartnerListForIsp: action((state, payload) => {
		state.partnerListForIsp = payload;
	}),	
	fetchpartnerListByLevelOrigin: thunk(async (actions, payload) => {
		const response = await fetchpartnerListByLevelOrigin(payload);
		if (response.status === 200) {
			const body: any = await response.json();
		//	console.log(body)
			if (body.messageType == 1) {

				let value = body.item?.map(function (item) {
					return {
						value: item?.partnerId,
						label: item?.partnerName
					}
				})
				actions.setoriginPartnerList(value);
			}
		} else {
			//const body = await response.json();
			actions.setoriginPartnerList([]);
		}
	}),
	partnerAssign: thunk(async (actions, payload) => {
		const response = await partnerAssign(payload);
		//console.log(response)
		if (response.status === 201 || response.status === 200) {
			const body: any = await response.json();

			notification['success']({
				message: 'Partner Assign',
				description: 'Selected partner has been assigned successfully',
			});
			actions.fetchPendingList();
		} else {
			//const body = await response.json();
			notification['error']({
				message: 'Partner Assign',
				description: 'Something went wrong ',
			});
			//	actions.setSignUpStatus(null);
		}
	}),

	applicantPartnerDelete: thunk(async (actions, payload) => {
		const response = await applicantPartnerDelete(payload);
		//console.log(response)
		if (response.status === 201 || response.status === 200) {
			const body: any = await response.json();

			notification['success']({
				message: 'Partner Assign',
				description: 'Selected partner request has been deleted successfully',
			});
			// const newresponse = await fetchPendingList();
			// if (newresponse.status === 200) {
			// 	const body: any = await newresponse.json();
			// 	if (body.messageType == 1) {
			// 		actions.setPendingListItem(body.item);
					
			// 	}
			// } else {
			// 	//const body = await response.json();
			// 	actions.setPendingListItem([]);
			// }
			actions.fetchPendingList();
			console.log('applicantPartnerDelete', applicantPartnerDelete);
			
		} else {
			//const body = await response.json();
			notification['error']({
				message: 'Partner Assign',
				description: 'Something went wrong ',
			});
			//	actions.setSignUpStatus(null);
		}
	}),


	setUnderPartner: action((state, payload) => {
		state.underPartnerList = payload;
	}),	
	setoriginPartnerList: action((state, payload) => {
		state.originPartnerList = payload;
	}),
	setSignUpStatus: action((state, payload) => {
		state.signUpStatus = payload;
	}),
	setPendingListItem: action((state, payload) => {
		state.pendingList = payload;
	}),

	fetchApprovePartnerList: thunk(async (actions, payload) => {
		const response = await fetchpartnerApproveListUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				if(body?.item.length == []){
					notification['error']({
						message: 'Partner List',
						description: 'No Data Found',
					});
				}else{
					actions.setApprovePartnerList(body?.item);
				}
                
            } else {
                notification['error']({
                    message: 'Partner List',
                    description: body?.message,
                });
                actions.setpartnerList([]);
            }
			//const body = await response.json();
			
		} else {
			actions.setpartnerList([]);
		}
	}),

	setApprovePartnerList: action((state, payload) => {
		state.approvePartnerList = payload;
	}),

	partnerList: [], 
	fetchpartnerList: thunk(async (actions, payload) => {
		const response = await fetchpartnerList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
                // notification['success']({
                //     message: 'Partner List',
                //     description: body?.message,
                // });
                actions.setpartnerList(body?.item);
            } else {
                notification['error']({
                    message: 'Partner List',
                    description: body?.message,
                });
                actions.setpartnerList([]);
            }
			//const body = await response.json();
			
		} else {
			actions.setpartnerList([]);
		}
	}),
	
	
	setpartnerList: action((state, payload) => {
		state.partnerList = payload;
	}),

	partnerProfile: {},
	fetchpartnerProfile: thunk(async (actions, payload) => {
		const response = await fetchpartnerProfile(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			console.log(body)
			if (body.messageType == 1) {
                actions.setpartnerProfile(body?.item);
            } else {
                notification['error']({
                    message: 'Partner List',
                    description: body?.message,
                });
                actions.setpartnerProfile({});
            }
			//const body = await response.json();
			
		} else {
			actions.setpartnerProfile({});
		}
	}),
	setpartnerProfile: action((state, payload) => {
		state.partnerProfile = payload;
	}),

	updatePartnerDetails:thunk(async (actions, payload) => {
		const response = await partnerPendingUpdateUrl(payload);
		if (response.status === 201) {
			const body: any = await response.json();
			notification.success({ message: body.message });
		} else {
			//const body = await response.json();
		}
	}),

	fetchunderDesignationList: thunk(async (actions, payload) => {
		const response = await fetchUnderDesignationListUrl(payload);
		if (response.status === 200) {
			const body: any = await response.json();
			if (body.messageType == 1) {
				actions.setUnderDesignationList(body.item);
				console.log('body.item', body.item);
				
			}
		} else {
			//const body = await response.json();
			actions.setUnderDesignationList([]);
		}
	}),
	setUnderDesignationList: action((state, payload) => {
		state.underDesignationList = payload;
	}),

	pendingPartnerAssign:thunk(async (actions, payload) => {
		const response = await pendingPartnerAssignUrl(payload);
		if (response.status === 201) {
			const body: any = await response.json();
			notification.success({ message: body.message });
			actions.fetchPendingList();
		} else {
			//const body = await response.json();
		}
	}),

	pendingPartnerAssignUpdate:thunk(async (actions, payload) => {
		const response = await pendingPartnerAssignUpdateUrl(payload);
		if (response.status === 201) {
			const body: any = await response.json();
			notification.success({ message: body.message });
		} else {
			//const body = await response.json();
		}
	}),

	pendingPartnerUpdate: thunk(async (actions, payload) => {
        const response = await updatePendingPartnerUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            notification.success({ message: body.message });
			actions.fetchPendingList();
        } else {

        }
    }),
	orderListByStatus: [],
	fetchOrderListByStatus: thunk(async (actions, payload) => {
		const response = await fetchOrderListByStatusUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				if(body?.item.length == []){
					notification['error']({
						message: 'Order List',
						description: 'No Data Found',
					});
				}else{
					actions.setOrderListByStatus(body?.item);
				}
                
            } else {
                notification['error']({
                    message: 'Order List',
                    description: body?.message,
                });
                actions.setpartnerList([]);
            }
			//const body = await response.json();
			
		} else {
			actions.setpartnerList([]);
		}
	}),

	setOrderListByStatus: action((state, payload) => {
		state.orderListByStatus = payload;
	}),

	fetchApprovePartnerListForProductOrder: thunk(async (actions, payload) => {
		const response = await fetchpartnerApproveListUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				if(body?.item.length == []){
					notification['error']({
						message: 'Partner List',
						description: 'No Data Found',
					});
				}else{
					actions.setApprovePartnerListForProductOrder(body?.item);
				}
                
            } else {
                notification['error']({
                    message: 'Partner List',
                    description: body?.message,
                });
                actions.setApprovePartnerListForProductOrder([]);
            }
			//const body = await response.json();
			
		} else {
			actions.setpartnerList([]);
		}
	}),
	approvePartnerListForProductOrder:[],
	setApprovePartnerListForProductOrder: action((state, payload) => {
		state.approvePartnerListForProductOrder = payload;
	}),
	fetchPartnerwiseProductOrderList: thunk(async (actions, payload) => {
		const response = await fetchOrderListByPartnerWiseUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				if(body?.item.length == []){
					notification['error']({
						message: 'Order List',
						description: 'No Data Found',
					});
				}else{
					actions.setPartnerwiseProductOrderList(body?.item);
				}
                
            } else {
                notification['error']({
                    message: 'Order List',
                    description: body?.message,
                });
                actions.setPartnerwiseProductOrderList([]);
            }
			//const body = await response.json();
			
		} else {
			actions.setpartnerList([]);
		}
	}),
	partnerwiseProductOrderList:[],
	setPartnerwiseProductOrderList: action((state, payload) => {
		state.partnerwiseProductOrderList = payload;
	}),
}
