import { Tabs , Button, Card, Col, DatePicker, Form, Input, InputNumber, notification, Row, Select, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectProduct } from '../../select/SelectProduct'
import moment from "moment";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectClient } from '../../select/SelectClient';
import BillGenerateSuperAdmin from './BillGenerateMonthlySuperAdmin.page';
import BillGenerateHalfYearlySuperAdmin from './BillGenerateHalfYearlySuperAdmin.page';
import BillGenerateMonthlySuperAdmin from './BillGenerateMonthlySuperAdmin.page';
import BillGenerateYearlySuperAdmin from './BillGenerateYearlySuperAdmin.page';
import BillGenerateSingleSuperAdmin from './BillGenerateSingleSuperAdmin.page';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function BillGenerateWrapperAdmin(props) {

    function callback(key) {
        console.log(key);
      }
    
    return (
        <>
            <Card title="Bill Generate">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Monthly" key="1">
                    <BillGenerateMonthlySuperAdmin />
                </TabPane>
                <TabPane tab="Half Yearly" key="2">
                    <BillGenerateHalfYearlySuperAdmin />
                </TabPane>
                <TabPane tab="Yearly" key="3">
                    <BillGenerateYearlySuperAdmin />
                </TabPane>                
                <TabPane tab="Single" key="4">
                    <BillGenerateSingleSuperAdmin />
                </TabPane>
            </Tabs>
            </Card>
        </>
    )
}
