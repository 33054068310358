import { DeleteOutlined, EditOutlined, ShopOutlined, SlidersOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import moment from 'moment';

export default function IIGDiscountList(props) {

    const check = useStoreState(state => state.isp.check);
    const ispDiscountList = useStoreState(state => state.isp.ispDiscountList);
    const fetchispDiscountList = useStoreActions(state => state.isp.fetchispDiscountList);
    const deleteDiscount = useStoreActions(state => state.isp.deleteDiscount);






    useEffect(() => {
        setPageTitle();
    }, []);

    useEffect(() => {
        setfilterTable(null);
        setsearchv('')
        fetchispDiscountList();
    }, [check])


    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Discount List'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }


    const columns = [

        {
            title: 'ID',
            dataIndex: 'clientCustomId',
            key: 'clientCustomId',

        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',

        },
        {
            title: 'Mobile No',
            dataIndex: 'mobileNo',
            key: 'mobileNo',

        },

        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Package Price',
            dataIndex: 'packagePrice',
            key: 'packagePrice',
        },        
        {
            title: 'Discount Amount',
            dataIndex: 'discountAmount',
            key: 'discountAmount',
        },        
        {
            title: 'Discount Date',
            dataIndex: 'discountGivenDate',
            key: 'discountGivenDate',
        },        
        {
            title: 'Discount Reason',
            dataIndex: 'discountReason',
            key: 'discountReason',
        },

        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                        <Popconfirm
                            title="Are you sure to delete?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteDiscount(record?.id)}
                        >
                            <Tooltip title="Delete">
                                <Button danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>


                </Space>
            ),
        },

    ]



    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = ispDiscountList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)

    };

    return (
        <>

            {ispDiscountList?.length > 0 &&
                <>
                    <Card title="Discounted List" >
                        <span>Total Found: {ispDiscountList !== null ? ispDiscountList?.length : 0}</span>
                        <Input
                            style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                            placeholder="Search..."
                            value={searchv}
                            allowClear
                            onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                        />
                        <Table
                            bordered={true}
                            dataSource={searchv === '' ? ispDiscountList : filterTable}
                            columns={columns}
                        />

                    </Card>
                </>
            }

        </>
    )
}
