import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, FileExcelOutlined, SaveOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import TableView from '../../../contents/AntTableResponsive';

const { Option } = Select;

const year = new Date().getFullYear();

export default function BillGenerateSingleSuperAdmin(props) {

    const [updateClientInfoForm] = Form.useForm();
    const ispClientProductWiseList = useStoreState(state => state.isp.ispClientProductWiseList2);
    const fetchIspClientProductWiseList = useStoreActions((state) => state.isp.fetchIspClientProductWiseList2);
    const fetchpackageListByProductId = useStoreActions((state) => state.order.fetchpackageListByProductId);
    const saveProductBillGenerateSingle = useStoreActions((state) => state.isp.saveProductBillGenerateSingle)
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [billCycle, setBillCycle] = useState<any>("");
    const [selectedClientId, setSelectedClientId] = useState<any>("");

    const [clientProductWiseList, setClientProductWiseList] = useState<any>();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        fetchIspClientProductWiseList(values?.selectProduct);

    };



    useEffect(() => {
        setClientProductWiseList(ispClientProductWiseList);
    }, [ispClientProductWiseList])

    const columns = [

        {
            title: 'Client ID',
            dataIndex: 'clientProvideId',
            key: 'clientProvideId',
            showOnResponse: true,
            showOnDesktop: true,
            width: 100
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            showOnResponse: true,
            showOnDesktop: true,
            width: 250
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            showOnResponse: true,
            showOnDesktop: true,
            width: 200
        },
        {
            title: 'Create Date',
            dataIndex: 'createDate',
            key: 'createDate',
            showOnResponse: true,
            showOnDesktop: true,
            width: 130
        },
        {
            title: 'Minimum Student',
            dataIndex: 'minimumStudent',
            key: 'minimumStudent',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Bill Cycle',
            dataIndex: 'billCycle',
            key: 'billCycle',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Package',
            dataIndex: 'packageName',
            key: 'packageName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Bill Payable Person',
            dataIndex: 'billPayablePerson',
            key: 'billPayablePerson',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Bill Type',
            dataIndex: 'billType',
            key: 'billType',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <Tooltip title="Update">
                        <Button type="primary" icon={<EditOutlined />} onClick={() => {
                            setIsViewModalVisible(true);
                            setBillCycle(record.billCycle);
                            fetchpackageListByProductId(record?.productId);
                            setSelectedClientId(record.clientId);

                        }} ></Button>
                    </Tooltip>
                </>
            )
        }
    ]


    const clientInfoUpdateFormSubmit = (value) => {

        let postdata: any = {
            "clientId": selectedClientId,
            "billingMonth": value.billingMonth === undefined ? '' : value.billingMonth,
            "billingYear": value.billingYear === undefined ? '' : value.billingYear,
        };
        saveProductBillGenerateSingle(postdata);
        updateClientInfoForm.resetFields();
        setIsViewModalVisible(false);
    }


    return (
        <>
            <>
                <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                    <Row gutter={15} >
                        <Col xs={24} sm={24} md={24} lg={7} xl={7}></Col>

                        <Col xs={24} sm={24} md={24} lg={7} xl={7}>

                            <Form.Item
                                name="selectProduct"
                                label="Select Product:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your product" },
                                ]}
                            >
                                <Select placeholder="Select Product" style={{ width: "100%" }} allowClear>
                                    <Option value="101">School/College</Option>
                                    <Option value="102">University</Option>
                                </Select>

                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                            <Space size="small" style={{ marginTop: 30 }} >
                                <Button type="primary" htmlType="submit" >
                                    Search
                                </Button>
                            </Space>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    </Row>
                </Form>
                {clientProductWiseList?.length > 0 &&
                    < >

                        {/* <span>Total Found: {clientProductWiseList !== null ? clientProductWiseList?.length : 0}</span>
                        <Input
                            style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                            placeholder="Search..."
                            value={searchv}
                            allowClear
                            onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                        />
                        <Table
                            rowKey="partnerName"
                            bordered={true}
                            columns={columns}
                            dataSource={searchv === '' ? clientProductWiseList : filterTable}
                        
                        /> */}
                        {/* <Button type="primary" icon={<SaveOutlined />} style={{ float: 'right' }}> Download Report </Button> */}
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                rowKey: "clientId",
                                dataSource: clientProductWiseList,
                                filterData: clientProductWiseList,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        <Row>

                        </Row>
                    </>

                }
            </>
            <Modal
                title="Product Bill Generate"
                visible={isViewModalVisible}
                okButtonProps={{ form: 'updateClientInfo', htmlType: 'submit' }}
                onCancel={() => setIsViewModalVisible(false)}
                // width={1000}
                okText="Save"
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    form={updateClientInfoForm}
                    id="updateClientInfo"
                    onFinish={clientInfoUpdateFormSubmit}
                >
                    <Row>
                        {billCycle === 'MONTHLY' &&
                            <>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="billingYear"
                                        label="Year:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <Select placeholder="Select Year" allowClear>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="billingMonth"
                                        label="Month:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select month" },
                                        ]}
                                    >
                                        <Select placeholder="Select Month" allowClear>
                                            <Option value="January">January</Option>
                                            <Option value="February">February</Option>
                                            <Option value="March">March</Option>
                                            <Option value="April">April</Option>
                                            <Option value="May">May</Option>
                                            <Option value="June">June</Option>
                                            <Option value="July">July</Option>
                                            <Option value="August">August</Option>
                                            <Option value="September">September</Option>
                                            <Option value="October">October</Option>
                                            <Option value="November">November</Option>
                                            <Option value="December">December</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </>
                        }
                        {billCycle === 'HALF_YEARLY' &&
                            <>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="billingYear"
                                        label="Year:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <Select placeholder="Select Year" allowClear>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="billingMonth"
                                        label="Month:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select month" },
                                        ]}
                                    >
                                        <Select placeholder="Select Month Range" allowClear>
                                            <Option value="January-June">January-June</Option>
                                            <Option value="July-December">July-December</Option>
                                        </Select>

                                    </Form.Item>
                                </Col>

                            </>

                        }
                        {billCycle === 'YEARLY' &&
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="billingYear"
                                    label="Year:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <Select placeholder="Select Year" allowClear>
                                        <Option value={year - 1}>{year - 1}</Option>
                                        <Option value={year}>{year}</Option>
                                        <Option value={year + 1}>{year + 1}</Option>
                                    </Select>

                                </Form.Item>
                            </Col>
                        }
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
