import { DeleteOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, InfoCircleOutlined, ShopOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip, Typography } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import ReactExport from "react-export-excel";
import { SelectDistrictForUpdateModal } from '../../select/SelectDistrictForUpdateModal'
import { SelectThanaForUpdateModal } from '../../select/SelectThanaForUpdateModal'
import { SelectUnderPartner } from '../../select/SelectUnderPartner'

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;
const { Paragraph } = Typography;

const { Option } = Select;

export default function ProductOrderListExecutiveByOrderStatus(props) {

    const [form] = Form.useForm();
    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rowSingleData, setRowSingleData] = useState<any>();
    const fetchOrderListByStatus = useStoreActions((state) => state.partner.fetchOrderListByStatus);
    const orderListByStatus = useStoreState((state) => state.partner.orderListByStatus);

    const onFinish = (value: any) => {
        fetchOrderListByStatus(value.orderStatus);
    };

    useEffect(() => {
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Order'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }
    
    const showModal = (value) => {
        setLoading(true);
        setIsModalVisible(true);
        setTimeout(() => {
            setLoading(false);
        }, 800);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Order Date',
            dataIndex: 'agreementDate',
            key: 'agreementDate',
            
        },
        {
            title: 'Total Student',
            dataIndex: 'numberOfStudent',
            key: 'numberOfStudent',
        },
        {
            title: 'Bill Cycle',
            dataIndex: 'billCycle',
            key: 'billCycle',
        },
        {
            title: 'Per Student Rate',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Partner Name',
            dataIndex: 'partnerName',
            key: 'partnerName',
        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <>
                    <Space size="small">
                        <Tooltip title="View">
                            <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                setIsModalVisible(true);
                                setRowSingleData(record);                            
                            }}></Button>
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    const search = value => {

        const result = orderListByStatus.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };
    
    return (
        <Card title='Order List'>
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} >
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}></Col>

                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="orderStatus"
                            label="Order Status:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select your status" },
                            ]}
                        >
                            <Select placeholder="Select Status" style={{ width: "100" }} >
                                <Option value="0">Pending</Option>
                                <Option value="1">Pre-Approve</Option>
                                <Option value="2">Approved</Option>
                            </Select>

                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>
            {orderListByStatus && orderListByStatus.length > 0 &&
                <>
                    <span>Total Found: {orderListByStatus !== null ? orderListByStatus?.length : 0}</span>
                    <Input
                        style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                        placeholder="Search..."
                        value={searchv}
                        allowClear
                        onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                    />
                    <Table bordered={true} dataSource={searchv === '' ? orderListByStatus : filterTable} columns={columns} />
                </>
            }
            <Modal
                title="Pending Partner Information" 
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ style: { display: 'none' } }}
                centered
            >       
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Client Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.clientName ? rowSingleData?.clientName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Product Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.productName ? rowSingleData?.productName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Package Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.packageName ? rowSingleData?.packageName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Partner Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.partnerName ? rowSingleData?.partnerName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Contact Number
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.contactNo ? rowSingleData?.contactNo : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Email
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.email ? rowSingleData?.email : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Order Date
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.agreementDate ? rowSingleData?.agreementDate : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Aggreement End Date
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.aggreementEndDate ? rowSingleData?.aggreementEndDate : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Total student
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.numberOfStudent ? rowSingleData?.numberOfStudent : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Per Student Rate
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.rate ? rowSingleData?.rate : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Bill cycle
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                        {rowSingleData && rowSingleData?.billCycle ? rowSingleData?.billCycle : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Bill Payable Person
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.billPayablePerson ? rowSingleData?.billPayablePerson : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Address
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.address ? rowSingleData?.address : ''}
                        </Col>
                    </Row>
            </Modal>
        </Card>
    )
}
