import { Button, Col, DatePicker, Form, Input, Result, Row, Space, Card, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectDistrict } from '../../select/SelectDistrict'
import { SelectThana } from '../../select/SelectThana'
import { SelectISPPackage } from '../../select/SelectISPPackage'
import moment from "moment";
import { Link } from "react-router-dom";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import TextArea from 'antd/lib/input/TextArea'


export default function IspClientAddFormPIP(props) {

    const [ispClientForm] = Form.useForm();

    useEffect(() => {

        setTimeout(function () {
            setPageTitle();
        })
    }, [])

    const saveIspClientInfo = useStoreActions(state => state.isp.saveIspClientInfo);
    const onIspClientAddFormSubmit = (value) => {
        let postData = {
            "clientCustomId": value.clientCustomId,
            "clientName": value.client_name,
            "mailingAddress": value.address,
            "mobileNo": value.mobile_number,
            "clientType": 1,
            "packageId": value.isp_package?.packageId,
            "clientRegistrationDate": moment(value.clientRegistrationDate._d).format("YYYY-MM-DD"),
        }

        saveIspClientInfo(postData);
        ispClientForm.resetFields();
        setispPackage(null)


    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };


    const [ispPackage, setispPackage] = useState<any>(null);

    const onchangeIspPackage = (val) => {
        console.log(val)
        setispPackage(val)
    }


    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP Client Add'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    return (
        <>
            <Card title="" >

                <Form
                    layout="horizontal"
                    {...layout}
                    onFinish={onIspClientAddFormSubmit}
                    id="basic-info"
                    form={ispClientForm}
                >
                    <div className="__orderForm p-0 custom-form">
                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Form.Item
                                    name="clientCustomId"
                                    label="Client Id:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write client id" },
                                    ]}
                                >
                                    <Input type="text" placeholder="Client Id"></Input>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Form.Item
                                    name="client_name"
                                    label="Client Name:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write client name" },
                                    ]}
                                >
                                    <Input type="text" placeholder="Client Name"></Input>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Form.Item
                                    name="mobile_number"
                                    label="Mobile:"
                                    className="title-Text"

                                    rules={[
                                        { required: true, message: "Please write your number", },
                                    ]}
                                >
                                    <Input type="text" placeholder="01711....."></Input>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Form.Item
                                    name="clientRegistrationDate"
                                    label="Registration Date:"
                                    className="title-Text"
                                    initialValue={moment(new Date())}
                                    rules={[
                                        { required: true, message: "Please select registration date", },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Form.Item
                                    name="address"
                                    label="Mailing Address:"
                                    className="title-Text"

                                    rules={[
                                        { required: true, message: "Please write your address", },
                                    ]}
                                >
                                    <TextArea placeholder="Your Address....."></TextArea>
                                </Form.Item>
                            </Col>



                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Form.Item
                                    name="isp_package"
                                    label="Package:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select isp package" },
                                    ]}
                                >
                                    <SelectISPPackage onChange={(val) => onchangeIspPackage(val)} selected={ispPackage}

                                    />
                                </Form.Item>
                            </Col>



                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Space size="small" style={{ float: "right" }}>
                                    <Button type="primary" htmlType="submit" >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Form>

            </Card>

        </>
    )
}
