import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectProductHalfYearly {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectProductHalfYearly = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectProductHalfYearly) => {
  const itemList = useStoreState((state) => state.order.productList);
  const itemListFetch = useStoreActions((state) => state.order.fetchproductList);
  const fetchclientList = useStoreActions((state) => state.order.fetchclientList);
  const fetchpackageListByProductId = useStoreActions((state) => state.order.fetchpackageListByProductId);

  React.useEffect(() => {
    itemListFetch();
  }, [])

  const onSelect = (id) => {
    if (itemList) {
      const val = itemList.find((item) => item.productId === id);
      onChange(val);
     // console.log(val)
      // fetchclientList(val?.productId);
    }
  };


  React.useEffect(() => {
    if (selected) {
      fetchpackageListByProductId(selected?.productId);
    }
  }, [selected])

  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      allowClear
      defaultValue={defaultSelected}
      value={selected?.productId}
      style={style}
      placeholder="Select Product"
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.productId} value={type.productId}>
            {type.productName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Products</Option>
      )}
    </Select>
  );
};
