import { Button, Card, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { SelectProduct } from '../../select/SelectProduct'
import moment from "moment";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectBillCycle } from '../../select/SelectBillCycle';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function BillGenerateListSuperAdmin(props) {

    const fetchbillGenerateInfoViewList = useStoreActions(state => state.bill.fetchBillGenerateInfoViewList);
    const updateBillGenerateInfo = useStoreActions(state => state.bill.updateBillGenerateInfo);
    const billGenerateInfoViewList = useStoreState(state => state.bill.billGenerateInfoViewList);
    const deleteMenualGenerateBill = useStoreActions((state) =>  state.bill.deleteMenualGenerateBill);
    const [billgenerateForm] = Form.useForm();

    const [sub, setsub] = useState<any>('');

    const orderFormSubmit = (value) => {
        value.productId = value?.productId?.productId
        fetchbillGenerateInfoViewList(value);
        setsub(value);
    };

    useEffect(() => {
        setPageTitle();
    }, []);

    const [productId, setProductId] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>(null);

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Bill Generate List'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const onchangeproductId = (val) => {
        setProductId(val);
    }
    const onchangebillCycle = (val) => {
        //   console.log(val)
        setBillCycle(val)
    }

    let year = new Date().getFullYear();
    const [bc, setbc] = useState<any>('');

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = billGenerateInfoViewList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const [billGenerateId, setBillGenerateId] = useState<any>(null);
    const columns = [
        {
            title: 'Client Provided Id',
            dataIndex: 'clientProvidedId',
            key: 'clientProvidedId',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Client Unit',
            dataIndex: 'clientUnit',
            key: 'clientUnit',
        },
        {
            title: 'Bill Month',
            dataIndex: 'billingMonth',
            key: 'billingMonth',
        },
        {
            title: 'Bill Year',
            dataIndex: 'billingYear',
            key: 'billingYear',
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Discount Note',
            dataIndex: 'discountNote',
            key: 'discountNote',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Update">
                        <Button type="primary" icon={<EditOutlined />} onClick={() => { showModal(record); setBillGenerateId(record.billGenerateId); formUpdate.setFieldsValue({ discount: record.discount, discountNote: record.discountNote }) }} ></Button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure to delete?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteBillGenerateManualList(record.billGenerateId)}
                        >
                            <Tooltip title="Delete">
                                <Button danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = (val) => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        // setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [formUpdate] = Form.useForm();
    const onFinishUpdate = (values: any) => {
        values.billGenerateId = billGenerateId;
        updateBillGenerateInfo(values);
        setTimeout(() => {
            fetchbillGenerateInfoViewList(sub);
        }, 1000);
        formUpdate.resetFields();
        setIsModalVisible(false);

    };

    function bcx(val) {
        setbc(val);
        if (val==="YEARLY"){
            billgenerateForm.setFieldsValue({ billingMonth: null })
        };
       
    }

    const deleteBillGenerateManualList = (value) => {
        deleteMenualGenerateBill(value);
        billgenerateForm.submit();
    }

    return (
        <>
            <Card title="Bill Generate List">
                <Form
                    layout="vertical"
                    onFinish={orderFormSubmit}
                    id="basic-info"
                    form={billgenerateForm}
                >
                    <div className="p-0">
                        <Row>
                            <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                                <Row>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <Form.Item
                                            name="productId"
                                            label="Product:"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select your product" },
                                            ]}
                                            style={{ width: "100%" }}
                                        >
                                            <SelectProduct onChange={(val) => onchangeproductId(val)} selected={productId} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <Form.Item
                                            name="billingYear"
                                            label="Year:"
                                            className="title-Text"
                                            initialValue={year}
                                            rules={[
                                                { required: true, message: "Please select year" },
                                            ]}
                                            style={{ width: "100%" }}
                                        >
                                            <Select placeholder="Select Year" allowClear style={{ width: "100%" }}>
                                                <Option value={year - 1}>{year - 1}</Option>
                                                <Option value={year}>{year}</Option>
                                                <Option value={year + 1}>{year + 1}</Option>
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <Form.Item
                                            name="billCycle"
                                            label="Bill Cycle:"
                                            className="title-Text"
                                            initialValue={null}
                                        >
                                            <Select placeholder="Select Bill Cycle" allowClear onChange={(e) => bcx(e)} style={{ width: "100%" }}>
                                                <Option value="MONTHLY">Monthly</Option>
                                                <Option value="HALF_YEARLY">Half Yearly</Option>
                                                <Option value="YEARLY">Yearly</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                        <Form.Item
                                            name="billingMonth"
                                            label="Month:"
                                            className="title-Text"
                                            initialValue={null}
                                        >
                                            <Select placeholder="Select Month" allowClear disabled={bc === "YEARLY" ? true : false} style={{ width: "100%" }}>
                                                <Option value="January">January</Option>
                                                <Option value="February">February</Option>
                                                <Option value="March">March</Option>
                                                <Option value="April">April</Option>
                                                <Option value="May">May</Option>
                                                <Option value="June">June</Option>
                                                <Option value="July">July</Option>
                                                <Option value="August">August</Option>
                                                <Option value="September">September</Option>
                                                <Option value="October">October</Option>
                                                <Option value="November">November</Option>
                                                <Option value="December">December</Option>
                                                <Option value="January-June">January-June</Option>
                                                <Option value="July-December">July-December</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={5} lg={5} xl={4}>
                                        <Form.Item
                                            name="billStatus"
                                            label="Bill Status:"
                                            className="title-Text"
                                            initialValue={null}
                                        >
                                            <Select placeholder="Select Bill Status" style={{ width: "100%" }}>
                                                <Option value="0">Unpaid</Option>
                                                <Option value="1">Paid</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Space size="small" className="pull-right mt-30-lg">
                                    <Button type="primary" htmlType="submit" >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="mt-15">
                                    <span>Total Found: {billGenerateInfoViewList !== null ? billGenerateInfoViewList?.length : 0}</span>
                                    <Input
                                        style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                                        placeholder="Search..."
                                        value={searchv}
                                        allowClear
                                        onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                                    />
                                    <Table
                                        bordered={true}
                                        dataSource={searchv === '' ? billGenerateInfoViewList : filterTable}
                                        columns={columns}
                                        rowKey={(record) => record.billGenerateId}
                                        // pagination={false}
                                        locale={{ emptyText: 'Search result will appear here' }}
                                        // style={{ maxHeight: 400 }}
                                    />

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Card>
            <Modal
                title="Discount"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"40%"}
                okText="Update"
                centered
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                maskClosable={false}
            >
                <Form layout="vertical" onFinish={onFinishUpdate} id='update-class' form={formUpdate} >
                    <Form.Item
                        name="discount"
                        label="Discount:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input discount", },
                        ]}
                    >
                        <Input type="text" placeholder="Discount"></Input>
                    </Form.Item>
                    <Form.Item
                        name="discountNote"
                        label="Discount Note:"
                        className="title-Text"
                    >
                        <Input.TextArea placeholder="Discount Note"></Input.TextArea>
                    </Form.Item>

                    <Button type="primary" htmlType="submit" >
                        Update
                    </Button>
                </Form>
            </Modal>
        </>
    )
}
