import { createStore } from 'easy-peasy';
import { Auth, authStore } from './states/auth/auth';
import { Common, commonStore } from './states/common/common';
import { Partner, partnerStore } from './states/partner/partner';
import { Order, orderStore } from './states/partner/order';
import { Bill, billStore } from './states/bill/bill';
import { ISP, ispStore } from './states/isp/isp';
import { IIG, iigStore } from './states/iig/iig';

export interface StoreModel {
	auth: Auth;
	common: Common;
	partner: Partner;
	order: Order;
	bill: Bill;
	isp: ISP;
	iig: IIG;
}

const storeModel: StoreModel = {
	auth: authStore,
	common: commonStore,
	partner: partnerStore,
	order: orderStore,
	bill: billStore,
	isp: ispStore,
	iig: iigStore,
}

export const store = createStore(storeModel);
