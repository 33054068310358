import { Button, Card, Col, Form, Input, Layout, message, Result, Tooltip } from 'antd'
import React, { useState } from 'react'
import logo from "../../../../../src/assets/images/suite_logo.png"
import splash from "../../../../../src/assets/images/3293466.jpg"
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone, InfoCircleOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import containerBG from "../../../../../src/assets/images/container_bg.png"

import OtpInput from 'react-otp-input';
export default function Dashboard(props) {

    const [username, setUsername] = useState<any>('')
    const [otp, setOtp] = useState<any>('')
    const [newPass, setNewPass] = useState("");
    const [confPass, setConfPass] = useState("");
    const fetchsendPasswordRecoveryToken = useStoreActions(state => state.auth.fetchsendPasswordRecoveryToken);
    const sendPasswordRecoveryToken = useStoreState(state => state.auth.sendPasswordRecoveryToken);
    const fetchresetPassword = useStoreActions(state => state.auth.fetchresetPassword);
    const resetPassword = useStoreState(state => state.auth.resetPassword);

    function handleSubmit(e) {
        e.preventDefault();
        if (username === '') {
            message.error('Username cannot be blank');
        } else {
            fetchsendPasswordRecoveryToken(username)
        }
    };
    function handleSubmitFinal(e) {
        e.preventDefault();

        if (newPass === confPass) {
            if (newPass.length === 0) {
                message.error("Password cannot be blank");
            } else {
                const payload = {
                    token: otp,
                    password: newPass,
                    userName: username,
                };
                fetchresetPassword(payload);
            }
        } else {
            message.error("Password doesn't match");
        }

    }

    return (
        <>
        <Layout className="login-form-wrapper">
            <div className="container">
                <div className="row box-shadow border-radius-35">
                    <div className="col-md-6 d-none d-md-block">
                        <img src={containerBG} className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <div className="login-form h-100">
                            <div className="logo-wrapper">
                                <div className="loginLogo">
                                    <img src={logo} className="img-fluid" />
                                </div>
                            </div>
                            <>
                            {!resetPassword && !sendPasswordRecoveryToken &&
                                    <>
                                        <div>
                                            <div className="row">
                                                <Col md={24} lg={24} xs={24}>
                                                    <p className="page-sub-title">Forgot your password?</p>
                                                    <span>Enter your registered username below and our system will send you a OTP code to your phone.</span>
                                                    <Input
                                                        name="username"
                                                        placeholder="Your username"
                                                        className="mt-30"
                                                        prefix={<KeyOutlined className="site-form-item-icon" />}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                        suffix={
                                                            <Tooltip title="username">
                                                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                            </Tooltip>
                                                        }
                                                    />
                                                </Col>
                                                <Col md={24} lg={24} xs={24}>
                                                    <div className="signUp-wrapper justify-content-center mt-30">
                                                        <Link className="signup" to="/login">Back to login</Link>
                                                    </div>
                                                </Col>
                                                <Col md={24} lg={24} xs={24}>
                                                    <Button
                                                        type="primary"
                                                        onClick={(e) => handleSubmit(e)}
                                                        className="submitButton mt-30"
                                                    >
                                                        Send
                                                    </Button>
                                                </Col>
                                            </div>
                                        </div>
                                    </>
                                }
                                {!resetPassword && sendPasswordRecoveryToken &&
                                    <>
                                        <div>
                                            <span className="mb-20 d-block">An OTP code has been sent to your phone number.</span>
                                            <Form layout="horizontal">
                                                <div className="row">
                                                    <Col md={24} lg={24} xs={24}>
                                                        <Form.Item>
                                                            <div className="d-flex justify-content-center ">
                                                                <div className="otp">
                                                                    <OtpInput
                                                                        value={otp}
                                                                        onChange={(e) => setOtp(e)}
                                                                        numInputs={4}
                                                                        separator={<span style={{ color: "#D9D9D9" }}>-</span>}
                                                                        inputStyle={{
                                                                            border: '1px solid #D9D9D9',
                                                                            borderRadius: '5px'
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={24} lg={24} xs={24}>
                                                        <Form.Item>
                                                            <Input.Password
                                                                iconRender={(visible) =>
                                                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                                                }
                                                                placeholder="New Password    "
                                                                onChange={(e) => setNewPass(e.target.value)}
                                                                value={newPass}
                                                                
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={24} lg={24} xs={24}>
                                                        <Form.Item>
                                                            <Input.Password
                                                                iconRender={(visible) =>
                                                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                                                }
                                                                placeholder="Confirm Password "
                                                                onChange={(e) => setConfPass(e.target.value)}
                                                                value={confPass}
                                                                
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={24} lg={24} xs={24}>
                                                        <Button
                                                            type="primary"
                                                            className="submitButton"
                                                            onClick={(e) => handleSubmitFinal(e)}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </Col>
                                                </div>
                                            </Form>

                                        </div>
                                    </>
                                }
                                {resetPassword && sendPasswordRecoveryToken &&
                                    <>
                                        <Result
                                            status="success"
                                            title="Your password has been reset successfully"
                                            subTitle="You can now use the new password to login to your account."
                                            extra={[
                                                <Button type="primary" key="console">
                                                    <a href="/login">Back to login</a>
                                                </Button>
                                            ]}
                                        />
                                    </>
                                }
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        </>
    )
}
