import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { CalculatorOutlined, ContainerOutlined, DashboardTwoTone, DollarCircleOutlined, FileTextOutlined, FundProjectionScreenOutlined, FundViewOutlined, PartitionOutlined, SolutionOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons/lib';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';

export function Sidebar() {
	// console.log(window.location.pathname)
	const fetchpartnerProfile = useStoreActions(state => state.common.fetchpartnerProfile);

	const partnerProfile = useStoreState(state => state.common.partnerProfile);
	useEffect(() => {
		// fetchpartnerProfile();
	}, []);

	const [pathValue, setpathValue] = useState<any>("1")
	const [mainpathValue, setmainpathValue] = useState<any>(null)


	const sideClick = (value) => {
		setpathValue(value.key)
		setKeys(value.keyPath)
		localStorage.setItem('shebaSuite', JSON.stringify(value.keyPath));
		localStorage.setItem('shebaSuiteKey', (value.key));
	}
	useEffect(() => {
		let val: any = localStorage.getItem('shebaSuite');
		let val2: any = localStorage.getItem('shebaSuiteKey');
		if (val !== null) {
			setRefresh(JSON.parse(val))
			setKeys(JSON.parse(val))
		}
		if (val2 !== null) {
			setpathValue(val2)
		}
		setView(true)
		//	console.log('here')
	}, [])


	const [refreshKeys, setRefresh] = useState<any>([]);
	const [view, setView] = useState<any>(false);
	const [keys, setKeys] = useState<any>([]);
	const onOpenChange = (value) => {
		setKeys(value)
	};

	return <>
		{view && <Menu theme="dark" defaultSelectedKeys={[pathValue]} mode="inline" selectedKeys={[pathValue]} onClick={sideClick} openKeys={keys} defaultOpenKeys={refreshKeys} onOpenChange={onOpenChange}>
			<>
				{/* ||||||||||||||||||||| */}
				{partnerProfile?.partnerRoles?.includes('ROLE_ADMIN') &&
					<>
						<SubMenu key={"partner"} icon={<PartitionOutlined />} title="Partner" >
							<Menu.Item key="01" icon={<FileTextOutlined />}>
								<Link to="/pending" className="nav-text">Pending</Link>
							</Menu.Item>
							<Menu.Item key="02" icon={<FileTextOutlined />}>
								<Link to="/approved-partner" className="nav-text">Approved</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key={"softwareOrderAction"} icon={<PartitionOutlined />} title="Order" >
							<Menu.Item key="24" icon={<FileTextOutlined />}>
								<Link to="/order-form" className="nav-text">Order Form</Link>
							</Menu.Item>
							<Menu.Item key="25x" icon={<FileTextOutlined />}>
								<Link to="/product-order-executive-list" className="nav-text">Order List</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key={"softwareOrder"} icon={<PartitionOutlined />} title="Order Status" >
							<Menu.Item key="03" icon={<FileTextOutlined />}>
								<Link to="/order-list" className="nav-text">Pending</Link>
							</Menu.Item>
							<Menu.Item key="04" icon={<FileTextOutlined />}>
								<Link to="/preorder-list" className="nav-text">Pre-Approved</Link>
							</Menu.Item>
							<Menu.Item key="05" icon={<FileTextOutlined />}>
								<Link to="/order-approved-list" className="nav-text">Approved</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key={"softwareBill"} icon={<PartitionOutlined />} title="Bill" >
							<Menu.Item key="010" icon={<FileTextOutlined />}>
								<Link to="/bill-generate" className="nav-text">Generate</Link>
							</Menu.Item>
							<Menu.Item key="10" icon={<ContainerOutlined />}>
								<Link to="/bill-generate-menual" className="nav-text">Collection</Link>
							</Menu.Item>
							<Menu.Item key="023" icon={<FileTextOutlined />}>
								<Link to="/paid-bill-list" className="nav-text">Paid</Link>
							</Menu.Item>
							<Menu.Item key="08" icon={<FileTextOutlined />}>
								<Link to="/unpaid-bill-list" className="nav-text">Unpaid</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key="sub9" icon={<CalculatorOutlined />} title="ShebaNet" >
							<Menu.Item key="isp-client-add-bulk" icon={<FileTextOutlined />}>
								<Link to="/isp-client-add-bulk" className="nav-text">Bulk Client Add</Link>
							</Menu.Item>
							<Menu.Item key="22" icon={<CalculatorOutlined />}>
								<Link to="/isp-client-list-sdl" className="nav-text">Client List</Link>
							</Menu.Item>
							<Menu.Item key="28" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-generate" className="nav-text">Bill Generate</Link>
							</Menu.Item>
							<Menu.Item key="23" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-status" className="nav-text">Bill Status</Link>
							</Menu.Item>
							<Menu.Item key="25" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-list-sdl" className="nav-text">Bill List</Link>
							</Menu.Item>
							<Menu.Item key="isp-bill-list-datewise-sdl" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-list-datewise-sdl" className="nav-text">Bill Disbursement</Link>
							</Menu.Item>
							<Menu.Item key="27" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-problem" className="nav-text">Bill Problem</Link>
							</Menu.Item>
						</SubMenu>
						<Menu.Item key="021" icon={<FileTextOutlined />}>
							<Link to="/client-info-update" className="nav-text">Client Info Update</Link>
						</Menu.Item>
						<SubMenu key={"report"} icon={<PartitionOutlined />} title="Report" >
							<Menu.Item key="06" icon={<FileTextOutlined />}>
								<Link to="/order-status" className="nav-text">Order Status</Link>
							</Menu.Item>
						</SubMenu>
						{/* <SubMenu key="spayadmin" icon={<CalculatorOutlined />} title="Spay" >
					<Menu.Item key="client-tansaction-summary" icon={<CalculatorOutlined />}>
						<Link to="/client-tansaction-summary" className="nav-text">Client Tran. Summary</Link>
					</Menu.Item>
					<Menu.Item key="client-vendor-tansaction-summary" icon={<CalculatorOutlined />}>
						<Link to="/client-vendor-tansaction-summary" className="nav-text">Client Vendor Tran. Summary</Link>
					</Menu.Item>
					<Menu.Item key="single-client-tansaction-summary" icon={<CalculatorOutlined />}>
						<Link to="/single-client-tansaction-summary" className="nav-text">Single Client Tran. Summary</Link>
					</Menu.Item>
					<Menu.Item key="single-client-vendor-tansaction-summary" icon={<CalculatorOutlined />}>
						<Link to="/single-client-vendor-tansaction-summary" className="nav-text">Single Client Vendor Tran. Summary</Link>
					</Menu.Item>
					<Menu.Item key="date-tansaction-summary" icon={<CalculatorOutlined />}>
						<Link to="/date-tansaction-summary" className="nav-text">Datewise Tran. Summary</Link>
					</Menu.Item>
					<Menu.Item key="client-date-tansaction-summary" icon={<CalculatorOutlined />}>
						<Link to="/client-date-tansaction-summary" className="nav-text">Client Datewise Tran. Summary</Link>
					</Menu.Item>
				</SubMenu> */}
						{/* <SubMenu key="ofpssuperadmin" icon={<CalculatorOutlined />} title="OFPS" >
					<SubMenu key="shebaSchool" icon={<CalculatorOutlined />} title="Sheba Shikkha" >
						<Menu.Item key="ofps-school-summary" icon={<CalculatorOutlined />}>
							<Link to="/ofps-school-summary" className="nav-text">Summary</Link>
						</Menu.Item>
						<Menu.Item key="ofps-school-institute-summary" icon={<CalculatorOutlined />}>
							<Link to="/ofps-school-institute-summary" className="nav-text">Details</Link>
						</Menu.Item>
					</SubMenu>
					<SubMenu key="shebaUniversity" icon={<CalculatorOutlined />} title="Sheba University" >
					</SubMenu>
				</SubMenu> */}
						<SubMenu key="spayadmin" icon={<CalculatorOutlined />} title="Spay" >
							<Menu.Item key="client-tansaction-summary" icon={<CalculatorOutlined />}>
								<Link to="/client-tansaction-summary" className="nav-text">Client Tran. Summary</Link>
							</Menu.Item>
							<Menu.Item key="client-vendor-tansaction-summary" icon={<CalculatorOutlined />}>
								<Link to="/client-vendor-tansaction-summary" className="nav-text">Client Vendor Tran. Summary</Link>
							</Menu.Item>
							<Menu.Item key="single-client-tansaction-summary" icon={<CalculatorOutlined />}>
								<Link to="/single-client-tansaction-summary" className="nav-text">Single Client Tran. Summary</Link>
							</Menu.Item>
							<Menu.Item key="single-client-vendor-tansaction-summary" icon={<CalculatorOutlined />}>
								<Link to="/single-client-vendor-tansaction-summary" className="nav-text">Single Client Vendor Tran. Summary</Link>
							</Menu.Item>
							<Menu.Item key="date-tansaction-summary" icon={<CalculatorOutlined />}>
								<Link to="/date-tansaction-summary" className="nav-text">Datewise Tran. Summary</Link>
							</Menu.Item>
							<Menu.Item key="client-date-tansaction-summary" icon={<CalculatorOutlined />}>
								<Link to="/client-date-tansaction-summary" className="nav-text">Client Datewise Tran. Summary</Link>
							</Menu.Item>
						</SubMenu>
						{/* {(partnerProfile?.partnerRoles?.includes('ROLE_ADMIN')|| (partnerProfile?.partnerRoles?.includes('ROLE_BILLING') || (partnerProfile?.partnerRoles?.includes('ROLE_RM')))) && */}
						{(partnerProfile?.partnerRoles?.includes('ROLE_ADMIN') || partnerProfile?.partnerRoles?.includes('ROLE_BILLING') || partnerProfile?.partnerRoles?.includes('ROLE_RM')) &&
							<SubMenu key="ofpssuperadmin" icon={<CalculatorOutlined />} title="OFPS" >
								<SubMenu key="shebaSchool" icon={<CalculatorOutlined />} title="Sheba Shikkha" >
									<Menu.Item key="ofps-school-summary" icon={<CalculatorOutlined />}>
										<Link to="/ofps-school-summary" className="nav-text">Summary</Link>
									</Menu.Item>
									<Menu.Item key="ofps-school-institute-summary" icon={<CalculatorOutlined />}>
										<Link to="/ofps-school-institute-summary" className="nav-text">Details</Link>
									</Menu.Item>
								</SubMenu>
								<SubMenu key="shebaUniversity" icon={<CalculatorOutlined />} title="Sheba University" >
								</SubMenu>
							</SubMenu>
						}
						<SubMenu key={"spgBillBill"} icon={<PartitionOutlined />} title="SPG" >
							<SubMenu key={"spgBillList"} icon={<PartitionOutlined />} title="Bill" >
								<Menu.Item key="spgBillSchool" icon={<FileTextOutlined />}>
									<Link to="/ofps-conf-list-school" className="nav-text">School</Link>
								</Menu.Item>
								<Menu.Item key="spgBillUniveristy" icon={<FileTextOutlined />}>
									<Link to="/ofps-conf-list-university" className="nav-text">University</Link>
								</Menu.Item>
							</SubMenu>
						</SubMenu>
						<SubMenu key={"customerSupport"} icon={<PartitionOutlined />} title="Customer Support" >
							<SubMenu key={"shebaNet"} icon={<PartitionOutlined />} title="Sheba Net" >
								<Menu.Item key="015" icon={<FileTextOutlined />}>
									<Link to="/isp-client-add-bulk" className="nav-text">Bulk Client Add</Link>
								</Menu.Item>
								<Menu.Item key="016" icon={<FileTextOutlined />}>
									<Link to="/isp-client-list-sdl" className="nav-text">Client List</Link>
								</Menu.Item>
								<Menu.Item key="017" icon={<FileTextOutlined />}>
									<Link to="/isp-bill-generate" className="nav-text">Bill Generate</Link>
								</Menu.Item>
								<Menu.Item key="018" icon={<FileTextOutlined />}>
									<Link to="/isp-bill-status" className="nav-text">Bill Status</Link>
								</Menu.Item>
								<Menu.Item key="019" icon={<FileTextOutlined />}>
									<Link to="/isp-bill-list-sdl" className="nav-text">Bill List</Link>
								</Menu.Item>
								<Menu.Item key="020" icon={<FileTextOutlined />}>
									<Link to="/isp-bill-problem" className="nav-text">Bill Problem</Link>
								</Menu.Item>
							</SubMenu>
						</SubMenu>
					</>
				}
				{(partnerProfile?.partnerRoles?.includes('ROLE_BILLING')) &&
					<>

						<SubMenu key={"spgBillBill"} icon={<PartitionOutlined />} title="SPG" >
							<SubMenu key={"spgBillList"} icon={<PartitionOutlined />} title="Bill" >
								<Menu.Item key="spgBillSchool" icon={<FileTextOutlined />}>
									<Link to="/ofps-conf-list-school" className="nav-text">School</Link>
								</Menu.Item>
								<Menu.Item key="spgBillUniveristy" icon={<FileTextOutlined />}>
									<Link to="/ofps-conf-list-university" className="nav-text">University</Link>
								</Menu.Item>
							</SubMenu>
						</SubMenu>
					</>
				}
				{partnerProfile?.partnerRoles?.includes('ROLE_BILLING') &&
					<>
						<SubMenu key={"partner"} icon={<PartitionOutlined />} title="Partner" >
							<Menu.Item key="01" icon={<FileTextOutlined />}>
								<Link to="/pending" className="nav-text">Pending</Link>
							</Menu.Item>
							<Menu.Item key="02" icon={<FileTextOutlined />}>
								<Link to="/approved-partner" className="nav-text">Approved</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key={"softwareOrderAction"} icon={<PartitionOutlined />} title="Order" >
							<Menu.Item key="24" icon={<FileTextOutlined />}>
								<Link to="/order-form" className="nav-text">Order Form</Link>
							</Menu.Item>
							<Menu.Item key="25x" icon={<FileTextOutlined />}>
								<Link to="/product-order-executive-list" className="nav-text">Order List</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key={"softwareOrder"} icon={<PartitionOutlined />} title="Order Status" >
							<Menu.Item key="03" icon={<FileTextOutlined />}>
								<Link to="/order-list" className="nav-text">Pending</Link>
							</Menu.Item>
							<Menu.Item key="04" icon={<FileTextOutlined />}>
								<Link to="/preorder-list" className="nav-text">Pre-Approved</Link>
							</Menu.Item>
							<Menu.Item key="05" icon={<FileTextOutlined />}>
								<Link to="/order-approved-list" className="nav-text">Approved</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key={"softwareBill"} icon={<PartitionOutlined />} title="Bill" >
							<Menu.Item key="023" icon={<FileTextOutlined />}>
								<Link to="/paid-bill-list" className="nav-text">Paid</Link>
							</Menu.Item>
							<Menu.Item key="08" icon={<FileTextOutlined />}>
								<Link to="/unpaid-bill-list" className="nav-text">Unpaid</Link>
							</Menu.Item>
						</SubMenu>

					</>
				}

				{partnerProfile?.partnerRoles?.includes('ROLE_BILLING') &&
					<>
						<SubMenu key={"partner"} icon={<PartitionOutlined />} title="Partner" >
							<Menu.Item key="02" icon={<FileTextOutlined />}>
								<Link to="/approved-partner" className="nav-text">Approved</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key={"softwareOrder"} icon={<PartitionOutlined />} title="Order Status" >
							<Menu.Item key="03" icon={<FileTextOutlined />}>
								<Link to="/order-list" className="nav-text">Pending</Link>
							</Menu.Item>
							<Menu.Item key="04" icon={<FileTextOutlined />}>
								<Link to="/preorder-list" className="nav-text">Pre-Approved</Link>
							</Menu.Item>
							<Menu.Item key="05" icon={<FileTextOutlined />}>
								<Link to="/order-approved-list" className="nav-text">Approved</Link>
							</Menu.Item>
						</SubMenu>
						<Menu.Item key="021" icon={<FileTextOutlined />}>
							<Link to="/client-info-update" className="nav-text">Client Info Update</Link>
						</Menu.Item>
						<SubMenu key={"softwareBill"} icon={<PartitionOutlined />} title="Software Bill" >
							<Menu.Item key="010" icon={<FileTextOutlined />}>
								<Link to="/bill-generate" className="nav-text">Generate</Link>
							</Menu.Item>
							<Menu.Item key="10" icon={<ContainerOutlined />}>
								<Link to="/bill-generate-menual" className="nav-text">Collection</Link>
							</Menu.Item>
							<Menu.Item key="023" icon={<FileTextOutlined />}>
								<Link to="/paid-bill-list" className="nav-text">Paid</Link>
							</Menu.Item>
							<Menu.Item key="08" icon={<FileTextOutlined />}>
								<Link to="/unpaid-bill-list" className="nav-text">Unpaid</Link>
							</Menu.Item>
						</SubMenu>

						<SubMenu key={"ispbill"} icon={<PartitionOutlined />} title="ISP Bill" >
							<Menu.Item key="010isp" icon={<FileTextOutlined />}>
								<Link to="/isp-bill-list-sdl" className="nav-text">Bill List</Link>
							</Menu.Item>
							<Menu.Item key="10disbursement" icon={<ContainerOutlined />}>
								<Link to="/isp-bill-list-datewise-sdl" className="nav-text">Bill Disbursement</Link>
							</Menu.Item>

						</SubMenu>

					</>
				}
				{partnerProfile?.partnerRoles?.includes('ROLE_ISP_SUPPORT') &&
					<>
						<SubMenu key="sub9" icon={<CalculatorOutlined />} title="ShebaNet" >
							<Menu.Item key="isp-client-add-bulk" icon={<FileTextOutlined />}>
								<Link to="/isp-client-add-bulk" className="nav-text">Bulk Client Add</Link>
							</Menu.Item>
							<Menu.Item key="22" icon={<CalculatorOutlined />}>
								<Link to="/isp-client-list-sdl" className="nav-text">Client List</Link>
							</Menu.Item>
							<Menu.Item key="28" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-generate" className="nav-text">Bill Generate</Link>
							</Menu.Item>
							<Menu.Item key="23" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-status" className="nav-text">Bill Status</Link>
							</Menu.Item>
							<Menu.Item key="25" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-list-sdl" className="nav-text">Bill List</Link>
							</Menu.Item>

							<Menu.Item key="27" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-problem" className="nav-text">Bill Problem</Link>
							</Menu.Item>
						</SubMenu>
					</>
				}
				{/* {(partnerProfile?.partnerRoles?.includes('ROLE_ADMIN') || (partnerProfile?.partnerRoles?.includes('ROLE_BILLING'))) &&
					<>

					</>
				} */}
				{partnerProfile?.partnerRoles?.includes('ROLE_ISP_RESELLER') &&
					<>

						<SubMenu key="sub9" icon={<CalculatorOutlined />} title="ShebaNet" >
							<Menu.Item key="isp-client-add-bulk" icon={<FileTextOutlined />}>
								<Link to="/isp-client-add" className="nav-text">Client Add</Link>
							</Menu.Item>
							<Menu.Item key="22" icon={<CalculatorOutlined />}>
								<Link to="/isp-client-list" className="nav-text">Client List</Link>
							</Menu.Item>
							<Menu.Item key="25" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-list" className="nav-text">Bill List</Link>
							</Menu.Item>
						</SubMenu>

					</>
				}
				{(partnerProfile?.partnerRoles?.includes('ROLE_DM') || partnerProfile?.partnerRoles?.includes('ROLE_EX')) &&
					<>
						<SubMenu key={"softwareOrderAction"} icon={<PartitionOutlined />} title="Order" >
							<Menu.Item key="24" icon={<FileTextOutlined />}>
								<Link to="/order-form" className="nav-text">Order Form</Link>
							</Menu.Item>
						</SubMenu>
					</>
				}
				{/* <>
				{partnerProfile?.partnerRoles?.includes('ROLE_DM') &&
					<>
						<SubMenu key={"softwareOrderAction"} icon={<PartitionOutlined />} title="Order" >
							<Menu.Item key="24" icon={<FileTextOutlined />}>
								<Link to="/order-form" className="nav-text">Order Form</Link>
							</Menu.Item>
							<Menu.Item key="3" icon={<FileTextOutlined />}>
								<Link to="/product-order-list-partner-wise" className="nav-text">Order List</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key={"softwareOrder"} icon={<PartitionOutlined />} title="Order Status" >
							<Menu.Item key="03" icon={<FileTextOutlined />}>
								<Link to="/order-list" className="nav-text">Pending</Link>
							</Menu.Item>
							<Menu.Item key="04" icon={<FileTextOutlined />}>
								<Link to="/preorder-list" className="nav-text">Pre-Approved</Link>
							</Menu.Item>
							<Menu.Item key="05" icon={<FileTextOutlined />}>
								<Link to="/order-approved-list" className="nav-text">Approved</Link>
							</Menu.Item>
						</SubMenu>
					</>
				}
				{partnerProfile?.partnerRoles?.includes('ROLE_EX') &&
					<>
						<SubMenu key={"softwareOrderAction"} icon={<PartitionOutlined />} title="Order" >
							<Menu.Item key="3" icon={<FileTextOutlined />}>
								<Link to="/order-form" className="nav-text">Order Form</Link>
							</Menu.Item>
							<Menu.Item key="26" icon={<FileTextOutlined />}>
								<Link to="/product-order-executive-list" className="nav-text">Order List</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu key="sub9" icon={<CalculatorOutlined />} title="ShebaNet" >
							<Menu.Item key="isp-client-add-bulk" icon={<FileTextOutlined />}>
								<Link to="/isp-client-add" className="nav-text">Client Add</Link>
							</Menu.Item>
							<Menu.Item key="22" icon={<CalculatorOutlined />}>
								<Link to="/isp-client-list" className="nav-text">Client List</Link>
							</Menu.Item>
							<Menu.Item key="25" icon={<CalculatorOutlined />}>
								<Link to="/isp-bill-list" className="nav-text">Bill List</Link>
							</Menu.Item>
						</SubMenu>

					</>
				}
				</> */}
				{/* ||||||||||||||||||||| */}

			</>
		</Menu>
		}
	</>
}
