import { DeleteOutlined, EditOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import moment from 'moment'
import { SelectBank } from '../../select/SelectBank'

export default function IspDisbursementListSDL(props) {

    const check = useStoreState(state => state.isp.check);
    const ispPartnerPaymentInfoList = useStoreState(state => state.isp.ispPartnerPaymentInfoList);
    const fetchispPartnerPaymentInfoList = useStoreActions(state => state.isp.fetchispPartnerPaymentInfoList);
    const ispPartnerPaymentInfoUpdate = useStoreActions(state => state.isp.ispPartnerPaymentInfoUpdate);
 


    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();

    const [fromDate, setfromDate] = useState<any>();
    const [toDate, settoDate] = useState<any>();

    const onFinish = (values: any) => {
        values.fromDate = moment(values?.fromDate).format("YYYY-MM-DD");
        values.toDate = moment(values?.toDate).format("YYYY-MM-DD");
        fetchispPartnerPaymentInfoList(values)
        // form.resetFields();
    };

    const onFinishUpdate = (values: any) => {
        values.id = row;
        values.bankName = values.bankName?.BankName==undefined?values.bankName:values.bankName?.BankName;
        values.paymentDate = moment(values?.paymentDate).format("YYYY-MM-DD");
        values.fromDate = fromDate;
        values.toDate = toDate;
        ispPartnerPaymentInfoUpdate(values)

        setIsModalVisible(false);

    };


    useEffect(() => {
        setPageTitle();
    }, []);




    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [row, setRow] = useState<any>();
    const columns = [

        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',

        },
        {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',

        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',

        },
        {
            title: 'Bank Name',
            dataIndex: 'bankName',
            key: 'bankName',

        },
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
            key: 'transactionId',

        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Tooltip title="Update">
                        <Button type="primary" icon={<EditOutlined />} onClick={() => { showModal(record); }} >Update</Button>
                    </Tooltip>

                </Space>
            ),
        },

    ]

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [bank, setbank] = useState<any>();

    const showModal = (val) => {
        setRow(val?.id);
        setbank(val?.bankName);
        formUpdate.setFieldsValue({
            bankName: val?.bankName,
            paidAmount: val?.paidAmount,
            paymentDate: moment(val.paymentDate, 'YYYY-MM-DD'),
            paymentMethod: val?.paymentMethod,
            transactionId: val?.transactionId,
        });
        setIsModalVisible(true);

    };

    const handleOk = () => {
        // setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    return (
        <>
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} className="box-shadow p-15">
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="fromDate"
                            label="From Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select From Date" onChange={(e: any) => setfromDate(moment(e._d).format("YYYY-MM-DD"))} />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Form.Item
                            name="toDate"
                            label="To Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select To Date" onChange={(e: any) => settoDate(moment(e._d).format("YYYY-MM-DD"))} />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>
            {ispPartnerPaymentInfoList?.length > 0 &&
                <Card title="Bill Disbursment List" >

                    <Table bordered={true} dataSource={ispPartnerPaymentInfoList} columns={columns} rowKey="id" />

                </Card>

            }
            <Modal
                title="Update"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"30%"}
                okText="Update"
                centered
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <Form layout="vertical" onFinish={onFinishUpdate} id='update-class' form={formUpdate} >
                    <Row gutter={15} >

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <Form.Item
                                name="bankName"
                                label="Bank:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select bank" },
                                ]}
                            >
                                <SelectBank defaultSelected={bank} />


                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <Form.Item
                                name="paidAmount"
                                label="Paid Amount:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please insert paid amount" },
                                ]}
                            >
                                <Input style={{ width: '100%' }} placeholder="Paid Amount" />
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <Form.Item
                                name="paymentDate"
                                label="Payment Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" />

                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <Form.Item
                                name="paymentMethod"
                                label="Payment Method:"
                                className="title-Text"

                            >
                                <Input style={{ width: '100%' }} placeholder="Payment Method" />
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <Form.Item
                                name="transactionId"
                                label="Transaction Id:"
                                className="title-Text"
                            >
                                <Input style={{ width: '100%' }} placeholder="Transaction Id" />
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                            <Space size="small" style={{ marginTop: 30 }} >
                                <Button type="primary" htmlType="submit" >
                                    Update
                                </Button>
                            </Space>

                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
