import { CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, FilePdfOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Select, Space, Table, Tooltip, Typography } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectBillCycleByValue } from '../../select/SelectBillCycleByValue';
import { SelectPackageByValue } from '../../select/SelectPackageByValue';
import TableView from '../../../contents/AntTableResponsive';
import ReactExport from "react-export-excel";
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;
const { Paragraph } = Typography;
const { Option } = Select;

export default function OrderListSuperAdmin(props) {

    const [updatependingDataForm] = Form.useForm();
    const pendingProductOrderList = useStoreState(state => state.order.pendingProductOrderList);
    const fetchpendingProductOrderList = useStoreActions(state => state.order.fetchpendingProductOrderList);
    const deleteProductOrder = useStoreActions(state => state.order.deleteProductOrder);
    const preApproveProductOrderPost = useStoreActions(state => state.order.preApproveProductOrderPost);
    const downloadAggrementCopy = useStoreActions((state) => state.order.downloadAggrementCopy);
    const fetchpackageListByProductId = useStoreActions((state) => state.order.fetchpackageListByProductId);
    const pendingOrderInfoUpdate = useStoreActions((state) => state.order.pendingOrderInfoUpdate);
    const partnerProfile = useStoreState((state) => state.common.partnerProfile);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [rowSingleData, setRowSingleData] = useState<any>();
    const [packageId, setPackageId] = useState<any>(null);
    const [selectOrgCat, setSelectOrgCat] = useState<any>(null);
    const [selectOrgType, setSelectOrgType] = useState<any>(null);
    const [thanaId, setThanaId] = useState<any>(null);
    const [disId, setdisId] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>("");
    const [xprice, setxPrice] = useState<any>(0)
    const [price, setPrice] = useState<any>(0)
    const [totalStudent, setTotalStudent] = useState<any>();
    const [paymentRate, setPaymentRate] = useState<any>();
    const [paymentRateTitle, setPaymentRateTitle] = useState<any>("Per Student Rate/FixedRate:");
    const [fileContent, setfileContent] = useState<any>("");
    const [selectPackageId, setSelectedPackageId] = useState<any>();
    const [orderId, setOrderId] = useState<any>();

    useEffect(() => {
        fetchpendingProductOrderList();
        setPageTitle();
    }, [])
    
    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Pending Order'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const deletePartner = value => {
        deleteProductOrder(value)
    }
    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:200,
        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Phone',
            dataIndex: 'contactNo',
            key: 'contactNo',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Email',
            dataIndex: 'instituteEmail',
            key: 'instituteEmail',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Order Date',
            dataIndex: 'agreementDate',
            key: 'agreementDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Hand Over Date',
            dataIndex: 'handOverDate',
            key: 'handOverDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Aggreement End Date',
            dataIndex: 'aggreementEndDate',
            key: 'aggreementEndDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:180,
        },
        {
            title: 'Total Student',
            dataIndex: 'numberOfStudent',
            key: 'numberOfStudent',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Bill Cycle',
            dataIndex: 'billCycle',
            key: 'billCycle',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Per Student Rate',
            dataIndex: 'rate',
            key: 'rate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Rate Type',
            dataIndex: 'rateType',
            key: 'rateType',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Bill Payable Person',
            dataIndex: 'billPayablePerson',
            key: 'billPayablePerson',
            showOnResponse: true, 
            showOnDesktop: true,
            width:160,
        },
        {
            title: 'Partner ID',
            width: 100,
            dataIndex: 'partnerCustomId',
            key: 'partnerCustomId',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Name',
            width: 150,
            dataIndex: 'partnerName',
            key: 'partnerName',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Email',
            width: 100,
            dataIndex: 'partnerEmail',
            key: 'partnerEmail',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Mobile',
            width: 130,
            dataIndex: 'partnerMobile',
            key: 'partnerMobile',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        // {
        //     title: 'Approve',
        //     width: 150,
        //     fixed: 'right',
        //     showOnResponse: true, 
        //     showOnDesktop: true,
        //     render: (text: any, record: any, index) => (
        //         <>
        //             {partnerProfile?.partnerRoles?.includes('ROLE_ADMIN') &&
        //                 <Space size="middle">
        //                     <Popconfirm
        //                         title="Are you sure to order this?"
        //                         okText="Yes"
        //                         cancelText="No"
        //                         onConfirm={() => preApproveProductOrderPost(record?.orderId)}
        //                     >
        //                         <Tooltip title="Pre-Approve Order">
        //                             <Button type="primary" icon={<CheckOutlined />} />
        //                         </Tooltip>
        //                     </Popconfirm>
        //                 </Space>
        //             }
        //             {partnerProfile?.partnerRoles?.includes('ROLE_BILLING')  || partnerProfile?.partnerRoles?.includes('ROLE_RM')&& 
        //                 <>
        //                     <Space size="small">
        //                         <Tooltip title="Pre-Approve Order">
        //                             <Button type="primary" icon={<CheckOutlined />} disabled />
        //                         </Tooltip>
        //                     </Space>
        //                 </>
        //             }
        //             {partnerProfile?.partnerRoles?.includes('ROLE_DM') && 
        //                 <>
        //                     <Space size="small">
        //                         <Tooltip title="Pre-Approve Order">
        //                             <Button type="primary" icon={<CheckOutlined />} disabled />
        //                         </Tooltip>
        //                     </Space>
        //                 </>
        //             }
        //             {partnerProfile?.partnerRoles?.includes('ROLE_EX') && 
        //                 <>
        //                     <Space size="small">
        //                         <Tooltip title="Pre-Approve Order">
        //                             <Button type="primary" icon={<CheckOutlined />} disabled />
        //                         </Tooltip>
        //                     </Space>
        //                 </>
        //             }
        //         </>
        //     ),
        // },
        {
            title: 'Action',
            width: 200,
            key: 'partnerCustomId',
            showOnResponse: true, 
            showOnDesktop: true,
            fixed: 'right',
            render: (text: any, record: any, index) => (
                <>
                    {partnerProfile?.partnerRoles?.includes('ROLE_ADMIN') && 
                        <Space size="small">
                            <Popconfirm
                                title="Are you sure to order this?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => preApproveProductOrderPost(record?.orderId)}
                            >
                                <Tooltip title="Pre-Approve Order">
                                    <Button type="primary" icon={<CheckOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                            <Tooltip title="View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                    setIsModalVisible(true);
                                    setRowSingleData(record);                            
                                }}></Button>
                            </Tooltip>
                            <Tooltip title="Update">
                                <Button type="primary" icon={<EditOutlined />} onClick={() => {
                                    fetchpackageListByProductId(record?.productId);
                                    setIsEditModalVisible(true);
                                    setBillCycle(record?.billCycle);
                                    setPrice(record?.rate);
                                    setPackageId(record?.packageId);
                                    setPaymentRateTitle(record?.rateType);
                                    setTotalStudent(record?.numberOfStudent);
                                    setPaymentRate(record?.rate);
                                    setOrderId(record?.orderId);
                                    updatependingDataForm.setFieldsValue({
                                        // product_id : record.productName
                                        package_id:record?.packageId,
                                        admin_name:record?.adminName,
                                        insName: record?.clientName,
                                        personal_number: record?.contactNo,
                                        aggrementDate: record.agreementDate == null ? null : moment(record.agreementDate, 'YYYY-MM-DD'),
                                        hod: record.handOverDate == null ? null : moment(record.handOverDate, 'YYYY-MM-DD'),
                                        aed: record.aggreementEndDate == null ? null : moment(record.aggreementEndDate, 'YYYY-MM-DD'),
                                        bill_cycle: record?.billCycle,
                                        payable_person: record?.billPayablePerson,
                                        rate_type: record?.rateType,
                                        price: record?.rate,
                                        number_student: record?.numberOfStudent,
                                        email:record?.instituteEmail,
                                        pRate: record?.rate,
                                        address:record?.address,
                                    });
                                }}></Button>
                            </Tooltip>
                            <Tooltip title="PDF Download">
                                <Button type="primary" onClick={() => downloadAggrementCopy(record?.orderId)} icon={<FilePdfOutlined />} ></Button>
                            </Tooltip>
                            <Popconfirm
                                title="Are you sure to delete this?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => deletePartner(record?.orderId)}
                            >
                                <Tooltip title="Delete">
                                    <Button danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    }
                    {partnerProfile?.partnerRoles?.includes('ROLE_BILLING') || partnerProfile?.partnerRoles?.includes('ROLE_RM')  && 
                        <>
                            <Space size="small">
                                <Tooltip title="Pre-Approve Order">
                                    <Button type="primary" icon={<CheckOutlined />} disabled />
                                </Tooltip>
                                <Tooltip title="View">
                                    <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                        setIsModalVisible(true);
                                        setRowSingleData(record);                            
                                    }}></Button>
                                </Tooltip>
                            </Space>
                        </>
                    }
                    {/* {partnerProfile?.partnerRoles?.includes('ROLE_EX') && 
                        <>
                            <Space size="small">
                                <Tooltip title="Pre-Approve Order">
                                    <Button type="primary" icon={<CheckOutlined />} disabled />
                                </Tooltip>
                                <Tooltip title="View">
                                    <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                        setIsModalVisible(true);
                                        setRowSingleData(record);                            
                                    }}></Button>
                                </Tooltip>
                            </Space>
                        </>
                    } */}
                    {partnerProfile?.partnerRoles?.includes('ROLE_DM') && 
                        <>
                            <Space size="small">
                                <Tooltip title="View">
                                    <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                        setIsModalVisible(true);
                                        setRowSingleData(record);                            
                                    }}></Button>
                                </Tooltip>
                            </Space>
                        </>
                    }
                </>
            ),
        },
    ];

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search =value => {

        const result = pendingProductOrderList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const pendingFormSubmit = (value) => {
        let postData:any = {
            agreementDate: moment(value?.aggrementDate).format("YYYY-MM-DD"),
            aggreementEndDate:  moment(value?.aed).format("YYYY-MM-DD"),
            adminName: value?.admin_name,
            billCycle: value?.bill_cycle,
            billPayablePerson: value?.payable_person,
            clientName: value?.insName,
            contactNo: value?.personal_number,
            email: value?.email,
            handOverDate: moment(value?.hod).format("YYYY-MM-DD"),
            numberOfStudent: value?.number_student,
            orderId: orderId,
            packageId: value?.package_id,
            rate: value?.pRate,
            rateType: value?.rate_type,
            address: value?.address
        }
        pendingOrderInfoUpdate(postData);
        setIsEditModalVisible(false);
    }

    const onchangepackageId = (val) => {
        console.log('setPackageId', val);
        
        setPackageId(val)
        // setPrice(val?.price)
        // orderForm.setFieldsValue({
        //     price: val?.price,
        //     pprice: val?.price,
        // });
    }

    const onchangebillCycle = (val) => {
        setBillCycle(val)
        let newprice=0;
        if (val.value==="Yearly"){
            newprice=price*12
            setxPrice(newprice)
        } else {
            newprice=price*1
            setxPrice(newprice)
        }

        updatependingDataForm.setFieldsValue({
            price: newprice,
        });        
        updatependingDataForm.setFieldsValue({
            number_student: null,
        });
    }

    const onchangeStudentField = (e) => {
        setTotalStudent(e);
        // orderForm.setFieldsValue({
        //     price: xprice*e,
        // }); 
        // orderForm.setFieldsValue({
        //     price: paymentRate*e,
        // }); 
        console.log('paymentRate', paymentRate);
        if (paymentRate !== undefined) {
            updatependingDataForm.setFieldsValue({
                price: paymentRate*e,
            }); 
        }
        console.log('tttt', paymentRate*e);
         

    }

    const onChnageFixedRateType = (value) => {
        setPaymentRate("");
        updatependingDataForm.setFieldsValue({
            price: "",
        });
        if (value == 'FIXED') {
            setPaymentRateTitle('Fixed Rate');
            updatependingDataForm.setFieldsValue({
                price: paymentRate,
            });
            setPaymentRate(paymentRate);
        } else {
            setPaymentRate(paymentRate);
            setPaymentRateTitle('PER_STUDENT');
            updatependingDataForm.setFieldsValue({
                price: totalStudent*paymentRate,
            });
        }
    }

    const onChangePrice = (value) => {
        setPaymentRate(value);
        console.log('paymentRateTitle',paymentRateTitle);
        
        if(paymentRateTitle == 'PER_STUDENT'){
            updatependingDataForm.setFieldsValue({
                price: totalStudent*value,
            });
        }else{
            updatependingDataForm.setFieldsValue({
                price: value,
            });
        }
    }


    const updatependingData = (value) => {
        console.log('value', value);
        
    }



    return (
        <>
            <Card title="Pending Order List" >
                <TableView
                    antTableProps={{
                        showHeader: true,
                        columns:columns,
                        rowKey:"orderId",
                        dataSource: pendingProductOrderList,
                        filterData: pendingProductOrderList,
                        pagination: true,
                        bordered: true,                      
                    }}
                    mobileBreakPoint={768}
                />
                <Row>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Approve Order Status Report`}
                            >
                                <ExcelSheet data={pendingProductOrderList} name="Approve Order Status Report">
                                    <ExcelColumn label="Product Name" value="productName" />
                                    <ExcelColumn label="Package Name" value="packageName" />
                                    <ExcelColumn label="Client Name" value="clientName" />
                                    <ExcelColumn label="Client No" value="contactNo" />
                                    <ExcelColumn label="Client Email" value="instituteEmail" />
                                    <ExcelColumn label="Area" value="area" />
                                    <ExcelColumn label="Address" value="address" />
                                    <ExcelColumn label="Order Date" value="agreementDate" />
                                    <ExcelColumn label="Hand Over Date" value="handOverDate" />
                                    <ExcelColumn label="Aggreement End Date" value="aggreementEndDate" />
                                    <ExcelColumn label="Total Student" value="numberOfStudent" />
                                    <ExcelColumn label="Bill Cycle" value="billCycle" />
                                    <ExcelColumn label="Per Student Rate" value="rate" />
                                    <ExcelColumn label="Rate Type" value="rateType" />
                                    <ExcelColumn label="Bill Payable Person" value="billPayablePerson" />
                                    <ExcelColumn label="Partner ID" value="partnerCustomId" />
                                    <ExcelColumn label="Partner Name" value="partnerName" />
                                    <ExcelColumn label="Partner Email" value="partnerEmail" />
                                    <ExcelColumn label="Partner Mobile" value="partnerMobile" />
                                </ExcelSheet>
                            </ExcelFile>
                        </Space>
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Product Information"
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                width={700}
                okText="Save"
                okButtonProps={{ style: { display: 'none' } }}
                centered
            >
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Product Name
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={8}>
                        {rowSingleData && rowSingleData?.productName ? rowSingleData?.productName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Package
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.packageName ? rowSingleData?.packageName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Name
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.clientName ? rowSingleData?.clientName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Phone
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.contactNo ? rowSingleData?.contactNo : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Email
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.instituteEmail ? rowSingleData?.instituteEmail : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Area
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.area ? rowSingleData?.area : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Address
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.address ? rowSingleData?.address : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Order Date	
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.agreementDate ? rowSingleData?.agreementDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Hand Over Date		
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.handOverDate ? rowSingleData?.agreementDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Aggreement End Date			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.aggreementEndDate ? rowSingleData?.aggreementEndDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Total Student
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.numberOfStudent ? rowSingleData?.numberOfStudent : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Bill Cycle	
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.billCycle ? rowSingleData?.billCycle : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Per Student Rate		
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.rate ? rowSingleData?.rate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Rate Type			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.rateType ? rowSingleData?.rateType : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Bill Payable Person			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.billPayablePerson ? rowSingleData?.billPayablePerson : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner ID				
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.partnerCustomId ? rowSingleData?.partnerCustomId : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Name					
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.packageName ? rowSingleData?.packageName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Email						
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.partnerEmail ? rowSingleData?.partnerEmail : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Mobile							
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.partnerMobile ? rowSingleData?.partnerMobile : ''}
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="Product Order Information Update"
                visible={isEditModalVisible}
                okButtonProps={{ form: 'updatePartner', htmlType: 'submit' }}
                onCancel={() => setIsEditModalVisible(false)}
                width={1000}
                okText="Update"
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    form={updatependingDataForm}
                    id="updatePartner"
                    onFinish={pendingFormSubmit}
                >
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="package_id"
                                label="Package:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your package" },
                                ]}
                            >
                                <SelectPackageByValue onChange={(val) => onchangepackageId(val)} selected={packageId} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="insName"
                                label="Institute/Organization Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert institute name" },
                                ]}
                            >
                                <Input type="text" placeholder="Institute Name"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="address"
                                label="Address:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write address" },
                                ]}
                            >
                                <Input type="text" placeholder="Address"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="admin_name"
                                label="Software Admin Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write software admin name" },
                                ]}
                            >
                                <Input type="text" placeholder="Admin Name"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="personal_number"
                                label="Software Admin Mobile No:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please write software admin mobile", },
                                ]}
                            >
                                <Input type="text" placeholder="01711....."></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="email"
                                label="Client Email:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write client address" },
                                    {
                                        max: 60,
                                        type: 'email',
                                        message: "Pleaset Insert valid email.",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="example@gmail.com"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="aggrementDate"
                                label="Aggrement Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter aggrement date" },
                                ]}
                            >
                                <DatePicker placeholder="Aggrement Date" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="hod"
                                label="Hand over Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter hand over date" },
                                ]}
                            >
                                <DatePicker placeholder="Hand over Date" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="aed"
                                label="Aggrement End Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter aggrement end date" },
                                ]}
                            >
                                <DatePicker placeholder="Aggrement End Date" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="bill_cycle"
                                label="Bill Cycle:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select bill cycle" },
                                ]}
                            >
                                <SelectBillCycleByValue onChange={(val) => onchangebillCycle(val)} selected={billCycle}

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="payable_person"
                                label="Bill Payable Person(Instiute/Student):"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select payable person" },
                                ]}
                            >
                                <Select>
                                    <Option value="STUDENT">Student</Option>
                                    <Option value="INSTITUTE">Instiute</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="rate_type"
                                label="Rate Type(Fixed rate/Per Student)"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select fixed rate" },
                                ]}
                            >
                                <Select onChange={(e) => onChnageFixedRateType(e)}>
                                    <Option value="FIXED">FIXED</Option>
                                    <Option value="PER_STUDENT">PER_STUDENT</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="number_student"
                                label="Number of Student:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert number of student" },
                                ]}
                            >
                                <InputNumber type="number" min={1} placeholder="Number Of Student" style={{width:"100%"}} onChange={(e) => onchangeStudentField(e)}></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="pRate"
                                label={paymentRateTitle}
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write fixed rate" },
                                ]}
                            >
                                <InputNumber type="text" style={{ width: "100%" }} onChange={(e) => onChangePrice(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="price"
                                label="Total Rate:"
                                className="title-Text"
                            >
                                <Input type="text" disabled value={price}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
