import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Select, Skeleton, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Text from 'antd/lib/typography/Text';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FileExcelOutlined, FilePdfOutlined, SaveOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function SchoolSummary(props) {
    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();

    const [tableData, setTableData] = useState([]);

    const [fromDate, setfromDate] = useState<any>();
    const [toDate, settoDate] = useState<any>();
    const [loading, setloading] = useState<boolean>(false);
    async function onFinish(values: any) {
        values.fromDate = moment(values?.fromDate).format("YYYY-MM-DD");
        values.toDate = moment(values?.toDate).format("YYYY-MM-DD");
        setloading(true);
        setpdfValue(values);
        try {
            let response = await fetch(`${process.env.REACT_APP_API_SHEBASHIKKHA}/public/shebashikkha/ofps/summary?fromDate=${values.fromDate}&toDate=${values.toDate}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            });
            if (response.status === 201 || response.status === 200) {
                const body = await response.json();
                setloading(false)
                if (body?.item?.length > 0) {
                    setTableData(body?.item);
                } else {
                    setTableData([]);
                    notification['error']({
                        message: 'OFPS',
                        description: "No Data Found",
                    });
                }


            } else {
                setTableData([]);
                notification['error']({
                    message: 'OFPS',
                    description: "No Data Found",
                });
                setloading(false)
            }

        } catch (error) {
            console.log(error);
            notification['error']({
                message: 'OFPS',
                description: "No Data Found",
            });
            setloading(false)
        }

        // form.resetFields();
    };



    useEffect(() => {
        setPageTitle();
    }, []);

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'OFPS'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [pdfValue, setpdfValue] = useState<any>();

    const [row, setRow] = useState<any>();
    const columns = [

        {
            title: 'Institute Id',
            dataIndex: 'instituteId',
            key: 'instituteId',

        },
        {
            title: 'Institute Name',
            dataIndex: 'instituteName',
            key: 'instituteName',

        },
        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',

        },
        {
            title: 'Service Charge',
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',

        },
        {
            title: 'Transaction Amount',
            dataIndex: 'trnAmount',
            key: 'trnAmount',
        },
        {
            title: 'Comission',
            dataIndex: 'commission',
            key: 'commission',
        },
        {
            title: 'Vat',
            dataIndex: 'vat',
            key: 'vat',
        },
        {
            title: 'Pay Amount',
            dataIndex: 'payAmount',
            key: 'payAmount',
        },
    ];

    function downloadPdf() {
        const doc = new jsPDF("landscape");
        var totalPagesExp = '{total_pages_count_string}';
        const tableColumn = ["Id", "Institute Name", "Fee Amount", "Service Charge", "Transaction Amount", "Comission", "Vat", "Pay Amount"];
        const tableRows: any[][] = [];
        var width = doc.internal.pageSize.getWidth()
        doc.text('Sheba Digital Limited (SDL)', width / 2, 20, { align: 'center' })
        doc.setFontSize(12)
        doc.text(`OFPS Summary`, width / 2, 27, { align: 'center' })
        doc.setFontSize(10)
        doc.text(`Date ${pdfValue.fromDate} to ${pdfValue.toDate}`, width / 2, 32, { align: 'center' })
        let totalFee = 0;
        let totalserviceCharge = 0;
        let totaltrnAmount = 0;
        let totalpayAmount = 0;
        let totalcommission = 0;
        let totalvat = 0;
        tableData.forEach((item: any) => {
            const itemData = [
                item.instituteId,
                item.instituteName,
                item.feeAmount,
                item.serviceCharge,
                item.trnAmount,
                item.commission,
                item.vat,
                item.payAmount,

            ];
            totalFee += item.feeAmount;
            totalserviceCharge += item.serviceCharge;
            totaltrnAmount += item.trnAmount;
            totalpayAmount += item.payAmount;
            totalcommission += item.commission;
            totalvat += item.vat;
            tableRows.push(itemData);
        });
        const totalCol = ['1', '2', '3', '4', '5', '6', '7', '8'];
        const totalColData = [['Total', '', totalFee, totalserviceCharge, totaltrnAmount, totalcommission, totalvat, totalpayAmount]];


        // @ts-ignore


        doc.autoTable(tableColumn, tableRows, {
            startY: 37,
            pageBreak: "auto",
            rowPageBreak: "auto",
            theme: "grid",
            columnStyles: {
                0: { cellWidth: 18 },
                1: { cellWidth: 75 },
                2: { cellWidth: 29 },
                3: { cellWidth: 29 },
                4: { cellWidth: 29 },
                5: { cellWidth: 29 },
                6: { cellWidth: 29 },
                7: { cellWidth: 29 },
            },

            didDrawPage: function (data) {
                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                doc.setTextColor(150)
                doc.setFontSize(10)
                doc.text('Powered by Sheba Digital Limited | Part of Sheba Group.', width / 2 - 40, pageHeight - 10);
            }

        });
        let finalY = (doc as any).lastAutoTable.finalY;
        (doc as any).autoTable(totalCol, totalColData, {
            startY: finalY + 0,
            pageBreak: "auto",
            rowPageBreak: "auto",
            showHead: "never",
            theme: "grid",
            columnStyles: {
                0: { cellWidth: 18 },
                1: { cellWidth: 75 },
                2: { cellWidth: 29 },
                3: { cellWidth: 29 },
                4: { cellWidth: 29 },
                5: { cellWidth: 29 },
                6: { cellWidth: 29 },
                7: { cellWidth: 29 },
            },
        });
        // finalY = (doc as any).lastAutoTable.finalY;
        // doc.text(`Total Bill Amount: ${totalBill} `, width - 52, finalY + 10)


        doc.save(`OFPS Summary.pdf`);
    }




    return (
        <Card title="OFPS Summary" >
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} >
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                        <Form.Item
                            name="fromDate"
                            label="From Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select From Date" onChange={(e: any) => setfromDate(moment(e._d).format("YYYY-MM-DD"))} />


                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                        <Form.Item
                            name="toDate"
                            label="To Date:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select date" },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder="Select To Date" onChange={(e: any) => settoDate(moment(e._d).format("YYYY-MM-DD"))} />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>

                </Row>
            </Form>
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
            {tableData?.length > 0 && !loading &&

                <>
                    <Table
                        bordered={true}
                        dataSource={tableData}
                        columns={columns}
                        rowKey="instituteId"
                        summary={pageData => {
                            let totalFee = 0;
                            let totalserviceCharge = 0;
                            let totaltrnAmount = 0;
                            let totalpayAmount = 0;
                            let totalcommission = 0;
                            let totalvat = 0;

                            tableData.forEach(({ feeAmount }) => {
                                totalFee += feeAmount;
                            });

                            tableData.forEach(({ serviceCharge }) => {
                                totalserviceCharge += serviceCharge;
                            });
                            tableData.forEach(({ trnAmount }) => {
                                totaltrnAmount += trnAmount;
                            });
                            tableData.forEach(({ payAmount }) => {
                                totalpayAmount += payAmount;
                            });
                            tableData.forEach(({ commission }) => {
                                totalcommission += commission;
                            });

                            tableData.forEach(({ vat }) => {
                                totalvat += vat;
                            });

                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>
                                            <Text type="danger" strong>Total:</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>
                                            <Text type="danger" strong></Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>
                                            <Text type="danger" strong>{totalFee}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>
                                            <Text type="danger" strong>{totalserviceCharge}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>
                                            <Text type="danger" strong>{totaltrnAmount}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}>
                                            <Text type="danger" strong>{totalcommission}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}>
                                            <Text type="danger" strong>{totalvat}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}>
                                            <Text type="danger" strong>{totalpayAmount}</Text>
                                        </Table.Summary.Cell>

                                    </Table.Summary.Row>

                                </>
                            );
                        }}
                    />

                    <Space style={{ float: 'right' }} size="middle">
                        <ExcelFile filename={`OFPS summary from ${pdfValue.fromDate} to ${pdfValue.toDate} `} element={<Button type="primary" icon={<FileExcelOutlined />} > Download Excel </Button>}>
                            <ExcelSheet data={tableData} name="OFPS">
                                <ExcelColumn label="Institute Id" value="instituteId" />
                                <ExcelColumn label="Institute Name" value="instituteName" />
                                <ExcelColumn label="Fee Amount" value="feeAmount" />
                                <ExcelColumn label="Service Charge" value="serviceCharge" />
                                <ExcelColumn label="Transaction Amount" value="trnAmount" />
                                <ExcelColumn label="Comission" value="commission" />
                                <ExcelColumn label="Vat" value="vat" />
                                <ExcelColumn label="Pay Amount" value="payAmount" />
                            </ExcelSheet>
                        </ExcelFile>
                        <Button type="primary" icon={<FilePdfOutlined />} onClick={downloadPdf} > Download PDF </Button>
                    </Space>
                </>
            }

        </Card>
    )
}
