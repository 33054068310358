import { Button, Col, DatePicker, Form, Input, Result, Row, Space, Card, InputNumber, Select, Upload, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectDistrict } from '../../select/SelectDistrict'
import { SelectThana } from '../../select/SelectThana'
import { SelectProduct } from '../../select/SelectProduct'
import { SelectPackage } from '../../select/SelectPackage'
import { SelectCategoryOrg } from '../../select/SelectCategoryOrg'
import { SelectTypeOrg } from '../../select/SelectTypeOrg'
import { SelectBillCycle } from '../../select/SelectBillCycle'
import moment from "moment";
import { Link } from "react-router-dom";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import ImgCrop from 'antd-img-crop'
import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select;

export default function OrderFormPartner(props) {

    const [orderForm] = Form.useForm();
    const postsaveProductOrder = useStoreActions(state => state.order.postsaveProductOrder);
    const ordersuccess = useStoreState(state => state.order.ordersuccess);
    const setordersuccess = useStoreActions(state => state.order.setordersuccess);
    const [fileList, setFileList] = useState<any>([]);
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const [paymentRateTitle, setPaymentRateTitle] = useState<any>("Per Student Rate/FixedRate:");
    const [totalStudent, setTotalStudent] = useState<any>();
    const [paymentRate, setPaymentRate] = useState<any>();


    useEffect(() => {
        setordersuccess(false);
        setTimeout(function () {
            setPageTitle();
        })
    }, [])
    const orderFormSubmit = (value) => {
        if (trainingCertificateFileSave===false){
            notification.error({message:"Please upload attachment of aggrement copy"});
            return
        }
        let postData = {
            adminName: value?.admin_name ? value?.admin_name : '',
            address: value?.village ? value?.village : '',
            aggreementEndDate: moment(value?.aed).format("YYYY-MM-DD"),
            aggrementCopy: aggrementCopy,
            agreementDate: moment(value?.ad).format("YYYY-MM-DD"),
            aggrementCopyContent: aggrementCopyContent,
            billCycle: value?.bill_cycle?.value ? value?.bill_cycle?.value : '',
            billPayablePerson: value?.payable_person ? value?.payable_person : '',
            clientName: value?.insName ? value?.insName : "",
            contactNo: value?.personal_number ? value?.personal_number : '',
            email: value?.email ? value?.email : '',
            fileSave: true,
            handOverDate: moment(value?.hod).format("YYYY-MM-DD"),
            numberOfStudent: value?.number_student ? value?.number_student : '',
            orderStatus: 0,
            organizationCategory: value?.ins_category?.value ? value?.ins_category?.value : '',
            organizationType: value?.ins_type?.value ? value?.ins_type?.value : '',
            packageId: value?.package_id?.packageId ? value?.package_id?.packageId : '',
            partnerId: 0,
            postOffice: value?.post_office ? value?.post_office : '',
            productId: value?.product_id?.productId ? value?.product_id?.productId : '',
            rate: value?.pRate ? value?.pRate : '',
            rateType: value?.rate_type ? value?.rate_type : '',
            thanaId: value?.thana_id?.thanaId ? value?.thana_id?.thanaId : ''
        }
        postsaveProductOrder(postData);
        clearFileInput(document.getElementById("upload-file"));
        setIsFile(false);
        setfileContent('');
        setattachmentFileName('');
    };
    const onchangeDistrict = (val) => {
        //   console.log(val)
        setThanaId(null);
        setdisId(val);
    }
    const [disId, setdisId] = useState<any>(null);

    const onchangeThana = (val) => {
        //   console.log(val)
        setThanaId(val);
    }
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const [thanaId, setThanaId] = useState<any>(null);
    const [productId, setProductId] = useState<any>(null);
    const [packageId, setPackageId] = useState<any>(null);
    const [selectOrgType, setSelectOrgType] = useState<any>(null);
    const [selectOrgCat, setSelectOrgCat] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>(null);

    const onchangeproductId = (val) => {
        setProductId(val);
        setPackageId(null);
        orderForm.setFieldsValue({
            package_id: null,
        });
        console.log('productId', productId);

    }
    const [price, setPrice] = useState<any>(0)
    const [xprice, setxPrice] = useState<any>(0)
    const onchangepackageId = (val) => {
        setPackageId(val)
        // setPrice(val?.price)
        // orderForm.setFieldsValue({
        //     price: val?.price,
        //     pprice: val?.price,
        // });
    }
    const onchangeselectOrgType = (val) => {
        setSelectOrgCat(null)
        setSelectOrgType(val)
    }
    const onchangeselectOrgCat = (val) => {
        setSelectOrgCat(val)
    }
    const onchangebillCycle = (val) => {
        setBillCycle(val)
        let newprice = 0;
        if (val.value === "Yearly") {
            newprice = price * 12
            setxPrice(newprice)
        } else {
            newprice = price * 1
            setxPrice(newprice)
        }

        orderForm.setFieldsValue({
            price: newprice,
        });
        orderForm.setFieldsValue({
            number_student: null,
        });
    }

    const onchangeStudentField = (e) => {
        setTotalStudent(e);
        // orderForm.setFieldsValue({
        //     price: xprice*e,
        // }); 
        // orderForm.setFieldsValue({
        //     price: paymentRate*e,
        // }); 
        console.log('paymentRate', paymentRate);
        if (paymentRate !== undefined) {
            orderForm.setFieldsValue({
                price: paymentRate * e,
            });
        }
        console.log('tttt', paymentRate * e);


    }
    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Order Form'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }



    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChnageFixedRateType = (value) => {
        setPaymentRate("");
        orderForm.setFieldsValue({
            price: "",
        });
        if (value == 'FIXED') {
            setPaymentRateTitle('Fixed Rate');
        } else {
            setPaymentRateTitle('Per Student Rate');
        }
    }

    const onChangePrice = (value) => {
        setPaymentRate(value);
        if (paymentRateTitle == 'Per Student Rate') {
            orderForm.setFieldsValue({
                price: totalStudent * value,
            });
        } else {
            orderForm.setFieldsValue({
                price: value,
            });
        }
    }

    async function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    const [trainingCertificateFileSave, setIsFile] = useState<boolean>(false);
    const [aggrementCopyContent, setfileContent] = useState<any>('');
    const [aggrementCopy, setattachmentFileName] = useState<any>('');

    const uploadPdf = (file: any) => {
        if (file.target.files[0].type!=='application/pdf'){
            notification.error({ message: 'Please upload a pdf file' });
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            setfileContent('');
            setattachmentFileName('');
            return;
        }
        if (file.target.files[0]?.size > 5000000) {
            notification.error({ message: 'PDF size should be less than 5MB' })
            // file.target.value = null;
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            setfileContent('');
            setattachmentFileName('');
            return;
        };

        getBase64(file.target.files[0], (imageUrl) => {
            setattachmentFileName(file.target.files[0].name);
            setfileContent(imageUrl.split(',')[1]);
            setIsFile(true);
        })
    };

    return (
        <>
            <Card title="" >
                {!ordersuccess && <>
                    <Form
                        layout="horizontal"
                        {...layout}
                        onFinish={orderFormSubmit}
                        id="basic-info"
                        form={orderForm}
                    >
                        <div className="__orderForm p-0 custom-form">
                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="product_id"
                                        label="Select Software:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select your software" },
                                        ]}
                                    >
                                        <SelectProduct onChange={(val) => onchangeproductId(val)} selected={productId}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="package_id"
                                        label="Package:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select your package" },
                                        ]}
                                    >
                                        <SelectPackage onChange={(val) => onchangepackageId(val)} selected={packageId}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="insName"
                                        label="Institute/Organization Name:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please insert institute name" },
                                        ]}
                                    >
                                        <Input type="text" placeholder="Institute Name"></Input>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="ins_type"
                                        label="Institute/Organization Type:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select your type" },
                                        ]}
                                    >
                                        <SelectTypeOrg onChange={(val) => onchangeselectOrgType(val)} selected={selectOrgType}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="ins_category"
                                        label="Institute/Organization Category:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select your category" },
                                        ]}
                                    >
                                        <SelectCategoryOrg onChange={(val) => onchangeselectOrgCat(val)} selected={selectOrgCat}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="village"
                                        label="Address:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please insert address" },
                                        ]}
                                    >
                                        <Input type="text" placeholder="Enter Address"></Input>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="post_office"
                                        label="Post Office:"
                                        className="title-Text"
                                    >
                                        <Input type="text" placeholder="Enter post office"></Input>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="district_id"
                                        label="District:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select your district" },
                                        ]}
                                    >
                                        <SelectDistrict onChange={(val) => onchangeDistrict(val)} selected={disId}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="thana_id"
                                        label="Thana:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select your Thana" },
                                        ]}
                                    >
                                        <SelectThana onChange={(val) => onchangeThana(val)} selected={thanaId}

                                        />
                                    </Form.Item>

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="admin_name"
                                        label="Software Admin Name:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write software admin name" },
                                        ]}
                                    >
                                        <Input type="text" placeholder="Admin Name"></Input>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="personal_number"
                                        label="Software Admin Mobile No.:"
                                        className="title-Text"

                                        rules={[
                                            { required: true, message: "Please write software admin mobile", },
                                        ]}
                                    >
                                        <Input type="text" placeholder="01711....."></Input>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="email"
                                        label="Software Admin Email:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write software admin email address" },
                                            {
                                                max: 60,
                                                type: 'email',
                                                message: "Pleaset Insert valid email.",
                                            },
                                        ]}
                                    >
                                        <Input type="text" placeholder="example@gmail.com"></Input>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="ad"
                                        label="Aggrement Date:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter aggrement date" },
                                        ]}
                                    >
                                        <DatePicker placeholder="Aggrement Date" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="hod"
                                        label="Hand over Date:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter hand over date" },
                                        ]}
                                    >
                                        <DatePicker placeholder="Hand over Date" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="aed"
                                        label="Aggrement End Date:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter aggrement end date" },
                                        ]}
                                    >
                                        <DatePicker placeholder="Aggrement End Date" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="bill_cycle"
                                        label="Bill Cycle:"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select bill cycle" },
                                        ]}
                                    >
                                        <SelectBillCycle onChange={(val) => onchangebillCycle(val)} selected={billCycle}

                                        />
                                    </Form.Item>
                                </Col>
                                {selectOrgType?.value === "Education" &&
                                    <>
                                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                            <Form.Item
                                                name="number_student"
                                                label="Number of Student:"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please insert number of student" },
                                                ]}
                                            >
                                                <InputNumber type="number" min={1} placeholder="Number Of Student" style={{ width: "100%" }} onChange={(e) => onchangeStudentField(e)}></InputNumber>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                            <Form.Item
                                                name="rate_type"
                                                label="Rate Type(Fixed rate/Per Student)"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select fixed rate" },
                                                ]}
                                            >
                                                <Select onChange={(e) => onChnageFixedRateType(e)}>
                                                    <Option value="FIXED">FIXED</Option>
                                                    <Option value="PER_STUDENT">PER_STUDENT</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                            <Form.Item
                                                name="pRate"
                                                label={paymentRateTitle}
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please write fixed rate" },
                                                ]}
                                            >
                                                <InputNumber type="text" style={{ width: "100%" }} onChange={(e) => onChangePrice(e)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                            <Form.Item
                                                name="payable_person"
                                                label="Bill Payable Person(Instiute/Student):"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select payable person" },
                                                ]}
                                            >
                                                <Select>
                                                    <Option value="STUDENT">Student</Option>
                                                    <Option value="INSTITUTE">Instiute</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                            <Form.Item
                                                name="rate_type"
                                                label="Rate Type(Fixed rate/Per student):"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select fixed rate" },
                                                ]}
                                            >
                                                <Select>
                                                    <Option value="">Fixed rate</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                            <Form.Item
                                                name="pprice"
                                                label="Product Rate:"
                                                className="title-Text"
                                            >
                                                <Input type="text" disabled ></Input>
                                            </Form.Item>
                                        </Col> */}
                                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                            <Form.Item
                                                name="price"
                                                label="Total Rate:"
                                                className="title-Text"
                                            >
                                                <Input type="text" disabled value={price}></Input>
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                                    <div style={{ display: "flex", marginBottom: 10 }}>
                                        <div style={{ width: "25%" }} className="ant-col ant-form-item-label"><label className="ant-form-item-required" >Aggrement copy Attachment)</label></div>
                                        <div style={{ width: "75%", marginLeft: 5 }} >
                                            <input style={{ borderColor: "#03D665" }} className='ant-input' type="file" accept="application/pdf" id="upload-file" onChange={uploadPdf} />
                                        </div>
                                    </div>

                                </Col>
                                {/* <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="user_image"
                                        label="Aggrement copy Attachment:"
                                        className="title-Text"
                                    >
                                        <input className='ant-input' type="file" accept="application/pdf" id="upload-file" onChange={uploadPdf} />
                                    </Form.Item>
                                </Col> */}
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Space size="small" style={{ float: "right" }}>
                                        <Button type="primary" htmlType="submit" >
                                            Submit
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </>}
                {ordersuccess && <>
                    <br />
                    <Result
                        status="success"
                        title="Your request has been submitted"
                        subTitle="You will get notified once you get approved by an admin."
                        extra={[
                            <Link to="/">                    <Button type="primary" key="console">
                                Back to Dashboard
                            </Button></Link>
                        ]}
                    />
                </>
                }
            </Card>

        </>
    )
}
