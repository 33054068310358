import { Button, Card, Col, DatePicker, Form, Input, InputNumber, notification, Row, Select, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectProduct } from '../../select/SelectProduct'
import moment from "moment";
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectClient } from '../../select/SelectClient';

const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function BillGenerateYearlySuperAdmin(props) {

    const saveProductBillGenerateYearly = useStoreActions(state => state.bill.saveProductBillGenerateYearly)
    const fetchclientUnit = useStoreActions(state => state.bill.fetchclientUnit)
    const clientUnit = useStoreState(state => state.bill.clientUnit);
    const clientList = useStoreState((state) => state.order.clientList);
    const [billgenerateForm] = Form.useForm();


    const orderFormSubmit = (value) => {
        //console.log(value)
        if (selectedRowKeys.length ===0) {
            notification.error({message:'Please select at least one client'})
            return
        }
        let postYearly = {
            clientIds: selectedValue.map(item => item.clientId),
            productId: value?.product_id?.productId,
            billingYear: value?.year,
        };
        saveProductBillGenerateYearly(postYearly);
        setselectedRowKeys([]);
        setselectedRowKeys([]);
    };

    useEffect(() => {
        setPageTitle()
    }, []);

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Bill Generate Yearly'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [productId, setProductId] = useState<any>(null);
    const [clientVal, setClientVal] = useState<any>(null);

    const [monthLength, setmonthLength] = useState<any>(0)
    const [num, setNum] = useState<any>(0)

    const onchangesetNum = (val) => {
        setNum(val)
    }

    const onchangemonthLength = (val) => {
        setmonthLength(val.length)
    }

    const onchangeproductId = (val) => {
        setProductId(val);
        setClientVal(null)
    }
    const onchangeClient = (val) => {
        setClientVal(val)
        fetchclientUnit(val?.clientId);
    }

    let year = new Date().getFullYear();

    const [total, settotal] = useState<any>(0);

    useEffect(() => {
        settotal(parseFloat(clientUnit) * monthLength * num)
    }, [clientUnit, monthLength, num]);

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
            key: 'contactNo',
        }
    ];
    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = clientList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };
    
    return (
        <>
            <Form
                layout="horizontal"
                {...layout}
                onFinish={orderFormSubmit}
                id="basic-info"
                form={billgenerateForm}
            >
                <div className="__orderForm p-0 custom-form">
                    <Row gutter={[16, 16]}>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="product_id"
                                label="Product:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your product" },
                                ]}
                            >
                                <SelectProduct onChange={(val) => {onchangeproductId(val); setselectedRowKeys([]); setselectedValue([])}} selected={productId} />
                            </Form.Item>

                            <Form.Item
                                name="year"
                                label="Year:"
                                className="title-Text"
                                initialValue={year}
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >
                                <Select placeholder="Select Year" allowClear>
                                    <Option value={year - 1}>{year - 1}</Option>
                                    <Option value={year}>{year}</Option>
                                    <Option value={year + 1}>{year + 1}</Option>
                                </Select>

                            </Form.Item>
                        </Col>
                        
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <Card title="Client List" >
                                    <span>Total Found: {clientList !== null ? clientList?.length : 0}</span>
                                    <Input
                                        style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                                        placeholder="Search..."
                                        value={searchv}
                                        allowClear
                                        onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                                    />
                                    <Table
                                        bordered={true}
                                        dataSource={searchv === '' ? clientList : filterTable}
                                        columns={columns}
                                        rowSelection={rowSelection}
                                        rowKey={(record) => record.clientId}
                                        pagination={false}
                                        locale={{ emptyText: 'Select product to see client list' }}
                                        style={{ maxHeight: 400 }}
                                    />
                                </Card>
                            </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Space size="small" style={{ float: "right" }}>
                                <Button type="primary" htmlType="submit" >
                                    Generate
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}
