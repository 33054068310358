import { CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tooltip, Typography } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import { SelectBillCycleByValue } from '../../select/SelectBillCycleByValue';
import { SelectPackageByValue } from '../../select/SelectPackageByValue';
import TableView from '../../../contents/AntTableResponsive';
import ReactExport from "react-export-excel";
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;
const { Paragraph } = Typography;
const { Option } = Select;

export default function OrderListSuperAdmin(props) {
    const [updatePreApproveDataForm] = Form.useForm();
    const preApproveProductOrderList = useStoreState(state => state.order.preApproveProductOrderList);

    const fetchpreApproveProductOrderList = useStoreActions(state => state.order.fetchpreApproveProductOrderList);

    const deleteProductOrder = useStoreActions(state => state.order.deleteProductOrder);
    const approveProductOrder = useStoreActions(state => state.order.approveProductOrder);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [rowSingleData, setRowSingleData] = useState<any>();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [orderId, setOrderId] = useState<any>();
    const [packageId, setPackageId] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>("");
    const [xprice, setxPrice] = useState<any>(0);
    const [price, setPrice] = useState<any>(0)
    const [paymentRate, setPaymentRate] = useState<any>();
    const [paymentRateTitle, setPaymentRateTitle] = useState<any>("Per Student Rate/FixedRate:");
    const [totalStudent, setTotalStudent] = useState<any>();
    const fetchpackageListByProductId = useStoreActions((state) => state.order.fetchpackageListByProductId);
    const preApproveOrderInfoUpdate = useStoreActions((state) => state.order.preApproveOrderInfoUpdate);
    const partnerProfile = useStoreState((state) => state.common.partnerProfile);

    useEffect(() => {
        fetchpreApproveProductOrderList();
        setPageTitle();
    }, [])

    // useEffect(() => {
    //     if (paymentRateTitle == 'FIXED') {
    //         setPaymentRateTitle('Fixed Rate');
    //     }else{
    //         setPaymentRateTitle('Per Student Rate');
    //     }
        
    // }, [paymentRateTitle])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Pre-approve Order'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const deletePartner = value => {
        deleteProductOrder(value)
    }
    const [chk, setchk] = useState<boolean>(false)
    const [charge, setCharge] = useState<any>(0)
    const [productId, setproductId] = useState<any>('')

    const onChangecheckbox = (e) => {
        setchk(e.target.checked)
        setCharge(0);
    }
    const onChangeCharge = (e) => {
        setCharge(e);
    }
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
        setchk(false);
        setCharge(0);
    };

    const handleOk = () => {
        let postData = {
            "developmentCharge": charge,
            "developmentChargeStatus": chk,
            "productOrderId": productId
        }
        approveProductOrder(postData)
        setTimeout(() => {
            setIsModalVisible(false);
        }, 1000);

    };

    const productApprove = () => {
        if (partnerProfile?.partnerRoles?.includes('ROLE_ADMIN')) {
            setIsModalVisible(true);
        } else {
            setIsModalVisible(false);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:200,
        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Phone',
            dataIndex: 'contactNo',
            key: 'contactNo',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Email',
            dataIndex: 'instituteEmail',
            key: 'instituteEmail',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Order Date',
            dataIndex: 'agreementDate',
            key: 'agreementDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Hand Over Date',
            dataIndex: 'handOverDate',
            key: 'handOverDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Aggreement End Date',
            dataIndex: 'aggreementEndDate',
            key: 'aggreementEndDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Total Student',
            dataIndex: 'numberOfStudent',
            key: 'numberOfStudent',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Bill Cycle',
            dataIndex: 'billCycle',
            key: 'billCycle',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Per Student Rate',
            dataIndex: 'rate',
            key: 'rate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Rate Type',
            dataIndex: 'rateType',
            key: 'rateType',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Bill Payable Person',
            dataIndex: 'billPayablePerson',
            key: 'billPayablePerson',
            showOnResponse: true, 
            showOnDesktop: true,
            width:160,
        },
        {
            title: 'Partner ID',
            width: 100,
            dataIndex: 'partnerCustomId',
            key: 'partnerCustomId',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Name',
            width: 150,
            dataIndex: 'partnerName',
            key: 'partnerName',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Email',
            width: 100,
            dataIndex: 'partnerEmail',
            key: 'partnerEmail',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Mobile',
            width: 130,
            dataIndex: 'partnerMobile',
            key: 'partnerMobile',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        // {
        //     title: 'Approve',
        //     showOnResponse: true, 
        //     showOnDesktop: true,
        //     width:100,
        //     fixed: 'right',
        //     render: (text: any, record: any, index) => (
        //         <>
        //             {partnerProfile?.partnerRoles?.includes('ROLE_ADMIN')  &&
        //                 <Space size="middle">

        //                 <Tooltip title="Approve Order">
        //                     <Button type="primary" icon={<TeamOutlined />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
        //                 </Tooltip>

        //                 </Space>
        //             }
        //             { partnerProfile?.partnerRoles?.includes('ROLE_RM') && 
        //                 <Space size="middle">

        //                 <Tooltip title="Approve Order">
        //                     <Button type="primary" disabled icon={<TeamOutlined />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
        //                 </Tooltip>

        //                 </Space>
        //             }
        //             {partnerProfile?.partnerRoles?.includes('ROLE_BILLING')  &&
        //                 <Space size="middle">

        //                 <Tooltip title="Approve Order">
        //                     <Button type="primary" disabled icon={<TeamOutlined />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
        //                 </Tooltip>

        //                 </Space>
        //             }
        //             {partnerProfile?.partnerRoles?.includes('ROLE_DM')  &&
        //                 <Space size="middle">

        //                 <Tooltip title="Approve Order">
        //                     <Button type="primary" disabled icon={<TeamOutlined />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
        //                 </Tooltip>

        //                 </Space>
        //             }
        //             {partnerProfile?.partnerRoles?.includes('ROLE_EX')  &&
        //                 <Space size="middle">

        //                 <Tooltip title="Approve Order">
        //                     <Button type="primary" disabled icon={<TeamOutlined />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
        //                 </Tooltip>

        //                 </Space>
        //             }
        //         </>
        //     ),
        // },
        {
            title: 'Action',
            showOnResponse: true, 
            showOnDesktop: true,
            width:200,
            fixed: 'right',
            render: (text: any, record: any, index) => (
                <>
                
                     {partnerProfile?.partnerRoles?.includes('ROLE_ADMIN')  &&
                        <Space size="middle">
                            <Tooltip title="Approve Order">
                                <Button type="primary" icon={<CheckOutlined  />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
                            </Tooltip>
                            <Tooltip title="View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                    setIsViewModalVisible(true);
                                    setRowSingleData(record);
                                    setproductId(record?.orderId)
                                    
                                }}></Button>
                            </Tooltip>
                            <Tooltip title="Update">
                                <Button type="primary" icon={<EditOutlined />} onClick={() => {
                                    fetchpackageListByProductId(record?.productId);
                                    setIsEditModalVisible(true);
                                    setBillCycle(record?.billCycle);
                                    setPrice(record?.rate);
                                    setPackageId(record?.packageId);
                                    setPaymentRateTitle(record?.rateType == 'FIXED' ? "Fixed Rate" : "Per Student rate");
                                    setTotalStudent(record?.numberOfStudent);
                                    setPaymentRate(record?.rate);
                                    setOrderId(record?.orderId);
                                    updatePreApproveDataForm.setFieldsValue({
                                        // product_id : record.productName
                                        package_id:record?.packageId,
                                        admin_name:record?.adminName,
                                        insName: record?.clientName,
                                        personal_number: record?.contactNo,
                                        aggrementDate: record.agreementDate == null ? null : moment(record.agreementDate, 'YYYY-MM-DD'),
                                        hod: record.handOverDate == null ? null : moment(record.handOverDate, 'YYYY-MM-DD'),
                                        aed: record.aggreementEndDate == null ? null : moment(record.aggreementEndDate, 'YYYY-MM-DD'),
                                        bill_cycle: record?.billCycle,
                                        payable_person: record?.billPayablePerson,
                                        rate_type: record?.rateType,
                                        price: record?.rate,
                                        number_student: record?.numberOfStudent,
                                        email:record?.instituteEmail,
                                        pRate: record?.rate,
                                        address:record?.address,
                                        order_status: record?.orderStatus == 0 ? 'Pending' :  'Pre-Approved'
                                    });
                                }}></Button>
                            </Tooltip>
                            <Popconfirm
                                title="Are you sure to delete this?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => deletePartner(record?.orderId)}
                            >
                                <Tooltip title="Delete">
                                    <Button danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
        
                        </Space>
                     }
                     {partnerProfile?.partnerRoles?.includes('ROLE_BILLING')  && 
                        <Space size="middle">
                            <Tooltip title="Approve Order">
                                <Button type="primary" disabled icon={<CheckOutlined  />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
                            </Tooltip>
                            <Tooltip title="View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                    setIsViewModalVisible(true);
                                    setRowSingleData(record);
                                    setproductId(record?.orderId)
                                    
                                }}></Button>
                            </Tooltip>
                        </Space>
                     }
                     {partnerProfile?.partnerRoles?.includes('ROLE_RM') && 
                        <Space size="middle">
                            <Tooltip title="Approve Order">
                                <Button type="primary" disabled icon={<CheckOutlined  />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
                            </Tooltip>
                            <Tooltip title="View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                    setIsViewModalVisible(true);
                                    setRowSingleData(record);
                                    setproductId(record?.orderId)
                                    
                                }}></Button>
                            </Tooltip>
                        </Space>
                     }
                     {partnerProfile?.partnerRoles?.includes('ROLE_DM') && 
                        <Space size="middle">
                            <Tooltip title="Approve Order">
                                <Button type="primary" disabled icon={<CheckOutlined  />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
                            </Tooltip>
                            <Tooltip title="View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                    setIsViewModalVisible(true);
                                    setRowSingleData(record);
                                    setproductId(record?.orderId)
                                    
                                }}></Button>
                            </Tooltip>
                        </Space>
                     }
                     {partnerProfile?.partnerRoles?.includes('ROLE_EM') && 
                        <Space size="middle">
                            <Tooltip title="View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                    setIsViewModalVisible(true);
                                    setRowSingleData(record);
                                    setproductId(record?.orderId)
                                    
                                }}></Button>
                            </Tooltip>
                        </Space>
                     }
                     {/* {partnerProfile?.partnerRoles?.includes('ROLE_EX')  &&
                        <Space size="middle">

                        <Tooltip title="Approve Order">
                            <Button type="primary" disabled icon={<CheckOutlined  />} onClick={() => { showModal(); setproductId(record?.orderId) }} />
                        </Tooltip>

                        </Space>
                    } */}
                </>
                
            ),
        },
    ];

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search =value => {

        const result = preApproveProductOrderList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const onchangepackageId = (val) => {
        console.log('setPackageId', val);
        
        setPackageId(val)
        // setPrice(val?.price)
        // orderForm.setFieldsValue({
        //     price: val?.price,
        //     pprice: val?.price,
        // });
    }

    const onchangebillCycle = (val) => {
        setBillCycle(val)
        let newprice=0;
        if (val.value==="Yearly"){
            newprice=price*12
            setxPrice(newprice)
        } else {
            newprice=price*1
            setxPrice(newprice)
        }

        updatePreApproveDataForm.setFieldsValue({
            price: newprice,
        });        
        updatePreApproveDataForm.setFieldsValue({
            number_student: null,
        });
    }

    const onChnageFixedRateType = (value) => {
        setPaymentRate("");
        updatePreApproveDataForm.setFieldsValue({
            price: "",
        });
        if (value == 'FIXED') {
            setPaymentRateTitle('Fixed Rate');
            updatePreApproveDataForm.setFieldsValue({
                price: paymentRate,
            });
            setPaymentRate(paymentRate);
        } else {
            setPaymentRate(paymentRate);
            setPaymentRateTitle('Per Student Rate');
            updatePreApproveDataForm.setFieldsValue({
                price: totalStudent*paymentRate,
            });
        }
    }

    const onchangeStudentField = (e) => {
        setTotalStudent(e);
        if (paymentRate !== undefined) {
            updatePreApproveDataForm.setFieldsValue({
                price: paymentRate*e,
            }); 
        }
    }

    const onChangePrice = (value) => {
        setPaymentRate(value);
        if(paymentRateTitle == 'PER_STUDENT'){
            updatePreApproveDataForm.setFieldsValue({
                price: totalStudent*value,
            });
        }else{
            updatePreApproveDataForm.setFieldsValue({
                price: value,
            });
        }
    }

    const pendingFormSubmit = (value) => {
        let postData:any = {
            agreementDate: moment(value?.aggrementDate).format("YYYY-MM-DD"),
            aggreementEndDate:  moment(value?.aed).format("YYYY-MM-DD"),
            adminName: value?.admin_name,
            billCycle: value?.bill_cycle,
            billPayablePerson: value?.payable_person,
            clientName: value?.insName,
            contactNo: value?.personal_number,
            email: value?.email,
            handOverDate: moment(value?.hod).format("YYYY-MM-DD"),
            numberOfStudent: value?.number_student,
            orderId: orderId,
            orderStatus: value?.order_status == '0' ? 0 :  2,
            packageId: value?.package_id,
            rate: value?.pRate,
            rateType: value?.rate_type,
            address: value?.address
        }
        preApproveOrderInfoUpdate(postData);
        setIsEditModalVisible(false);
    }


    return (
        <>
            <Card title="Pre-approve Order List">
                <TableView
                    antTableProps={{
                        showHeader: true,
                        columns:columns,
                        rowKey:"orderId",
                        dataSource: preApproveProductOrderList,
                        filterData: preApproveProductOrderList,
                        pagination: true,
                        bordered: true,                      
                    }}
                    mobileBreakPoint={768}
                />
                <Row>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Approve Order Status Report`}
                            >
                                <ExcelSheet data={preApproveProductOrderList} name="Approve Order Status Report">
                                    <ExcelColumn label="Product Name" value="productName" />
                                    <ExcelColumn label="Package Name" value="packageName" />
                                    <ExcelColumn label="Client Name" value="clientName" />
                                    <ExcelColumn label="Client No" value="contactNo" />
                                    <ExcelColumn label="Client Email" value="instituteEmail" />
                                    <ExcelColumn label="Area" value="area" />
                                    <ExcelColumn label="Address" value="address" />
                                    <ExcelColumn label="Order Date" value="agreementDate" />
                                    <ExcelColumn label="Hand Over Date" value="handOverDate" />
                                    <ExcelColumn label="Aggreement End Date" value="aggreementEndDate" />
                                    <ExcelColumn label="Total Student" value="numberOfStudent" />
                                    <ExcelColumn label="Bill Cycle" value="billCycle" />
                                    <ExcelColumn label="Per Student Rate" value="rate" />
                                    <ExcelColumn label="Rate Type" value="rateType" />
                                    <ExcelColumn label="Bill Payable Person" value="billPayablePerson" />
                                    <ExcelColumn label="Partner ID" value="partnerCustomId" />
                                    <ExcelColumn label="Partner Name" value="partnerName" />
                                    <ExcelColumn label="Partner Email" value="partnerEmail" />
                                    <ExcelColumn label="Partner Mobile" value="partnerMobile" />
                                </ExcelSheet>
                            </ExcelFile>
                        </Space>
                    </Col>
                </Row>
            </Card>

            <Modal
                title="Approve Order"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={400}
                okText="Final Approve"
                centered
            >
                <Checkbox onChange={onChangecheckbox}>Development Charge</Checkbox>
                {chk &&
                    <>
                        <br /> <br />
                        <label htmlFor="charge"> Developmenmt Charge:</label> <br />
                        <InputNumber min={0} value={charge} onChange={onChangeCharge} style={{ width: 200 }} />
                    </>
                }
            </Modal>
            <Modal
                title="Product Information"
                visible={isViewModalVisible}
                onOk={productApprove}
                onCancel={() => setIsViewModalVisible(false)}
                width={700}
                okText="Approved"
                centered
            >
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Product Name
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={8}>
                        {rowSingleData && rowSingleData?.productName ? rowSingleData?.productName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Package
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.packageName ? rowSingleData?.packageName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Name
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.clientName ? rowSingleData?.clientName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Phone
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.contactNo ? rowSingleData?.contactNo : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Email
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.instituteEmail ? rowSingleData?.instituteEmail : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Area
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.area ? rowSingleData?.area : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Address
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.address ? rowSingleData?.address : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Order Date	
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.agreementDate ? rowSingleData?.agreementDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Hand Over Date		
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.handOverDate ? rowSingleData?.agreementDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Aggreement End Date			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.aggreementEndDate ? rowSingleData?.aggreementEndDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Total Student
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.numberOfStudent ? rowSingleData?.numberOfStudent : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Bill Cycle	
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.billCycle ? rowSingleData?.billCycle : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Per Student Rate		
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.rate ? rowSingleData?.rate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Rate Type			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.rateType ? rowSingleData?.rateType : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Bill Payable Person			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.billPayablePerson ? rowSingleData?.billPayablePerson : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner ID				
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.partnerCustomId ? rowSingleData?.partnerCustomId : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Name					
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.packageName ? rowSingleData?.packageName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Email						
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.partnerEmail ? rowSingleData?.partnerEmail : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Mobile							
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.partnerMobile ? rowSingleData?.partnerMobile : ''}
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="Product Order Information Update"
                visible={isEditModalVisible}
                okButtonProps={{ form: 'updatePartner', htmlType: 'submit' }}
                onCancel={() => setIsEditModalVisible(false)}
                width={1000}
                okText="Update"
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    form={updatePreApproveDataForm}
                    id="updatePartner"
                    onFinish={pendingFormSubmit}
                >
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="package_id"
                                label="Package:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your package" },
                                ]}
                            >
                                <SelectPackageByValue onChange={(val) => onchangepackageId(val)} selected={packageId} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item
                                name="insName"
                                label="Institute/Organization Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert institute name" },
                                ]}
                            >
                                <Input type="text" placeholder="Institute Name"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="address"
                                label="Address:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write address" },
                                ]}
                            >
                                <Input type="text" placeholder="Address"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="admin_name"
                                label="Software Admin Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write software admin name" },
                                ]}
                            >
                                <Input type="text" placeholder="Admin Name"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="personal_number"
                                label="Software Admin Mobile No:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please write software admin mobile", },
                                ]}
                            >
                                <Input type="text" placeholder="01711....."></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="email"
                                label="Client Email:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write client email" },
                                    {
                                        max: 60,
                                        type: 'email',
                                        message: "Pleaset Insert valid email.",
                                    },
                                ]}
                            >
                                <Input type="text" placeholder="example@gmail.com"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="aggrementDate"
                                label="Aggrement Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter aggrement date" },
                                ]}
                            >
                                <DatePicker placeholder="Aggrement Date" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="hod"
                                label="Hand over Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter hand over date" },
                                ]}
                            >
                                <DatePicker placeholder="Hand over Date" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="aed"
                                label="Aggrement End Date:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter aggrement end date" },
                                ]}
                            >
                                <DatePicker placeholder="Aggrement End Date" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="bill_cycle"
                                label="Bill Cycle:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select bill cycle" },
                                ]}
                            >
                                <SelectBillCycleByValue onChange={(val) => onchangebillCycle(val)} selected={billCycle}

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="payable_person"
                                label="Bill Payable Person(Instiute/Student):"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select payable person" },
                                ]}
                            >
                                <Select>
                                    <Option value="STUDENT">Student</Option>
                                    <Option value="INSTITUTE">Instiute</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="order_status"
                                label="Order Status"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select order status" },
                                ]}
                            >
                                <Select>
                                    <Option value="2">Pre-Approved</Option>
                                    <Option value="0" >Pending</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="rate_type"
                                label="Rate Type(Fixed rate/Per Student)"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select rate type" },
                                ]}
                            >
                                <Select onChange={(e) => onChnageFixedRateType(e)}>
                                    <Option value="FIXED">FIXED</Option>
                                    <Option value="PER_STUDENT">PER_STUDENT</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="number_student"
                                label="Number of Student"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert number of student" },
                                ]}
                            >
                                <InputNumber type="number" min={1} placeholder="Number Of Student" style={{width:"100%"}} onChange={(e) => onchangeStudentField(e)}></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="pRate"
                                label={paymentRateTitle}
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write fixed rate" },
                                ]}
                            >
                                <InputNumber type="text" style={{ width: "100%" }} onChange={(e) => onChangePrice(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                name="price"
                                label="Total Rate:"
                                className="title-Text"
                            >
                                <Input type="text" disabled value={price}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
