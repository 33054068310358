import { DeleteOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, InfoCircleOutlined, ShopOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Divider, Form, Input, Modal, Popconfirm, Row, Select, Space, Tooltip, Typography, Table } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import ReactExport from "react-export-excel";
import { SelectDistrictForUpdateModal } from '../../select/SelectDistrictForUpdateModal'
import { SelectThanaForUpdateModal } from '../../select/SelectThanaForUpdateModal'
import { SelectUnderPartner } from '../../select/SelectUnderPartner'

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;
const { Paragraph } = Typography;

const { Option } = Select;

export default function ApprovedPartnerSDL(props) {

    const partnerList = useStoreState(state => state.partner.partnerList);
    const fetchApprovePartnerList = useStoreActions(state => state.partner.fetchApprovePartnerList);
    const approvePartnerList = useStoreState((state) => state.partner.approvePartnerList);
    const fetchpartnerProfile2 = useStoreActions((state) => state.common.fetchpartnerProfile2);
    const partnerProfile = useStoreState((state) => state.common.partnerProfile);
    const fetechpartnerProfile = useStoreActions(state => state.partner.fetchpartnerProfile);
    const thanaListFetch = useStoreActions((state) => state.common.thanaListFetch);
    const fetchunderDesignationList = useStoreActions((state) => state.partner.fetchunderDesignationList);
    const [underDesignationUpdateValue, setUnderDesignationUpdateValue] = useState<any>([]);
    const pendingPartnerAssignUpdate = useStoreActions((state) => state.partner.pendingPartnerAssignUpdate);
    const [form] = Form.useForm();
    const [updateApprovedPartnerForm] = Form.useForm();
    const [districtUpdateValue, setDistrictUpdateValue] = useState<any>(null);
    const [districtUpdateId, setDistrictUpdateId] = useState<any>(null);
    const [thanaUpdateValue, setThanaUpdateValue] = useState<any>();
    const [thanaUpdateId, setThanaUpdateId] = useState<any>();
    const [underPartnerId, setUnderPartnerId] = useState<any>();
    const [underParentId, setUnderParentId] = useState<any>();
    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
    const [rowSingleData, setRowSingleData] = useState<any>();
    const [designationIdStore, setDesignationIdStore] = useState<any>();

    const onFinish = (values: any) => {
        fetchApprovePartnerList(values?.designationPartner);
        setDesignationIdStore(values?.designationPartner);
    };

    

    useEffect(() => {
        setPageTitle();
        fetchpartnerProfile2()
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Approved Partner'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }
    
    const showModal = (value) => {
        setLoading(true);
        fetechpartnerProfile(value);
        setIsModalVisible(true);
        setTimeout(() => {
            setLoading(false);
        }, 800);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const updateModalOpen = (value) => {
        setIsUpdateModalVisible(value);
    }

    const columns = [
        {
            title: 'Partner ID',
            dataIndex: 'partnerCustomId',
            key: 'partnerCustomId',

        },
        {
            title: 'Partner Name',
            dataIndex: 'partnerName',
            key: 'partnerName',

        },
        {
            title: 'Personal Mobile No',
            dataIndex: 'personalNumber',
            key: 'personalNumber',

        },
        {
            title: 'Corporate Number',
            dataIndex: 'corporateNumber',
            key: 'corporateNumber',

        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',

        },
        {
            title: 'District',
            dataIndex: 'districtName',
            key: 'districtName',
        },
        {
            title: 'Thana',
            dataIndex: 'thanaName',
            key: 'thanaName',

        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'Action',
            render: (text: any, record: any, index) => (
                <>
                    {partnerProfile?.partnerRoles?.includes('ROLE_ADMIN') &&  //updateModalOpen
                        <Space size="middle">
                            <Tooltip title="Pre-Approve Order Details View">
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                    showModal(record.partnerId);
                                    setRowSingleData(record);
                                }} />
                            </Tooltip>
                            <Tooltip title="Partner Approved Update">
                                <Button type="primary" icon={<TeamOutlined />} onClick={() => {
                                            updateApprovedPartnerForm.setFieldsValue({
                                                designationPartnerUpdate : record.designation,
                                                districtUpdate: record.districtName,
                                                thanaIdUpdate: record.thanaName,
                                                underPartnerUpdate: record.districtName,
                                                partnerViewID: record.partnerCustomId,
                                                partnerViewName: record.partnerName
                                            });
                                            setDistrictUpdateValue(record.districtId);
                                            setThanaUpdateValue(record.thanaName);
                                            setIsUpdateModalVisible(true);
                                            thanaListFetch(record.districtId);
                                            fetchunderDesignationList(record.designation);
                                            setUnderParentId(record.partnerId);
                                            setThanaUpdateId(record.thanaId);
                                            setUnderPartnerId(record.partnerName);
                                        }} ></Button>
                            </Tooltip>
                        </Space>
                    }
                    {partnerProfile?.partnerRoles?.includes('ROLE_RM') && 
                        <>
                            <Space size="middle">
                                <Tooltip title="Pre-Approve Order Details View">
                                    <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                        showModal(record.partnerId);
                                        setRowSingleData(record);
                                    }} />
                                </Tooltip>
                            </Space>
                        </>
                    }
                    {partnerProfile.partnerRoles?.includes('ROLE_BILLING') && 
                        <>
                            <Space size="middle">
                                <Tooltip title="Pre-Approve Order Details View">
                                    <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                                        showModal(record.partnerId);
                                        setRowSingleData(record);
                                    }} />
                                </Tooltip>
                            </Space>
                        </>
                    }
                </>
            ),
        }
    ];

    const columns2 = [
        {
            title: 'Exam Title',
            dataIndex: 'examTitle',

        },
        {
            title: 'Major/Group',
            dataIndex: 'major',
        },
        {
            title: 'Passing Year',
            dataIndex: 'passingYear',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
        },
        {
            title: 'Institute/University',
            dataIndex: 'instituteName',
        },
        {
            title: 'Result',
            dataIndex: 'result',
        },
    ];

    const search = value => {

        const result = partnerList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const partnerUpdateFormSubmit = (value) => {
        let postData:any = {
            "areaId": thanaUpdateId,
            "designation": value.designationPartnerUpdate,
            "parentId": underPartnerId,
            "partnerId": underParentId
        }
        pendingPartnerAssignUpdate(postData);
        updateApprovedPartnerForm.resetFields();
        setIsUpdateModalVisible(false);
        fetchApprovePartnerList(designationIdStore);
    }

    const onchangeDistrictUpdate = (value) => {
        setDistrictUpdateValue(value.districtId);
        thanaListFetch(value.districtId);
        setDistrictUpdateId(value.districtId);
        setThanaUpdateId(null);
        setThanaUpdateValue(null);
    }

    const onChangeThanaUpdate = (value) => {
        console.log('value', value);
        
        setThanaUpdateId(value.thanaId);
        setThanaUpdateValue(value.thanaName);
    }

    const onChangeDesigntation = (value) => {
        fetchunderDesignationList(value);
    }

    const onChnageUnderPartner = (value) => {
        setUnderPartnerId(value.partnerParentId);
        setUnderDesignationUpdateValue(value.partnerId);
    }
    
    return (
        <Card title='Partner List'>
            <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} >
                <Row gutter={15} >
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}></Col>

                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>

                        <Form.Item
                            name="designationPartner"
                            label="Designation:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select your designation" },
                            ]}
                        >
                            <Select placeholder="Select Designation" style={{ width: "100" }} >
                                <Option value="SDL">SDL</Option>
                                <Option value="RM">RM</Option>
                                <Option value="DM">DM</Option>
                                <Option value="EX">EX</Option>
                                <Option value="BILLING">BILLING</Option>
                                <Option value="SUPPORT">SUPPORT</Option>
                                <Option value="ISP_RESELLER">ISP RESELLER</Option>
                            </Select>

                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>

                        <Space size="small" style={{ marginTop: 30 }} >
                            <Button type="primary" htmlType="submit" >
                                Search
                            </Button>
                        </Space>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                </Row>
            </Form>
            {approvePartnerList && approvePartnerList.length > 0 &&
                <>
                    <Table bordered={true} dataSource={ approvePartnerList} columns={columns}  />

                    <Space style={{ float: 'right' }} size="middle">
                        <ExcelFile filename="Approve List" element={<Button type="primary" icon={<FileExcelOutlined />} > Download Excel </Button>}>
                            <ExcelSheet data={approvePartnerList} name="Approved List">
                                <ExcelColumn label="Partner ID" value="partnerCustomId" />
                                <ExcelColumn label="Partner Name" value="partnerName" />
                                <ExcelColumn label="Personal Mobile No" value="personalNumber" />
                                <ExcelColumn label="Corporate Number" value="corporateNumber" />
                                <ExcelColumn label="Email" value="email" />
                                <ExcelColumn label="District Name" value="districtName" />
                                <ExcelColumn label="Thana name" value="thanaName" />
                                <ExcelColumn label="Designation" value="designation" />
                            </ExcelSheet>
                        </ExcelFile>

                    </Space>
                </>
            }
            <Modal
                title="Pending Partner Information" 
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ style: { display: 'none' } }}
                centered
            >
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Partner ID
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.partnerCustomId ? rowSingleData?.partnerCustomId : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Partner Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.partnerName ? rowSingleData?.partnerName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Designation
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.designation ? rowSingleData?.designation : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Corporate Number
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.corporateNumber ? rowSingleData?.corporateNumber : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Personal Number
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.personalNumber ? rowSingleData?.personalNumber : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Email
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.email ? rowSingleData?.email : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                District Name
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.districtName ? rowSingleData?.districtName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Thana
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={12}>
                            {rowSingleData && rowSingleData?.thanaName ? rowSingleData?.thanaName : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={5} style={{ margin: 0 }}>
                                Address
                            </Typography.Title>
                        </Col>
                        <Col span={1}>
                            -
                        </Col>
                        <Col span={16}>
                            {rowSingleData && rowSingleData?.address ? rowSingleData?.address : ''}
                        </Col>
                    </Row>
            </Modal>
            <Modal
                title="Partner Approved Update"
                visible={isUpdateModalVisible}
                onCancel={() => setIsUpdateModalVisible(false)}
                centered
                cancelText="Close"
                okText="Update"
                maskClosable={false}
                okButtonProps={{ form: 'updateApprover', htmlType: 'submit' }}
            >
                <Form
                    layout='vertical'
                    form={updateApprovedPartnerForm}
                    id="updateApprover"
                    onFinish={partnerUpdateFormSubmit}
                >
                    <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="partnerViewID"
                                label="Partner ID"
                                className="title-Text"
                            >
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="partnerViewName"
                                label="Partner Name"
                                className="title-Text"
                            >
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="designationPartnerUpdate"
                                label="Designation:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your status" },
                                ]}
                            >
                                <Select placeholder="Select Designation" style={{ width: "100" }} onChange={(val) => onChangeDesigntation(val)}>
                                    <Option value="SDL">SDL</Option>
                                    <Option value="RM">RM</Option>
                                    <Option value="DM">DM</Option>
                                    <Option value="EX">EX</Option>
                                    <Option value="BILLING">BILLING</Option>
                                    <Option value="SUPPORT">SUPPORT</Option>
                                    <Option value="ISP_RESELLER">ISP RESELLER</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="underPartnerUpdate"
                                label="Under Partner:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select your partner" },
                                ]}
                            >
                                <SelectUnderPartner onChange={(val) => onChnageUnderPartner(val)} selected={underPartnerId} defaultSelected={underPartnerId}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="districtUpdate"
                                label="District"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select district" },
                                ]}
                            >
                                <SelectDistrictForUpdateModal onChange={(val) => onchangeDistrictUpdate(val)} selected={districtUpdateValue}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    name="thanaIdUpdate"
                                    label="Thana"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select your thana", },
                                    ]}
                                >
                                    <SelectThanaForUpdateModal onChange={(val) => onChangeThanaUpdate(val)} selected={thanaUpdateValue} />
                                </Form.Item>
                            </Col>
                    </Row>
                </Form>
            </Modal>
        </Card>
    )
}
