
import { DeleteOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tooltip, Typography,  } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'
import ReactExport from "react-export-excel";
import TableView from '../../../contents/AntTableResponsive';
const { Paragraph } = Typography;
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function OrderStatusApproveListAPIPReport(props) {

    const [updatePreApproveDataForm] = Form.useForm();
    // const pendingApproveProductOrderList = useStoreState(state => state.order.pendingApproveProductOrderList);
    // const fetchpendingApproveProductOrderList = useStoreActions(state => state.order.fetchpendingApproveProductOrderList);

    const pendingApproveProductOrderList = useStoreState(state => state.order.pendingApproveProductOrderList);
    const fetchpendingApproveProductOrderList = useStoreActions(state => state.order.fetchpendingApproveProductOrderList);

    const deleteProductOrder = useStoreActions(state => state.order.deleteProductOrder);
    const approveProductOrder = useStoreActions(state => state.order.approveProductOrder);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [rowSingleData, setRowSingleData] = useState<any>();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [orderId, setOrderId] = useState<any>();
    const [packageId, setPackageId] = useState<any>(null);
    const [billCycle, setBillCycle] = useState<any>("");
    const [xprice, setxPrice] = useState<any>(0);
    const [price, setPrice] = useState<any>(0)
    const [paymentRate, setPaymentRate] = useState<any>();
    const [paymentRateTitle, setPaymentRateTitle] = useState<any>("Per Student Rate/FixedRate:");
    const [totalStudent, setTotalStudent] = useState<any>();
    const fetchpackageListByProductId = useStoreActions((state) => state.order.fetchpackageListByProductId);
    const preApproveOrderInfoUpdate = useStoreActions((state) => state.order.preApproveOrderInfoUpdate);

    useEffect(() => {
        fetchpendingApproveProductOrderList(1);
        setPageTitle();
    }, [])

    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'Approve Order'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const deletePartner = value => {
        deleteProductOrder(value)
    }
    const [chk, setchk] = useState<boolean>(false)
    const [charge, setCharge] = useState<any>(0)
    const [productId, setproductId] = useState<any>('')

    const onChangecheckbox = (e) => {
        setchk(e.target.checked)
        setCharge(0);
    }
    const onChangeCharge = (e) => {
        setCharge(e);
    }
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
        setchk(false);
        setCharge(0);
    };



    const productApprove = () => {
        setIsModalVisible(true);
    }

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:200,
        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Phone',
            dataIndex: 'contactNo',
            key: 'contactNo',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Client Email',
            dataIndex: 'instituteEmail',
            key: 'instituteEmail',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Order Date',
            dataIndex: 'agreementDate',
            key: 'agreementDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Hand Over Date',
            dataIndex: 'handOverDate',
            key: 'handOverDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Aggreement End Date',
            dataIndex: 'aggreementEndDate',
            key: 'aggreementEndDate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Total Student',
            dataIndex: 'numberOfStudent',
            key: 'numberOfStudent',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Bill Cycle',
            dataIndex: 'billCycle',
            key: 'billCycle',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Per Student Rate',
            dataIndex: 'rate',
            key: 'rate',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Rate Type',
            dataIndex: 'rateType',
            key: 'rateType',
            showOnResponse: true, 
            showOnDesktop: true,
            width:150,
        },
        {
            title: 'Bill Payable Person',
            dataIndex: 'billPayablePerson',
            key: 'billPayablePerson',
            showOnResponse: true, 
            showOnDesktop: true,
            width:160,
        },
        {
            title: 'Partner ID',
            width: 100,
            dataIndex: 'partnerCustomId',
            key: 'partnerCustomId',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Name',
            width: 150,
            dataIndex: 'partnerName',
            key: 'partnerName',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Email',
            width: 100,
            dataIndex: 'partnerEmail',
            key: 'partnerEmail',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Partner Mobile',
            width: 130,
            dataIndex: 'partnerMobile',
            key: 'partnerMobile',
            showOnResponse: true, 
            showOnDesktop: true,
        },
        {
            title: 'Action',
            width: 60, 
            fixed: 'right',
            showOnResponse: true, 
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="View">
                        <Button type="primary" icon={<EyeOutlined />} onClick={() => { 
                            setIsViewModalVisible(true);
                            setRowSingleData(record);
                            setproductId(record?.orderId)
                            
                        }}></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search =value => {

        const result = pendingApproveProductOrderList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)
    };

    const onchangepackageId = (val) => {
        console.log('setPackageId', val);
        
        setPackageId(val)
        // setPrice(val?.price)
        // orderForm.setFieldsValue({
        //     price: val?.price,
        //     pprice: val?.price,
        // });
    }

    return (
        <>
            <Card title="Approve Order List">
                    {/*<span>Total Found: {pendingApproveProductOrderList !== null ? pendingApproveProductOrderList?.length : 0}</span>
                     <Input
                        style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                        placeholder="Search..."
                        value={searchv}
                        allowClear
                        onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                    /> */}
                <TableView
                    antTableProps={{
                        showHeader: true,
                        columns:columns,
                        rowKey:"orderId",
                        dataSource: pendingApproveProductOrderList,
                        filterData: pendingApproveProductOrderList,
                        pagination: true,
                        bordered: true,                      
                    }}
                    mobileBreakPoint={768}
                />
                <Row>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Approve Order Status Report`}
                            >
                                <ExcelSheet data={pendingApproveProductOrderList} name="Approve Order Status Report">
                                    <ExcelColumn label="Product Name" value="productName" />
                                    <ExcelColumn label="Package Name" value="packageName" />
                                    <ExcelColumn label="Client Name" value="clientName" />
                                    <ExcelColumn label="Client No" value="contactNo" />
                                    <ExcelColumn label="Client Email" value="instituteEmail" />
                                    <ExcelColumn label="Area" value="area" />
                                    <ExcelColumn label="Address" value="address" />
                                    <ExcelColumn label="Order Date" value="agreementDate" />
                                    <ExcelColumn label="Hand Over Date" value="handOverDate" />
                                    <ExcelColumn label="Aggreement End Date" value="aggreementEndDate" />
                                    <ExcelColumn label="Total Student" value="numberOfStudent" />
                                    <ExcelColumn label="Bill Cycle" value="billCycle" />
                                    <ExcelColumn label="Per Student Rate" value="rate" />
                                    <ExcelColumn label="Rate Type" value="rateType" />
                                    <ExcelColumn label="Bill Payable Person" value="billPayablePerson" />
                                    <ExcelColumn label="Partner ID" value="partnerCustomId" />
                                    <ExcelColumn label="Partner Name" value="partnerName" />
                                    <ExcelColumn label="Partner Email" value="partnerEmail" />
                                    <ExcelColumn label="Partner Mobile" value="partnerMobile" />
                                </ExcelSheet>
                            </ExcelFile>
                        </Space>
                    </Col>
                </Row>
            </Card>

            <Modal
                title="Product Information"
                visible={isViewModalVisible}
                onCancel={() => setIsViewModalVisible(false)}
                width={700}
                centered
                onOk={() => setIsViewModalVisible(false)}
            >
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Product Name
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={8}>
                        {rowSingleData && rowSingleData?.productName ? rowSingleData?.productName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Package
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.packageName ? rowSingleData?.packageName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Name
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.clientName ? rowSingleData?.clientName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Phone
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.contactNo ? rowSingleData?.contactNo : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Client Email
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.instituteEmail ? rowSingleData?.instituteEmail : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Area
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.area ? rowSingleData?.area : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Address
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.address ? rowSingleData?.address : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Order Date	
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.agreementDate ? rowSingleData?.agreementDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Hand Over Date		
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.handOverDate ? rowSingleData?.agreementDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Aggreement End Date			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.aggreementEndDate ? rowSingleData?.aggreementEndDate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Total Student
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.numberOfStudent ? rowSingleData?.numberOfStudent : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Bill Cycle	
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.billCycle ? rowSingleData?.billCycle : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Per Student Rate		
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.rate ? rowSingleData?.rate : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Rate Type			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.rateType ? rowSingleData?.rateType : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Bill Payable Person			
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.billPayablePerson ? rowSingleData?.billPayablePerson : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner ID				
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.partnerCustomId ? rowSingleData?.partnerCustomId : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Name					
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.packageName ? rowSingleData?.packageName : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Email						
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={14}>
                        {rowSingleData && rowSingleData?.partnerEmail ? rowSingleData?.partnerEmail : ''}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                            Partner Mobile							
                        </Typography.Title>
                    </Col>
                    <Col span={1}>
                        -
                    </Col>
                    <Col span={6}>
                        {rowSingleData && rowSingleData?.partnerMobile ? rowSingleData?.partnerMobile : ''}
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
