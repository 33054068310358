import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectYear {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

const currentyear = new Date().getFullYear();

export const SelectYear = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectYear) => {

  const itemList =  [
    { value: currentyear - 1, label: currentyear - 1 },
    { value: currentyear, label: currentyear },
    { value: currentyear + 1, label: currentyear + 1 }
]

  const onSelect = (id) => {
    if (itemList) {
      const val:any = itemList.find((item) => item.value === id);
      onChange(val.value);
    }
  };

  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Year"
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option  key={type.value} value={type.value}>
            {type.label}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Month</Option>
      )}
    </Select>
  );
};
