import { DeleteOutlined, EditOutlined, ShopOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Table, Tag, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy'


export default function IspPackageSDLCreate(props) {

    const check = useStoreState(state => state.isp.check);
    const ispClientInfoListForPip = useStoreState(state => state.isp.ispClientInfoListForPip);
    const fetchispClientInfoListForPip = useStoreActions(state => state.isp.fetchispClientInfoListForPip);
    const saveIspPackageInfo = useStoreActions(state => state.isp.saveIspPackageInfo);
    const updateIspClientInfo = useStoreActions(state => state.isp.updateIspClientInfo);


    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();

    const onFinish = (values: any) => {
        saveIspPackageInfo(values)
        form.resetFields();
    };

    const onFinishUpdate = (values: any) => {

        let postData = {
            "clientCustomId": values.clientCustomId,
            "clientId": row,
            "clientName": values.client_name,
            "clientStatus": values.clientStatus ? 1 : 0,
            "mailingAddress": values.address,
            "mobileNo": values.mobile_number
        }

        updateIspClientInfo(postData)
        setIsModalVisible(false);

    };


    useEffect(() => {
        setPageTitle();
    }, []);

    useEffect(() => {
        setfilterTable(null);
        setsearchv('')
        fetchispClientInfoListForPip();
    }, [check])


    const setPageTitle = () => {
        var pageTitleEle = document.getElementById('pageTitle');
        var pageTitle = 'ISP'
        return pageTitleEle ? pageTitleEle.innerHTML = pageTitle : ''
    }

    const [row, setRow] = useState<any>();
    const columns = [

        {
            title: 'ID',
            dataIndex: 'clientCustomId',
            key: 'clientCustomId',

        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',

        },
        {
            title: 'Mobile No',
            dataIndex: 'mobileNo',
            key: 'mobileNo',

        },
        {
            title: 'Mailing Address',
            dataIndex: 'mailingAddress',
            key: 'mailingAddress',

        },
        {
            title: 'Package Name',
            dataIndex: 'packageName',
            key: 'packageName',

        },
        {
            title: 'Package Price',
            dataIndex: 'packagePrice',
            key: 'packagePrice',

        },
        {
            title: 'Status',
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    {record?.clientStatus === 1 ? <Tag color="success">Enabled</Tag> : <Tag color="error">Disabled</Tag>}

                </Space>
            ),
        },
        {
            title: 'Edit',
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Tooltip title="Update Package">
                        <Button type="primary" icon={<EditOutlined />} onClick={() => { showModal(record); }} >Update</Button>
                    </Tooltip>

                </Space>
            ),
        },

    ]

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (val) => {
        setRow(val?.clientId);
        formUpdate.setFieldsValue({
            clientCustomId: val?.clientCustomId,
            client_name: val?.clientName,
            mobile_number: val?.mobileNo,
            address: val?.mailingAddress,
            clientStatus: val?.clientStatus === 1 ? true : false,
        });
        setIsModalVisible(true);

    };

    const handleOk = () => {
        // setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const [filterTable, setfilterTable] = useState<any>(null);
    const [searchv, setsearchv] = useState<any>('');
    const search = value => {

        const result = ispClientInfoListForPip.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setfilterTable(result)

    };

    return (
        <>

            {ispClientInfoListForPip?.length > 0 &&
                <>


                    <Card title="ISP Client List" >
                    <span>Total Found: {ispClientInfoListForPip!==null?ispClientInfoListForPip?.length:0}</span>
                        <Input
                            style={{ margin: "0 0 10px 0", width: 250, float: "right" }}
                            placeholder="Search..."
                            value={searchv}
                            allowClear
                            onChange={(e) => { search(e.target.value); setsearchv(e.target.value) }}
                        />
                        <Table
                            bordered={true}
                            dataSource={searchv === '' ? ispClientInfoListForPip : filterTable}
                            columns={columns}
                        />

                    </Card>
                </>
            }
            <Modal
                title="Update Client Information"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"40%"}
                okText="Update"
                centered
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <Form layout="vertical" onFinish={onFinishUpdate} id='update-class' form={formUpdate} >
                    <Row gutter={15} >

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                            <Form.Item
                                name="clientCustomId"
                                label="Client Id:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write client id" },
                                ]}
                            >
                                <Input type="text" placeholder="Client Id"></Input>
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                            <Form.Item
                                name="client_name"
                                label="Client Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write client name" },
                                ]}
                            >
                                <Input type="text" placeholder="Client Name"></Input>
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                            <Form.Item
                                name="mobile_number"
                                label="Mobile:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please write your number", },
                                ]}
                            >
                                <Input type="text" placeholder="01711....."></Input>
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                            <Form.Item
                                name="address"
                                label="Mailing Address:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please write your address", },
                                ]}
                            >
                                <TextArea placeholder="Your Address....."></TextArea>
                            </Form.Item>

                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                            <Form.Item
                                name="clientStatus"
                                label="Client Status:"
                                className="title-Text"
                                valuePropName="checked"
                            >
                                <Checkbox >Check To Enable Client</Checkbox>
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                            <Space size="small" style={{ marginTop: 30, float: "right" }} >
                                <Button type="primary" htmlType="submit" >
                                    Update
                                </Button>
                            </Space>

                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
